import React, { useState, useEffect } from "react";
// import ReactGA from "react-ga";
import JeemainHeader from "../../components/JeemainHeader";
import LoadingComp from "./components/LoadingComp";
import ApiUrl from "../../common/ApiUrl";
import Api, { handleAPIError } from "../../common/Api";
import QuestionNotVisited from "./components/QuestionStatus/QuestionNotVisited";
import QuestionNotAnswered from "./components/QuestionStatus/QuestionNotAnswered";
import QuestionAnswered from "./components/QuestionStatus/QuestionAnswered";
import QuestionReviewNotAnswered from "./components/QuestionStatus/QuestionReviewNotAnswered";
import QuestionReviewAnswered from "./components/QuestionStatus/QuestionReviewAnswered";
import "./styles.css";
import { segmentEvents } from "../../utils/constants";

const InstructionPage = (props) => {
	const [examInst, setExamInst] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [checkedboxx, setCheckedBoxx] = useState(false);

	const checkBoxHandler = (e) => {
		setCheckedBoxx(e.target.checked);
	};

	const getExamInfo = () => {
		Api.doFetch(
			"GET",
			{},
			ApiUrl.GET_EXAM_INFO + `${props.location.state.ExamScheduleId}/?student_id=${props.location.state.studentId}`
		)
			.then((response) => {
				if (response) {
					if (response.status === true) {
						setExamInst(response.data);
						setIsLoading(false);
					} else {
						handleAPIError(response.message);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onStartTestClick = () => {
		Api.trackEvent(segmentEvents.MAINS.INSTR_NEXT);
		props.history.push({
			pathname: "/student/JeeExamScreen",
			state: {
				examdId: props.location.state.ExamScheduleId,
				studentId: props.location.state.studentId,
				exam_subjects: examInst.data.exam_subjects,
				exam_theme_assoc_id: examInst.data.exam_theme_assoc_id,
				exam_name: examInst.data.exam_name,
				studentName: props.location.state.studentName,
			},
		});
	};

	useEffect(() => {
		// ReactGA.initialize("UA-128060243-1");
		// ReactGA.pageview(window.location + window.location.search);
		getExamInfo();
	}, []);

	if (isLoading) {
		return <LoadingComp />;
	}

	return (
		<div style={{ fontFamily: "Poppins" }}>
			<JeemainHeader />
			<div className="ins_con_sec">
				<div>
					<p className="ins_page_heading">GENERAL INSTRUCTION</p>
				</div>
				<div>
					<div style={{ textAlign: "center" }}>
						<p>Choose Your Default Language</p>
						<select style={{ width: "80%" }} onClick={() => Api.trackEvent(segmentEvents.MAINS.INSTR_LANGUAGE)}>
							<option>English</option>
						</select>
					</div>
				</div>
			</div>
			<div>
				<center>
					<p id="heading_three">Please Read Instruction carefully</p>
				</center>
			</div>
			<br />
			<br />
			<div className="main_ins_page_con container">
				<p className="main_content_ins_page">General Instruction</p>
				<div className="container">
					<ol type="1">
						<li className="general_list">
							Total duration of {examInst.data.exam_name} is {examInst.duration} min.
						</li>
						<li className="general_list">
							The clock will be set at the server. The countdown timer in the top right corner of screen will
							display the remaining time available for you to complete the examination. When the timer reaches zero,
							the examination will end by itself. You will not be required to end or submit your examination.
						</li>
						<li className="general_list">
							The Questions Palette displayed on the right side of screen will show the status of each question
							using one of the following symbols:
						</li>
						<div className="internal_list">
							<div>
								<div className="box_list">
									<div>1.</div>
									<div className="center_img">
										<QuestionNotVisited />
									</div>
									<div>You have not visited the question yet.</div>
								</div>
								<div className="box_list">
									<div>2.</div>
									<div className="center_img">
										<QuestionNotAnswered />
									</div>
									<div>You have not answered the question.</div>
								</div>
								<div className="box_list">
									<div>3.</div>
									<div className="center_img">
										<QuestionAnswered />
									</div>
									<div>You have answered the question.</div>
								</div>
								<div className="box_list">
									<div>4.</div>
									<div className="center_img">
										<QuestionReviewNotAnswered />
									</div>
									<div> &nbsp;You have NOT answered the question, but have marked the question for review.</div>
								</div>
								<div className="box_list">
									<div>5.</div>
									<div className="center_img">
										<QuestionReviewAnswered />
									</div>
									<div>
										You have answered the question and have also marked it for review. All these questions
										will be evaluated.
									</div>
								</div>
							</div>

							<div className="marks-symbol disclaimer" style={{ marginLeft: -40 }}>
								<strong>Please note</strong>
								<p>You are not allowed to switch from the exam window. If you do so, you will not be able to return back to the exam</p>
							</div>
						</div>
					</ol>
					<ol start="4">
							<li className="general_list">
								You can click on the "&gt;" arrow which apperes to the left of question palette to collapse the
								question palette thereby maximizing the question window. To view the question palette again, you
								can click on "&lt;" which appears on the right side of question window.
							</li>
							<li className="general_list">
								You can click on your "Profile" image on top right corner of your screen to change the language
								during the exam for entire question paper. On clicking of Profile image you will get a drop-down
								to change the question content to the desired language.
							</li>
						</ol>
				</div>
				<p className="main_content_ins_page">Navigating to a Question:</p>
				<div className="container">
					<ol start="6">
						<li className="general_list">To answer a question, do the following:</li>
						<div className="internal_list">
							<ol type="a">
								<li className="general_list">
									Click on the question number in the Question Palette at the right of your screen to go to that
									numbered question directly. Note that using this option does NOT save your answer to the
									current question.
								</li>
								<li className="general_list">
									Click on <strong>Save & Next</strong> to save your answer for the current question and then go
									to the next question.
								</li>
								<li className="general_list">
									Click on <strong>Mark for Review & Next</strong> to save your answer for the current question,
									mark it for review, and then go to the next question
								</li>
							</ol>
						</div>
					</ol>
				</div>
				<p className="main_content_ins_page">Answering a Question:</p>
				<div className="container">
					<ol start="7">
						<li className="general_list">Procedure for answering a multiple choice type question:</li>
						<div className="internal_list">
							<ol type="a">
								<li className="general_list">
									To select your answer, click on the button of one of the options.
								</li>
								<li className="general_list">
									To deselect your chosen answer, click on the button of the chosen option again or click on the{" "}
									<strong>Clear Response</strong> button.
								</li>
								<li className="general_list">
									To change your chosen answer, click on the button of another option.
								</li>
								<li className="general_list">To save your answer, you MUST click on the Save & Next button.</li>
								<li className="general_list">
									To mark the question for review, click on the Mark for Review & Next button.
								</li>
							</ol>
						</div>
						<li className="general_list">
							To change your answer to a question that has already been answered, first select that question for
							answering and then follow the procedure for answering that type of question.
						</li>
					</ol>
				</div>
				<p className="main_content_ins_page">Navigating through Sections:</p>
				<div className="container">
					<ol start="9">
						<li className="general_list">
							Sections in this question paper are displayed on the top bar of the screen. Questions in a section can
							be viewed by click on the section name. The section you are currently viewing is highlighted.
						</li>
						<li className="general_list">
							After click the Save & Next button on the last question for a section, you will automatically be taken
							to the first question of the next section.
						</li>
						<li className="general_list">
							You can shuffle between sections and questions anything during the examination as per your convenience
							only during the time stipulated.
						</li>
						<li className="general_list">
							Candidate can view the corresponding section summery as part of the legend that appears in every
							section above the question palette.
						</li>
					</ol>
				</div>
				<br />
				<div>
					<hr />
					<center>
						<p style={{ color: "#A94442", padding: "20px", marginBottom: 0 }}>
							Please note all questions will appear in your default language. This language can be changed for a
							particular question later on.
						</p>
					</center>
					<hr />
				</div>
			</div>
			<br />
			<div className="container">
				<form>
					<div className="form-check">
						<label className="form-check-label" style={{ fontWeight: "500 " }}>
							<input type="checkbox" className="form-check-input" onChange={checkBoxHandler} />
							&nbsp; I have read and understood the instructions. All computer hardware allotted to me are in proper
							working condition. I declare that I am not in possession of / not wearing / not carrying any
							prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the
							Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be
							debarred from this Test and/or to disciplinary action, which may include ban from future Tests /
							Examinations
						</label>
						<br />
					</div>
				</form>
				<hr />
			</div>
			<br />
			<div>
				<center>
					<button className={checkedboxx ? `pro_btn` : `pro_btn2`} disabled={!checkedboxx} onClick={onStartTestClick}>
						PROCEED
					</button>
				</center>
			</div>
			<br />
			<br />
		</div>
	);
};

export default InstructionPage;
