import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import Api from "./common/Api";
import { useDocumentTitle } from "./utils/hooks/use-document-title";

let lastVisitedPage = "";

const PublicRoute = ({ component: Component, title = null, ...props }) => {
    useDocumentTitle(title);

	useEffect(() => {
		if (lastVisitedPage !== window.location.pathname) {
			Api.trackPage();
			lastVisitedPage = window.location.pathname;
		}
	});

	return <Route {...props} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
