import { useEffect, useRef } from "react";
import { formatSecondsToTime, useTimer } from "./use-timer";

export default function GenericV2Timer({ examTimeLeft, onTimerEnd = () => {} }) {
    const timerRef = useRef(false);
    const { time, status, start: startTimer } = useTimer({ timerType: "DECREMENTAL" });

    useEffect(() => {
        if (status === "STOPPED") {
            if (timerRef.current && typeof onTimerEnd === "function") {
                onTimerEnd();
            }
        }
    }, [status, onTimerEnd]);

    useEffect(() => {
        if (typeof examTimeLeft === "number") {
            timerRef.current = true;
            if (examTimeLeft > 0) {
                startTimer(examTimeLeft);
            } else {
                if (typeof onTimerEnd === "function") {
                    onTimerEnd();
                }
            }
        }
    }, [examTimeLeft, startTimer, onTimerEnd]);

    if (time) {
        return (
            <>
                <span className="timer-area" style={{ alignSelf: "center", color: Number(time) < 1800 ? "red" : "#012C63" }}>
                    <i className="far fa-clock" /> {formatSecondsToTime(time)}
                </span>
            </>
        );
    }

    return null;
}
