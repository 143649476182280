import React from "react";
import { SwipeableDrawer } from "@material-ui/core";
import "./layout.css";
import Avatar from "../../assets/img/dashboard_avatar.png";
import homeIcon from "../../assets/img/homeIcon.svg";
import scheduleIcon from "../../assets/img/scheduleIcon.svg";
import solutionsIcon from "../../assets/img/solutionsIcon.svg";
import MyProfileImg from "../../assets/img/my_profile.svg";
import ChangePWImg from "../../assets/img/change_pw.svg";
import HelpSupportImg from "../../assets/img/help_support.svg";
import LogoutImg from "../../assets/img/logout.svg";
import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import { Link, useHistory } from "react-router-dom";
import lockedImg from "../../assets/img/lockedImg.svg";
import { segmentEvents } from "../../utils/constants";

const Sidedrawer = ({ openSidebar, onClose, handleModalOpen, courses, studentData, isFree }) => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const history = useHistory();

	React.useEffect(() => {
		if (courses.length > 0) setSelectedIndex(courses.findIndex((c) => c.is_enable));
	}, [courses]);

	const onChange = (i) => {
		if (i === selectedIndex) {
			onClose();
		} else {
			setSelectedIndex(i);
			const formdata = new FormData();
			formdata.append("student_id", studentData.id);
			formdata.append("student_course_id", courses[i].student_course_id);
			Api.doUpload("POST", formdata, ApiUrl.SWITCH_COURSE)
				.then((response) => {
					if (response.status) {
						history.push({
							pathname: "/student",
						});
						document.location.reload();
					} else {
						alert(response.message);
					}
				})
				.catch(() => {
					this.props.history.push("/error");
				});
		}
	};

	return (
		<>
			<SwipeableDrawer
				PaperProps={{
					style: { borderRadius: "0px 16px 16px 0px" },
				}}
				anchor={"left"}
				open={openSidebar}
				onOpen={() => {}}
				onClose={onClose}>
				<div
					style={{
						width: "80vw",
						maxWidth: 400,
					}}
					className="hamburger-padding paper-stylingg">
					<div>
						<div className="user-details-ham">
							<img
								src={studentData.profile_pic ? studentData.profile_pic : Avatar}
								className="user-avatar-image"
								alt="user_default"
							/>
							<div>
								<div className="userName">{studentData.name}</div>
								<div className="userNumber">+91-{studentData.mobile_number}</div>
							</div>
						</div>
						<hr />
						{!isFree && (
							<div>
								<div className="flexBetween">
									<div className="courseHeading">Current Batch</div>

									{courses.length > 2 && (
										<div className="seeAllCourse" onClick={handleModalOpen}>
											See all
										</div>
									)}
								</div>

								<div className="batchList">
									{courses.slice(0, 2).map((item, i) => {
										return (
											<label className="containerBox" key={i}>
												{item.section_name}
												<input
													checked={i === selectedIndex}
													onChange={() => onChange(i)}
													type="radio"
													name="radio"
												/>
												<span className="checkmark"></span>
											</label>
										);
									})}
								</div>
								<hr />
							</div>
						)}

						<div className="navigationLinks">
							<Link to="/student" className="linkItem" onClick={onClose}>
								<img src={homeIcon} alt="" />
								Home
							</Link>
							{isFree ? (
								<div className="linkItem" onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SCHEDULE_CLICK)}>
									<img src={scheduleIcon} alt="" />
									Schedule
									<img src={lockedImg} style={{ width: 14, position: "absolute", right: 0 }} alt="" />
								</div>
							) : (
								<Link to="/student/myschedule" className="linkItem" onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SCHEDULE_CLICK)}>
									<img src={scheduleIcon} alt="" />
									Schedule
								</Link>
							)}
							{isFree ? (
								<div 
									className="linkItem" 
									onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SOLUTIONS_CLICK)}
								>
									<img src={solutionsIcon} alt="" />
									Solution
									<img src={lockedImg} style={{ width: 14, position: "absolute", right: 0 }} alt="" />
								</div>
							) : (
								<Link 
									to="/student/doubtmodule/searchquestion" 
									className="linkItem" 
									onClick={() => Api.trackEvent(segmentEvents.NAVBAR.SOLUTIONS_CLICK)}
								>
									<img src={solutionsIcon} alt="" />
									Solution
								</Link>
							)}
							<Link to="/student/profile" className="linkItem" onClick={() => Api.trackEvent(segmentEvents.NAVBAR.PROFILE_CLICK)}>
								<img alt="" src={MyProfileImg} />
								My Profile
							</Link>
							<Link to="/student/changepassword" className="linkItem" onClick= {() => Api.trackEvent(segmentEvents.NAVBAR.CHANGEPASSWORD_CLICK)}>
								<img alt="" src={ChangePWImg} />
								Change Password
							</Link>
							<Link to="/student/help-and-support" className="linkItem" onClick= {() => Api.trackEvent(segmentEvents.NAVBAR.HELPSUPPORT_CLICK)}>
								<img alt="" src={HelpSupportImg} />
								Help & Support
							</Link>
						</div>
					</div>

					<div 
						className="linkItem" 
						onClick={async () => {
							await Api.trackEventAsync(segmentEvents.NAVBAR.LOGOUT_CLICK);
							Api.logout();
						}}
					>
						<img src={LogoutImg} alt="" className="logoutHamImage" />
						Logout
					</div>
				</div>
			</SwipeableDrawer>
		</>
	);
};

export default Sidedrawer;
