import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Api from "./common/Api";
import { useDocumentTitle } from "./utils/hooks/use-document-title";

let lastVisitedPage = "";

export const ProtectedRoute = ({ component: Component, title = null, skipSetTitle = false, ...rest }) => {
	let data = localStorage.getItem("userdetail");

	try {
		data = data ? JSON.parse(data) : null;
	} catch (error) {
		data = null;
	}

	useDocumentTitle(title, { skipSetTitle });

	useEffect(() => {
		if (lastVisitedPage !== window.location.pathname) {
			
			Api.trackPage();
			lastVisitedPage = window.location.pathname;
		}
	});

	if (data && data.permission === rest.value) {
		return <Route {...rest} render={(props) => <Component {...props} />} />;
	}

	return <Route {...rest} render={(props) => <Redirect to={{ pathname: "/", state: { from: props.location } }} />} />;
};
