import React from "react";
import { toast } from "react-toastify";

export const parserConfig = {
    replace: (domNode) => {
        if (domNode.name === "img") {
            return <img src={domNode.attribs.src} alt={domNode.attribs.alt} width="100%" />;
        }
    },
};

export const generateRequestOptions = (token = null) => {
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (token) headers["Authorization"] = `Token ${token}`;

    return {
        redirect: "follow",
        headers,
    };
};

export const getUserToken = () => {
    let userDetails = localStorage.getItem("userdetail");

    if (userDetails) {
        userDetails = JSON.parse(userDetails);

        return userDetails.token || null;
    }
};

export const padDigits = (d, n = 2) => {
    return (new Array(n).join("0") + d).slice(-n);
};

export const updateQuestionStatus = (questionStatusList, sectionIdMaxLength) => {
    if (questionStatusList && questionStatusList.length > 0) {
        const questionStatusDataMap = {};

        const allCounts = {
            questionCount: questionStatusList.length,
            answeredCount: 0,
            unansweredCount: 0,
            answeredReviewCount: 0,
            unansweredReviewCount: 0,
            notVisitedCount: 0,
        };

        questionStatusList.forEach((questionStatus) => {
            // Padding the section id to avoid sorting issues for object
            // Example: Sorted result would be correct for 09999-1234 10000-1235
            questionStatusDataMap[`${padDigits(questionStatus.section_id, sectionIdMaxLength)}-${questionStatus.question_id}`] = questionStatus;

            if (questionStatus.color_id === "0") allCounts.notVisitedCount += 1;
            else if (questionStatus.color_id === "1") allCounts.answeredCount += 1;
            else if (questionStatus.color_id === "2") allCounts.unansweredReviewCount += 1;
            else if (questionStatus.color_id === "3") allCounts.answeredReviewCount += 1;
            else if (questionStatus.color_id === "4") allCounts.unansweredCount += 1;
        });

        return [questionStatusDataMap, allCounts];
    }
};

export const updateQuestionStatusFromMap = (questionStatusMap) => {
	const allCounts = {
		questionCount: 0,
		answeredCount: 0,
		unansweredCount: 0,
		answeredReviewCount: 0,
		unansweredReviewCount: 0,
		notVisitedCount: 0,
	};

	try {
		const questionStatusList = Object.values(questionStatusMap);

		allCounts.questionCount = questionStatusList.length;

		questionStatusList.forEach((questionStatus) => {
			if (questionStatus.color_id === "0") allCounts.notVisitedCount += 1;
			else if (questionStatus.color_id === "1") allCounts.answeredCount += 1;
			else if (questionStatus.color_id === "2") allCounts.unansweredReviewCount += 1;
			else if (questionStatus.color_id === "3") allCounts.answeredReviewCount += 1;
			else if (questionStatus.color_id === "4") allCounts.unansweredCount += 1;
		});
	} catch (error) {}

	return allCounts;
};

export const generateQuestionTileProps = (value, currentSectionId, currentQuestionSerialNo) => {
    const colorStatusClass =
        value.color_id === "0" && value.serial_no === currentQuestionSerialNo
            ? "not-answered"
            : {
                  0: "not-visited",
                  1: "answered",
                  2: "not-answered-marked",
                  3: "answered-marked",
                  4: "not-answered",
              }[value.color_id] || "";

    const sectionStatusClass = currentSectionId === null || value.section_id === currentSectionId ? "active" : "";

    return {
        className: `genericV2-question-tile ${colorStatusClass} ${sectionStatusClass}`,
        style: value.serial_no === currentQuestionSerialNo ? { cursor: "not-allowed" } : undefined,
    };
};

export const showToast = (messageType, extraOptions = {}) => {
    const options =
        {
            0: { content: "Not answered", backgroundColor: "rgba(255, 199, 54, 1)" },
            1: { content: "Answer saved", backgroundColor: "rgba(68, 178, 0, 1)" },
            2: { content: "Not answered & marked for review", backgroundColor: "#FFC736" },
            3: { content: "Answer saved & marked for review", backgroundColor: "rgba(68, 178, 0, 1)" },
            4: { content: "Not answered", backgroundColor: "rgba(255, 199, 54, 1)" },
            5: { content: "Exam time is over", backgroundColor: "#ff3322" },
        }[messageType] || null;

    if (options) {
        const { content, backgroundColor } = options;

        toast(content, {
            position: "bottom-left",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            ...extraOptions,
            style: { backgroundColor, color: "white", ...((extraOptions && extraOptions.style) || {}) },
        });
    }
};
