import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistReducer, PersistConfig } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import examReducer from "./reducers/examReducer";
import localforage from "localforage";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const storage = localforage.createInstance({
    name: "myclassroomExam",
    storeName: "generic",
});

/** @type { PersistConfig } */
const examPersistConfig = {
    key: "exam",
    storage: storage,
    // blacklist: ["miscData"],
    // stateReconciler: autoMergeLevel2,
};

const middleware = [thunk];

const examPersistReducer = persistReducer(examPersistConfig, examReducer);

export const examStore = createStore(examPersistReducer, applyMiddleware(...middleware));

export const examPersistor = persistStore(examStore);

export const getOfflineCurrentExamData = () => {
    const { examData, miscData } = examStore.getState();

    if (examData && miscData) {
        const { currentExamId } = miscData;

        if (currentExamId && examData[currentExamId]) {
            return examData[currentExamId];
        }
    }

    return null;
};

export const getOfflineCurrentStudentExamData = () => {
    const { studentExamData, miscData } = examStore.getState();

    if (studentExamData && miscData) {
        const { currentExamId, currentStudentId } = miscData;

        const examStudentKey = currentExamId && currentStudentId && `${currentExamId}_${currentStudentId}`;
        
        if (examStudentKey && studentExamData[examStudentKey]) {
            return studentExamData[examStudentKey];
        }
    }

    return null;
};

export const getOfflineMiscData = () => {
    const { miscData } = examStore.getState();

    return miscData;
};

export const getStudentAnswerData = () => {
    const { miscData, studentAnswerData } = examStore.getState();

    if (miscData && studentAnswerData) {

        const { currentExamId, currentStudentId } = miscData;
        
        const examStudentKey = currentExamId && currentStudentId && `${currentExamId}_${currentStudentId}`;
        
        if (examStudentKey && studentAnswerData[examStudentKey]) {
            return studentAnswerData[examStudentKey];
        }
    }

    return null;
}