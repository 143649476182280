import moment from "moment";

export class EventPublisher {
	store = null;
	getCommonAttributes = () => {
		if (this.store?.student_data) {
			var current = moment().startOf("day");
			const studentData = this.store.student_data;
			const installmentData = this.store.installment_data;
			const parentsData = this.store.parents_data?.[0];
			const weekData = this.store.attendance_past_week;
			const yesterdayData = this.store.attendance_yesterday;

			let attributeObject = {
				user_name: studentData.name,
				email: studentData.email,
				mobile: studentData.mobile_number,
				city: studentData.city_assoc,
				state: studentData.school_assoc.state,
				class: studentData.program_name,
				course: studentData.section_assoc.section_name,
				centre: studentData.school_assoc.name,
				batch: studentData.section_assoc.class_name,
				"overdue amount": studentData.fee_pending,
				"registration date": studentData.date_joined,
				"user type": "student",
				"overdue days": installmentData?.next_installment_date
					? 0
					: moment(installmentData?.next_installment_date).diff(current, "days"),
				"test completion percentage": studentData.testcompletions,
				"dpp completion percentage": studentData.dppcompletions,
				"last 7 Days attendance percentage": weekData.attendance_percentage,
				"course end date": studentData.student_course_end_date,
				"yesterday's attendance": yesterdayData.attendance_percentage,
				"next installment amount": installmentData.due_amount,
				"next installment date": installmentData.next_installment_date,
				gender: studentData.gender,
				"email opt in": studentData.email_opt_in,
				"whatsapp opt in": studentData.whatsapp_opt_in,
				"parent name": parentsData?.name,
				"parent mobile number": parentsData?.mobile_number,
				"student type": studentData.is_free ? "FREE" : "PAID",
			};
			return attributeObject;
		}
		return {};
	};

	constructor(store) {
		this.store = store;

		window.addEventListener("MOENGAGE_EVENT", (e) => {
			if (!e.detail?.eventName) {
				return console.error("No Event name supplied");
			}
			let eventAttributes = e.detail?.properties ?? {};
			const allAttributes = clean({
				...this.getCommonAttributes(),
				...eventAttributes,
			});

			try {
				window?.Moengage?.track_event(e.detail.eventName, allAttributes);
			} catch (err) {
				console.error(err);
			}
		});
	}

	cleanupEventListener = () => {
		window.removeEventListener("MOENGAGE_EVENT", () => {});
	};
}

const clean = (obj) => {
	const cleanObj = {};
	Object.entries(obj).forEach(([key, value]) => {
		if (value !== null && typeof value !== "undefined" && value !== "null" && value !== undefined) {
			cleanObj[key] = value;
		}
	});
	return cleanObj;
};
