import React from "react";
import CommonCard from "../../components/corner/CommonCard";
import { useHistory } from "react-router-dom";
import { cornerType, dashboardIds } from "../../utils/constants";

const NEETCorner = ({ active }) => {
	const history = useHistory();

	return (
		<CommonCard
			title={"NEET Corner"}
			active={active}
			navigate={(state) => {
				history.push({ pathname: "/student/neet", state });
			}}
			id={dashboardIds.NEET}
			type={cornerType.NEET}
		/>
	);
};

export default React.memo(NEETCorner);
