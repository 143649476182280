import {
  JEE_ADVANCE_EXAM_QUESTION_SUCCESS,
  JEE_ADVANCE_EXAM_QUESTION_FAILURE,
  JEE_ADVANCE_EXAM_QUESTION_SUBMIT,
  JEE_ADVANCE_UPDATE_QUESTIONS,
  JEE_ADVANCE_START_LOADER,
} from "../actionType";
import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";

export const getJeeAdvanceExamQuestion =
  (exam_id, student, type, currentSec) => async (dispatch, getState) => {
    if (type === "SectionQuestion") {
      dispatch({
        type: JEE_ADVANCE_START_LOADER,
      });
      try {
        const response = await Api.doFetch(
          "GET",
          {},
          ApiUrl.FETCH_QUESTIONS +
            `${exam_id}/?student_id=${student.id}&subject_id=${currentSec.exam_subject_assoc}&section_id=${currentSec.id}`
        );

        if (response.status === true) {
          dispatch({
            type: JEE_ADVANCE_EXAM_QUESTION_SUCCESS,
            payload: { data: response.data },
          });
        } else {
          dispatch({
            type: JEE_ADVANCE_EXAM_QUESTION_FAILURE,
            payload: response,
          });
        }
        return response;
      } catch (error) {
        dispatch({
          type: JEE_ADVANCE_EXAM_QUESTION_FAILURE,
          payload: error,
        });
      }
    } else if (type === "SaveAnswer") {
      dispatch({
        type: JEE_ADVANCE_EXAM_QUESTION_SUBMIT,
        payload: currentSec,
      });
    } else {
      const exam_question =
        getState().JeeAdvanceQuestion.examQuestions.section_questions;
      if (Object.keys(exam_question).length === 0 && student?.id) {
        dispatch({
          type: JEE_ADVANCE_START_LOADER,
        });
        var formdata = new FormData();
        formdata.append("student_id", student.id);
        try {
          const response = await Api.doUpload(
            "POST",
            formdata,
            ApiUrl.START_EXAM + `${exam_id}/`
          );

          if (response.status === true) {
            dispatch({
              type: JEE_ADVANCE_EXAM_QUESTION_SUCCESS,
              payload: { data: response.data },
            });
          } else {
            dispatch({
              type: JEE_ADVANCE_EXAM_QUESTION_FAILURE,
              payload: response,
            });
          }
          return response;
        } catch (error) {
          dispatch({
            type: JEE_ADVANCE_EXAM_QUESTION_FAILURE,
            payload: error,
          });
        }
      }
    }
    return;
  };

export const updateJeeAdvancedQuestions =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: JEE_ADVANCE_UPDATE_QUESTIONS,
      payload: data,
    });
  };
