import React from "react";

const GenericV2Sections = React.forwardRef(({ sections = [], changeSection, currentSectionId }, sectionRefs) => {
    if (!sections || !sections.length) return null;

    return (
        <>
            {sections.map((section, sectionIndex) => {
                return (
                    <span
                        style={
                            section.id === currentSectionId
                                ? { backgroundColor: "#012C63", color: "white" }
                                : { backgroundColor: "rgba(255, 255, 255, 0.15)", color: "#012C63" }
                        }
                        ref={sectionRefs.current[sectionIndex]}
                        onClick={changeSection(section.id, sectionIndex)}
                        className="section"
                        key={`section-${sectionIndex}`}
                    >
                        {section.section_name}
                    </span>
                );
            })}
        </>
    );
});

export default GenericV2Sections;
