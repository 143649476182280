import axios from "axios";
import Api from "../../../common/Api";
import ApiUrl from "../../../common/ApiUrl";
import { examStore, getOfflineCurrentStudentExamData, getOfflineMiscData, getStudentAnswerData } from "../../../store/examStore";
import {
	GENERIC_OFFLINE_QUESTION_SYNC_QUEUE,
	GENERIC_OFFLINE_QUESTION_CLEAR_QUEUE,
} from "../../../store/reducers/examReducer/studentAnswerDataReducer";

let abortController = new AbortController();
let intervalId = null;

export const trySync = async () => {
	if (abortController) {
		abortController.abort();
		abortController = new AbortController();
	}

	const { currentStudentId: studentId, currentExamId: examId } = getOfflineMiscData();

	const failedAnswers = getStudentAnswerData();

	if (!failedAnswers) return;

	const { questionsFailedToSave } = failedAnswers;

	if (Object.keys(questionsFailedToSave).length > 0) {
		await examStore.dispatch({
			type: GENERIC_OFFLINE_QUESTION_SYNC_QUEUE,
			payload: {
				examId,
				studentId,
			},
		});
	}

	const { questionsQueuedToSync } = getStudentAnswerData();

	if (questionsQueuedToSync.length > 0) {
		const token = Api.isAuthenticated();

		const headers = {
			Authorization: `Token ${token}`,
			"Content-Type": "application/json",
		};

		const reqBody = {
			studentId,
			examId,
			updatedAnswers: [],
		};

		const { questions } = getOfflineCurrentStudentExamData();

		for (const questionId of questionsQueuedToSync) {
			const question = questions[questionId];

			if (question) {
				const options_selected = ["1", "2", "3", "4"].filter((n) => {
					const key = `is_option${n}_selected`;

					return question[key];
				});

				reqBody.updatedAnswers.push({
					serial_no: questionId,
					allotted_id: question.id,
					type: question.question_type_id,
					review: question.review_status === "true",
					total_seconds: question.total_seconds,
					ans_given: question.ans_given,
					options_selected,
					current_attempt_seconds: question.current_attempt_seconds,
				});
			}
		}

		try {
			const { data: bulkSaveRes } = await axios.post(`${ApiUrl.BULK_SAVE_ANSWERS}/${examId}`, reqBody, {
				signal: abortController.signal,
				headers,
			});

			if (bulkSaveRes?.status === true) {
				// Clear queue
				await examStore.dispatch({
					type: GENERIC_OFFLINE_QUESTION_CLEAR_QUEUE,
					payload: {
						examId,
						studentId,
					},
				});
			} else {
			}
		} catch (error) {
			console.log(error);
		}
	}
};

export const startOfflineSync = () => {
	if (intervalId !== null) clearInterval(intervalId);

	intervalId = setInterval(() => {
		trySync();
	}, 60000); // 1 minute
};

export const cancelOfflineSync = () => {
	if (intervalId !== null) clearInterval(intervalId);

	intervalId = null;
};
