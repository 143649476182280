import { STUDENT_DETAIL_SUCCESS, STUDENT_DETAIL_FAILURE,EMPTY_STUDENT_DETAIL } from '../actionType'

export const initialState = {
  StudentDetail:{},
    loading: true,
    error: null,
    success: false,
}

export default function studentDetailReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {   
    case STUDENT_DETAIL_SUCCESS:
      return {
        StudentDetail: payload,
        loading: false,
        error: false,
        success:true,
      }
    case STUDENT_DETAIL_FAILURE:
      return {        
        loading: true,
        error: payload,
        success: false
      }
    case EMPTY_STUDENT_DETAIL:
    return {        
      StudentDetail:{}
    }
    default:
      return state
  }
}