import React from "react";
import Spinner from "../../spinner/Spinner";
import styles from "./solid-button.module.css";

const SolidButton = ({
	loading = false,
	onClick = () => {},
	disabled = false,
	children,
	small,
	style = {},
	loaderStyle = {},
}) => {
	return (
		<button
			className={`${styles.button} ${small && styles.smallButton} `}
			disabled={disabled}
			onClick={() => {
				if (loading) return;
				onClick();
			}}
			style={{ ...(disabled && { boxShadow: "none" }), ...style }}>
			{children}
			{loading && <Spinner style={loaderStyle} />}
		</button>
	);
};

export default SolidButton;
