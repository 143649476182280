import React from "react";
import MPPNavIcon from "./icons/MPPNavIcon";
import TestNavIcon from "./icons/TestNavIcon";
import ClassNavIcon from "./icons/ClassNavIcon";
import NcertNavIcon from "./icons/NcertNavIcon";
import JeeNavIcon from "./icons/JeeNavIcon";
import NeetNavIcon from "./icons/NeetNavIcon";
import lockedImg from "../../assets/img/lockedImg.svg";
import { dashboardIds } from "../../utils/constants";

const DashboardNavbar = ({ activeSection, setActiveSection, stopScrollListner, isFree }) => {
	const scrollTo = (id) => {
		const el = document.getElementById(id);
		if (!el) return;
		let scrollTimer = -1;
		const bodyScroll = () => {
			if (scrollTimer !== -1) clearTimeout(scrollTimer);
			scrollTimer = window.setTimeout(scrollFinished, 500);
		};
		const scrollFinished = () => {
			stopScrollListner.current = false;
			window.removeEventListener("scroll", bodyScroll);
		};
		window.addEventListener("scroll", bodyScroll);
		stopScrollListner.current = true;
		setTimeout(() => {
			el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
		}, 0);
		setActiveSection(id);
	};

	const links = isFree
		? [
				{
					id: dashboardIds.NCERT,
					label: "NCERT Corner",
					img: <NcertNavIcon />,
				},
				{
					id: dashboardIds.JEE,
					label: "JEE Corner",
					img: <JeeNavIcon />,
				},
				{
					id: dashboardIds.NEET,
					label: "NEET Corner",
					img: <NeetNavIcon />,
				},
				{
					id: dashboardIds.MPP,
					label: "MPPs",
					img: <MPPNavIcon />,
					locked: true,
				},
				{
					id: dashboardIds.TESTS,
					label: "Tests",
					img: <TestNavIcon />,
					locked: true,
				},
				{
					id: dashboardIds.CLASSES,
					label: "Classes",
					img: <ClassNavIcon />,
					locked: true,
				},
		  ]
		: [
				{
					id: dashboardIds.MPP,
					label: "MPPs",
					img: <MPPNavIcon />,
				},
				{
					id: dashboardIds.TESTS,
					label: "Tests",
					img: <TestNavIcon />,
				},
				{
					id: dashboardIds.CLASSES,
					label: "Classes",
					img: <ClassNavIcon />,
				},
				{
					id: dashboardIds.NCERT,
					label: "NCERT Corner",
					img: <NcertNavIcon />,
				},
				{
					id: dashboardIds.JEE,
					label: "JEE Corner",
					img: <JeeNavIcon />,
				},
				{
					id: dashboardIds.NEET,
					label: "NEET Corner",
					img: <NeetNavIcon />,
				},
		  ];

	return (
		<div>
			{links.map((link) => (
				<div
					key={link.id}
					className={`dasboard_nav_item_wrapper ${link.id === activeSection ? "active" : ""}`}
					onClick={() => scrollTo(link.id)}>
					{React.cloneElement(link.img, { fill: link.id === activeSection ? "#FFFFFF" : "#0E0D0D" })}
					{link.comingSoon ? (
						<div>
							<p className={`dasboard_nav_text ${link.id === activeSection ? "active" : ""}`}>{link.label}</p>
							<span className="dashboard_nav_coming_soon">Coming soon</span>
						</div>
					) : (
						<p className={`dasboard_nav_text ${link.id === activeSection ? "active" : ""}`}>{link.label}</p>
					)}
					{link.locked && (
						<div className="locked-container" style={{ position: "absolute", right: 12 }}>
							<img src={lockedImg} style={{ width: 14 }} alt="" />
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default DashboardNavbar;
