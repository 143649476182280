import React from "react";
import "./styles.css";

const QuestionAnswered = ({ width, height, customCss = {}, children }) => {
  return (
    <div
      className="Answered"
      style={{
        ...(width && { width }),
        ...(height && { height }),
        ...customCss,
      }}
    >
      {children}
    </div>
  );
};

export default QuestionAnswered;
