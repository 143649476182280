import React from "react";
import { useMediaQuery } from "@material-ui/core";
import CompletedImg from "../../../assets/img/completed_mpp.svg";
import PendingOrangeClock from "../../../images/pending-orange-clock.svg";
import PendingImg from "../../../assets/img/pending_mpp.svg";
import GreetingImg from "../../../assets/img/greeting.svg";
import SolidButton from "../../../components/common/SolidButton";
import { useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import OneLineTooltip from "../../../components/common/OneLineTooltip";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";

const getList = (arr, idx) => {
	const arrClone = [...arr];
	const lastPart = arrClone.splice(arr.length + 1 - idx, arr.length);
	return [arrClone, lastPart];
};

const MppCard = ({ index, data, studentData, token }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	const history = useHistory();
	const tagsRef = React.useRef(null);
	const [extraCatCount, setExtraCatCount] = React.useState(0);
	const [mainList, overflowList] = getList(Array.isArray(data.tag_names) ? data.tag_names : [], extraCatCount);

	React.useEffect(() => {
		const heightBreakpoint = 50;
		if (tagsRef?.current?.offsetHeight > heightBreakpoint) {
			setExtraCatCount((num) => num + 1);
		}
	}, [extraCatCount, mainList]);

	const handleResultButtonClick = (item) => {
		//TODO: Segment
		Api.moEngageTrackEvent("Web-DPP_action_button", {
			Timestamp: Date(),
			"Clicked button": "Result",
			DPP_subject: item?.subject_name || "",
			DPP_chapter: item?.chapter_name || "",
			Course: studentData?.section_assoc?.section_name || "",
			Batch: studentData?.section_assoc?.class_name || "",
		});
		Api.trackEvent(segmentEvents.DASHBOARD.MPP_RESULT);

		history.push({
			pathname: `/student/dpp-result/${item.id}`,
		});
	};

	const handleStartButtonClick = (item) => {
		//TODO: Segment
		Api.moEngageTrackEvent("Web-DPP_action_button", {
			Timestamp: Date(),
			"Clicked button": "Start",
			DPP_subject: item?.subject_name || "",
			DPP_chapter: item?.chapter_name || "",
			Course: studentData?.section_assoc?.section_name || "",
			Batch: studentData?.section_assoc?.class_name || "",
		});
		Api.trackEvent(segmentEvents.DASHBOARD.MPP_START);

		history.push({
			pathname: "/student/InstructionsGenericDpp",
			state: {
				studentId: studentData.id,
				userId: studentData.user_assoc,
				token,
				ExamScheduleId: item.id,
				studentName: studentData.name,
			},
		});
	};

	const handleContinueButtonClick = (item) => {
		//TODO: Segment
		Api.moEngageTrackEvent("Web-DPP_action_button", {
			Timestamp: Date(),
			"Clicked button": "Continue",
			DPP_subject: item?.subject_name || "",
			DPP_chapter: item?.chapter_name || "",
			Course: studentData?.section_assoc?.section_name || "",
			Batch: studentData?.section_assoc?.class_name || "",
		});
		Api.trackEvent(segmentEvents.DASHBOARD.MPP_CONTINUE);

		history.push({
			pathname: "/student/InstructionsGenericDpp",
			state: {
				studentId: studentData.id,
				userId: studentData.user_assoc,
				token,
				ExamScheduleId: item.id,
				studentName: studentData.name,
			},
		});
	};

	return (
		<div
			style={{
				paddingLeft: isMobile ? 0 : "2rem",
				paddingRight: !isMobile ? 0 : "2rem",
				scrollSnapAlign: "start",
			}}
			key={index}>
			<div className="dashboard_mpp_card">
				<div className="mpp_card_status_wrapper">
					{data.status ? (
						<>
							{data.button !== "Result" ? (
								data.button === "Continue" ? (
									<div
										className="mpp_card_status"
										style={{
											"--mpp_card_status": "#FFEACA",
										}}>
										<p
											className="mpp_card_status_text"
											style={{
												"--mpp_card_status_text": "#CE7C00",
											}}>
											<img
												alt="pending orange clock"
												src={PendingOrangeClock}
												className="mpp_status_img_pending"
												style={{
													width: "1.5rem",
												}}
											/>
											{data.status}
										</p>
									</div>
								) : (
									<div
										className="mpp_card_status"
										style={{
											"--mpp_card_status": "#FFD8D8",
										}}>
										<p
											className="mpp_card_status_text"
											style={{
												"--mpp_card_status_text": "#AC0E0E",
											}}>
											<img alt="pending red clock" src={PendingImg} className="mpp_status_img_pending" />
											{data.status}
										</p>
									</div>
								)
							) : (
								<div
									className="mpp_card_status"
									style={{
										"--mpp_card_status": "#E5FFDC",
									}}>
									<p
										className="mpp_card_status_text"
										style={{
											"--mpp_card_status_text": "#2CAA00",
										}}>
										<img alt="comsolete tick" src={CompletedImg} className="mpp_status_img_completed" />
										{data.status}
									</p>
								</div>
							)}
						</>
					) : (
						<>&nbsp;</>
					)}
				</div>

				{!data.teacher_name ? (
					<div className="faculty_mpp_wrapper_dummy" />
				) : (
					<OneLineTooltip title={`Hi ${studentData.name}, ${data.teacher_name} has assigned this MPP to you`}>
						<div id={`factuly-${index}`} className="faculty_mpp_wrapper">
							<img src={GreetingImg} alt="greeting" className="faculty_greeting_img" />
							Hi {studentData.name}, {data.teacher_name} has assigned this MPP to you
						</div>
					</OneLineTooltip>
				)}
				<p className="mpp_card_header">{data.name}</p>

				<div className="mpp_subject_chapter_wrapper">
					<p className="mpp_card_subject" style={{ backgroundColor: data.subject_primary_color }}>
						{data.subject_name}
					</p>

					<p className="mpp_card_chapter">{data.chapter_name}</p>
				</div>

				<div ref={tagsRef} className="mpp_tag_wrapper">
					{mainList.map((tag, i) => {
						return (
							<div className="d-flex align-items-center" key={i}>
								{i !== 0 && <div className="mpp_tag_seperator" />}
								<span className="mpp_card_tags">{tag}</span>
							</div>
						);
					})}
					{overflowList.length > 0 && (
						<div className="d-flex align-items-center">
							<div className="mpp_tag_seperator" />
							<Tooltip title={overflowList.map((tag) => tag).join(", ")} placement="bottom">
								<span className="test_chapters_more">+{overflowList.length}</span>
							</Tooltip>
						</div>
					)}
				</div>
				<div style={{ flexGrow: 1 }} />
				<center>
					{data.button !== "Result" ? (
						data.button === "Continue" ? (
							<SolidButton small onClick={() => handleContinueButtonClick(data)}>
								Continue
							</SolidButton>
						) : (
							<SolidButton small onClick={() => handleStartButtonClick(data)}>
								Start
							</SolidButton>
						)
					) : (
						<button className="dpp_result_button" onClick={() => handleResultButtonClick(data)}>
							Result
						</button>
					)}
				</center>
			</div>
		</div>
	);
};

export default MppCard;
