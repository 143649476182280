import React from 'react'
import ReactLoading from 'react-loading';

function LoadingComp() {
  return (
    <div style={{flex:1,justifyContent:'center',alignItems:'center',width:'100%',height:'100vh',display:'flex'}} >
       <div style={{justifyContent:'flex-start',alignItems:'center',flex:1,display:'flex',flexDirection:'column'}} >
        <h3 style={{padding:20}} >
        Loading... please wait
        </h3>
        <ReactLoading type='spinningBubbles' color='blue' height={'10%'} width={'10%'} />
       </div>
    </div>
  )
}

export default LoadingComp