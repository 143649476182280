/**
 * @typedef {Object} StudentExamDataType
 * @property {object} questions
 * @property {array} questionsStatus
 * @property {object} sectionQuestions
 */

/** @type { { [examStudentID: string]: ExamDataType } } */
export const initialState = {};

export const GENERIC_OFFLINE_SET_STUDENT_EXAM_DETAILS = "GENERIC_OFFLINE_SET_STUDENT_EXAM_DETAILS";
export const GENERIC_OFFLINE_EXAM_STARTED = "GENERIC_OFFLINE_EXAM_STARTED";
export const GENERIC_OFFLINE_UPDATE_QUESTION = "GENERIC_OFFLINE_UPDATE_QUESTION";

export default function studentExamDataReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GENERIC_OFFLINE_SET_STUDENT_EXAM_DETAILS: {
            if (payload) {
                const { questions, sectionQuestions, questionsStatus, questionsStatusIndexes, examId, studentId } = payload;

                const examStudentKey = examId && studentId && `${examId}_${studentId}`;

                if (examStudentKey) {
                    const currentState = { ...state };

                    currentState[examStudentKey] = {
                        questions,
                        questionsStatus,
                        questionsStatusIndexes,
                        sectionQuestions,
                        updatedAt: Date.now(),
                    };

                    return currentState;
                }
            }
            return state;
        }

        case GENERIC_OFFLINE_EXAM_STARTED: {
            if (payload) {
                const { examId, studentId, studentExamEndOn } = payload;

                const examStudentKey = examId && studentId && `${examId}_${studentId}`;

                if (examStudentKey) {
                    const currentState = { ...state };

                    currentState[examStudentKey] = {
                        ...currentState[examStudentKey],
                        studentExamEndOn,
                        updatedAt: Date.now(),
                    };

                    return currentState;
                }
            }

            return state;
        }

        case GENERIC_OFFLINE_UPDATE_QUESTION: {
            if (payload) {
                const { examId, studentId, serialNo, questionUpdateData: _questionUpdateData, questionStatusColor} = payload;
                const { current_attempt_seconds, ...questionUpdateData } = _questionUpdateData;
                const examStudentKey = examId && studentId && `${examId}_${studentId}`;

                if (examStudentKey) {
                    const currentState = { ...state };

                    const currentData = { ...currentState[examStudentKey] };

                    if (currentData) {
                        if (currentData.questions[serialNo]) {

                            const questionData = { ...currentData.questions[serialNo], ...questionUpdateData };
                        
                            if (current_attempt_seconds && !Array.isArray(questionData.current_attempt_seconds)) {
                                questionData.current_attempt_seconds = [current_attempt_seconds];
                            } else {
                                questionData.current_attempt_seconds.push(current_attempt_seconds);
                            }
    
                            currentData.questions[serialNo] = questionData;
    
                            const questionsStatusIndexes = currentData.questionsStatusIndexes;
                            const questionsStatusIndex = questionsStatusIndexes[serialNo];
    
                            const currentDataQuestionsStatus = Array.from(currentData.questionsStatus);
    
                            if (questionStatusColor) {
                                currentDataQuestionsStatus[questionsStatusIndex] = {
                                    ...currentDataQuestionsStatus[questionsStatusIndex],
                                    color_id: questionStatusColor,
                                }
    
                            } else if (currentDataQuestionsStatus[questionsStatusIndex].color_id === "0") {
                                currentDataQuestionsStatus[questionsStatusIndex] = {
                                    ...currentDataQuestionsStatus[questionsStatusIndex],
                                    color_id: "4",
                                }
                            }

                            currentData.questionsStatus = currentDataQuestionsStatus;
                        }
                    }

                    currentState[examStudentKey] = {
                        ...currentData,
                        updatedAt: Date.now(),
                    };

                    return currentState;
                }
            }

            return state;
        }

        default:
            return state;
    }
}
