import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import { cornerType } from "../../utils/constants";
import { RESET_CORNER_STATE, SET_CORNER_STATE } from "../actionType";

export const getFilterData = (type, hasSelectedClass, filterState) => async (dispatch) => {
	const payload = await Api.doFetch("GET", {}, `${ApiUrl.GET_CORNER_FILTERS}?corner_type=${type}`);
	if (payload.status) {
		dispatch({
			type: SET_CORNER_STATE,
			payload: {
				filters: payload.data,
				loading: false,
				...(!hasSelectedClass && { filterState: { ...filterState, selectedClass: payload.data.class[0]?.id } }),
			},
		});
	} else {
		dispatch({
			type: SET_CORNER_STATE,
			payload: { loading: false },
		});
	}
};

export const updateFilterState = (state) => async (dispatch) => {
	dispatch({
		type: SET_CORNER_STATE,
		payload: { filterState: state },
	});
};

export const getChapterDetails = (id, type, chapterBank, ncertQuestionsBank) => async (dispatch) => {
	if (chapterBank[id]) return;

	dispatch({
		type: SET_CORNER_STATE,
		payload: { loadingChapter: id },
	});
	const videoPayload = await Api.doFetch("GET", {}, `${ApiUrl.GET_CHAPTER_VIDEOS}?corner_type=${type}&chapter_id=${id}`);
	if (type !== cornerType.NCERT) {
		const notesPayload = await Api.doFetch("GET", {}, `${ApiUrl.GET_CHAPTER_NOTES}?corner_type=${type}&chapter_id=${id}`);
		dispatch({
			type: SET_CORNER_STATE,
			payload: {
				chapterBank: {
					...chapterBank,
					[id]: {
						videos: videoPayload.data ? videoPayload.data.videos : [],
						notes: notesPayload.data ? notesPayload.data.notes : [],
					},
				},
			},
		});
	} else {
		const exercisePayload = await Api.doFetch("GET", {}, `${ApiUrl.GET_NCERT_EXERCISE}?corner_type=${type}&chapter_id=${id}`);
		const questionsPayload = exercisePayload.data?.exercises?.[0]?.exercise_id
			? await Api.doFetch(
					"GET",
					{},
					`${ApiUrl.GET_NCERT_QUESTIONS}?corner_type=${type}&exercise_id=${exercisePayload.data.exercises[0].exercise_id}`
			  )
			: null;
		dispatch({
			type: SET_CORNER_STATE,
			payload: {
				chapterBank: {
					...chapterBank,
					[id]: {
						videos: videoPayload.data ? videoPayload.data.videos : [],
						notes: questionsPayload?.data ? questionsPayload.data.questions : [],
						tabs: exercisePayload.data ? exercisePayload.data.exercises : [],
					},
				},
			},
		});

		if (exercisePayload.data?.exercises?.[0])
			dispatch({
				type: SET_CORNER_STATE,
				payload: {
					ncertQuestionsBank: {
						...ncertQuestionsBank,
						[exercisePayload.data.exercises[0].exercise_id]: questionsPayload?.data
							? questionsPayload.data.questions
							: [],
					},
				},
			});
	}
	dispatch({
		type: SET_CORNER_STATE,
		payload: { loadingChapter: null },
	});
};

export const onTabChanged = (id, chapterId, type, chapterBank, ncertQuestionsBank) => async (dispatch) => {
	if (ncertQuestionsBank[id]) {
		dispatch({
			type: SET_CORNER_STATE,
			payload: {
				chapterBank: {
					...chapterBank,
					[chapterId]: {
						...chapterBank[chapterId],
						notes: ncertQuestionsBank[id],
					},
				},
			},
		});
		return;
	}

	const questionsPayload = await Api.doFetch("GET", {}, `${ApiUrl.GET_NCERT_QUESTIONS}?corner_type=${type}&exercise_id=${id}`);

	dispatch({
		type: SET_CORNER_STATE,
		payload: {
			chapterBank: {
				...chapterBank,
				[chapterId]: {
					...chapterBank[chapterId],
					notes: questionsPayload?.data ? questionsPayload.data.questions : [],
				},
			},
		},
	});

	dispatch({
		type: SET_CORNER_STATE,
		payload: {
			ncertQuestionsBank: {
				...ncertQuestionsBank,
				[id]: questionsPayload?.data ? questionsPayload.data.questions : [],
			},
		},
	});
};

export const resetCornerState = () => (dispatch) => {
	dispatch({
		type: RESET_CORNER_STATE,
	});
};
