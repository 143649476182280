export const segmentEvents = {
	GENERAL: {
		LOGIN: "W_STUDENT_SuccessfullLogin",
	},
	STUDENT: {
		DOWNLOAD_QUESTION_PAPER: "W_STUDENT_DownloadQuestionPaper",
		ALL_TEST: "W_STUDENT_AllTest",
		ATTEMPTED_TEST: "W_STUDENT_AttemptedTest",
		PENDING_TEST: "W_STUDENT_PendingTest",
		PAST_LIVE_CLASSES: "W_STUDENT_PastLiveClasses",
		ASSIGNMENT_VIEW: "W_STUDENT_AssignmentViewAll",
		TEST_VIEW: "W_STUDENT_TestViewAll",
	},
	MOENGAGE: {
		TEST_RESULT_VIEWED: "Web-Test Result Viewed",
		ASSIGNMENT_RESULT_VIEWED: "Web-Assignment Result Viewed",
		ASSIGNMENT_STARTED: "Web-Assignment Started",
		TEST_STARTED: "Web-Test Started",
		NOTES_VIEWED: "Web-Notes_Viewed",
		PAST_LIVE_CLASS_VIEWED: "Web-Past_Live_Class_Viewed",
		TIME_SPENT_ON_NOTES: "Web-Time spent on notes screen",
		LIVE_CLASS_JOINED: "Web-Live_Class_Joined",
		TIME_SPENT_ON_LIVE_CLASS: "Web-Time spent on Past Live class screen",
		TIME_SPENT_ON_ASSIGNMENT_LIST: "Web-Time spent on Assignment List screen",
		DPP_CLICKED_PAST_CLASS: "Web-DPP_clicked_on_past_class_card",
		TIME_SPENT_ON_DPP_LISTING: "Web-Time spent on DPP Listing screen",
		DPP_FILTER_BY_SUBJECT: "Web-DPP_filter_by_subject",
		DPP_FILTER_BY_STATUS: "Web-DPP_filter_by_status",
		DPP_ACTION_BTN: "Web-DPP_action_button",
		TIME_SPENT_ON_HOME: "Web-Time spent on Home Screen",
		DPP_CLICKED_DASHBOARD: "Web-DPP_clicked_on_dashboard",
	},
	LOGIN: {
		AUTH_SUCCESS: "WS_LOGIN_AUTH_SUCCESS",
		AUTH_FAILED: "WS_LOGIN_AUTH_FAILED",
		FORGOT_PWD_CLICK: "WS_LOGIN_FORGOTPWD_CLICK",
		REGISTER_CLICK: "WS_LOGIN_REGISTER_CLICK",
	},
	FORGOTPWD: {
		SEND_OTP_SUCCESS: "WS_FORGOTPWD_SENDOTP_SUCCESS",
		SEND_OTP_FAILED: "WS_FORGOTPWD_SENDOTP_FAILED",
		BACK_TO_LOGIN_CLICK: "WS_FORGOTPWD_BACKLOGIN_CLICK",
		OTP_SUBMIT_SUCCESS: "WS_FORGOTPWD_OTPSUBMIT_SUCCESS",
		OTP_SUBMIT_FAILED: "WS_FORGOTPWD_OTPSUBMIT_FAILED",
		RESETPWD_SUCCESS: "WS_FORGOTPWD_RESETPWD_SUCCESS",
		RESETPWD_FAILED: "WS_FORGOTPWD_RESETPWD_FAILED",
	},
	REGISTER: {
		BACKLOGIN_CLICK: "WS_REGISTER_BACKLOGIN_CLICK",
		OTP_SUCCESS: "WS_REGISTER_OTP_SUCCESS",
		OTP_FAILED: "WS_REGISTER_OTP_FAILED",
		OTP_CHANGE: "WS_REGISTER_OTP_CHANGE",
		OTP_RESEND_SUCCESS: "WS_REGISTER_OTP_RESEND_SUCCESS",
		OTP_RESEND_FAILED: "WS_REGISTER_OTP_RESEND_FAILED",
		OTP_VERIFY: "WS_REGISTER_OTP_VERIFY",
		STEP1_SUCCESS: "WS_REGISTER_STEP1_SUCCESS",
		STEP1_FAILED: "WS_REGISTER_STEP1_FAILED",
		STEP2_SUCCESS: "WS_REGISTER_STEP2_SUCCESS",
		STEP2_FAILED: "WS_REGISTER_STEP2_FAILED",
		STEP3_SUCCESS: "WS_REGISTER_STEP3_SUCCESS",
		STEP3_FAILED: "WS_REGISTER_STEP3_FAILED",
		STEP3_LOCATION_SUCCESS: "WS_REGISTER_STEP3_LOCATION_SUCCESS",
		STEP3_LOCATION_FAILED: "WS_REGISTER_STEP3_LOCATION_FAILED",
		TERMS_CLICK: "WS_REGISTER_TERMS_CLICK",
	},
	NAVBAR: {
		SCHEDULE_CLICK: "WS_NAVBAR_SCHEDULE_CLICK",
		SOLUTIONS_CLICK: "WS_NAVBAR_SOLUTIONS_CLICK",
		NOTIFICATIONS_CLICK: "WS_NAVBAR_NOTIFICATIONS_CLICK",
		PROFILE_CLICK: "WS_NAVBAR_PROFILE_CLICK",
		SWITCHBATCH_CLICK: "WS_NAVBAR_SWITCHBATCH_CLICK",
		CHANGEPASSWORD_CLICK: "WS_NAVBAR_CHANGEPASSWORD_CLICK",
		HELPSUPPORT_CLICK: "WS_NAVBAR_HELPSUPPORT_CLICK",
		LOGOUT_CLICK: "WS_NAVBAR_LOGOUT_CLICK",
		RESPONSIVE_ATTENDANCE_CLICK: "WS_NAVBAR_RESPONSIVE_ATTENDANCE_CLICK",
	},
	SWITCHBATCH: {
		DONE_CLICK: "WS_SWITCHBATCH_DONE_CLICK",
	},
	PROFILE: {
		UPDATEPARENT_CLICK: "WS_PROFILE_UPDATEPARENT_CLICK",
	},
	EDITPARENT: {
		SUBMIT_CLICK: "WS_EDITPARENT_SUBMIT_CLICK",
	},
	CHANGEPWD: {
		CHANGE_CLICK: "WS_CHANGEPWD_CHANGE_CLICK",
	},
	HELP: {
		SUBMIT_CLICK: "WS_HELP_SUBMIT_CLICK",
	},
	DASHBOARD: {
		MPP_VIEWALL: "WS_DASHBOARD_MPP_VIEWALL",
		MPP_START: "WS_DASHBOARD_MPP_START",
		MPP_CONTINUE: "WS_DASHBOARD_MPP_CONTINUE",
		MPP_RESULT: "WS_DASHBOARD_MPP_RESULT",
		TEST_VIEWALL: "WS_DASHBOARD_TEST_VIEWALL",
		TEST_START: "WS_DASHBOARD_TEST_START",
		TEST_CONTINUE: "WS_DASHBOARD_TEST_CONTINUE",
		TEST_PRACTICE: "WS_DASHBOARD_TEST_PRACTICE",
		TEST_QUESTIONPAPER: "WS_DASHBOARD_TEST_QUESTIONPAPER",
		TEST_RESULT: "WS_DASHBOARD_TEST_RESULT",
		CLASSES_VIEWALL: "WS_DASHBOARD_CLASSES_VIEWALL",
		CLASSES_NOTES: "WS_DASHBOARD_CLASSES_NOTES",
		CLASSES_REPLAY: "WS_DASHBOARD_CLASSES_REPLAY",
		CLASSES_JOIN: "WS_DASHBOARD_CLASSES_JOIN",
	},
	FREEDASHBOARD: {
		HEADER_ENROLL: "WS_FREEDASHBOARD_HEADER_ENROLL",
		REQUESTCALL_MPP: "WS_FREEDASHBOARD_REQUESTCALL_MPP",
		REQUESTCALL_TEST: "WS_FREEDASHBOARD_REQUESTCALL_TEST",
		REQUESTCALL_CLASSES: "WS_FREEDASHBOARD_REQUESTCALL_CLASSES",
		REQUESTCALL_ATTENDANCE: "WS_FREEDASHBOARD_REQUESTCALL_ATTENDANCE",
	},
	MPP: {
		SUBJECT_CLICK: "WS_MPP_SUBJECT_CLICK",
		SUBJECT_RESET: "WS_MPP_SUBJECT_RESET",
		CHAPTER_CLICK: "WS_MPP_CHAPTER_CLICK",
		CHAPTER_RESET: "WS_MPP_CHAPTER_RESET",
		FILTER_ALL: "WS_MPP_FILTER_ALL",
		FILTER_PENDING: "WS_MPP_FILTER_PENDING",
		FILTER_COMPLETED: "WS_MPP_FILTER_COMPLETED",
		START_CLICK: "WS_MPP_START_CLICK",
		CONTINUE_CLICK: "WS_MPP_CONTINUE_CLICK",
		RESULT_CLICK: "WS_MPP_RESULT_CLICK",
	},
	TEST: {
		START_CLICK: "WS_TEST_START_CLICK",
		CONTINUE_CLICK: "WS_TEST_CONTINUE_CLICK",
		PRACTICE_CLICK: "WS_TEST_PRACTICE_CLICK",
		QUESTIONPAPER_CLICK: "WS_TEST_QUESTIONPAPER_CLICK",
		RESULT_CLICK: "WS_TEST_RESULT_CLICK",
	},
	GENERIC: {
		INSTR_LANGUAGE: "WS_GENERIC_INSTR_LANGUAGE",
		INSTR_NEXT: "WS_GENERIC_INSTR_NEXT",
		SECTION_CLICK: "WS_GENERIC_SECTION_CLICK",
		QUESTION_CLICK: "WS_GENERIC_QUESTION_CLICK",
		INSTR_CLICK: "WS_GENERIC_INSTR_CLICK",
		SECTIONINSTR_CLICK: "WS_GENERIC_SECTIONINSTR_CLICK",
		MARKSCHEME_CLICK: "WS_GENERIC_MARKSCHEME_CLICK",
		MARKREVIEW_CLICK: "WS_GENERIC_MARKREVIEW_CLICK",
		NEXT_CLICK: "WS_GENERIC_NEXT_CLICK",
		SUBMIT_CLICK: "WS_GENERIC_SUBMIT_CLICK",
		SUBMIT_YES: "WS_GENERIC_SUBMIT_YES",
		SUBMIT_NO: "WS_GENERIC_SUBMIT_NO",
	},
	MAINS: {
		INSTR_LANGUAGE: "WS_MAINS_INSTR_LANGUAGE",
		INSTR_NEXT: "WS_MAINS_INSTR_NEXT",
		LANGUAGE_CLICK: "WS_MAINS_LANGUAGE_CLICK",
		QUESTION_CLICK: "WS_MAINS_QUESTION_CLICK",
		SAVENEXT_CLICK: "WS_MAINS_SAVENEXT_CLICK",
		CLEAR_CLICK: "WS_MAINS_CLEAR_CLICK",
		SAVEREVIEW_CLICK: "WS_MAINS_SAVEREVIEW_CLICK",
		REVIEWNEXT_CLICK: "WS_MAINS_REVIEWNEXT_CLICK",
		BACK_CLICK: "WS_MAINS_BACK_CLICK",
		NEXT_CLICK: "WS_MAINS_NEXT_CLICK",
		SUBMIT_CLICK: "WS_MAINS_SUBMIT_CLICK",
		SIDEBAR_COLLAPSE: "WS_MAINS_SIDEBAR_COLLAPSE",
		SIDEBAR_EXPAND: "WS_MAINS_SIDEBAR_EXPAND",
	},
	ADVANCED: {
		INSTR_LANGUAGE: "WS_ADVANCED_INSTR_LANGUAGE",
		INSTR_NEXT: "WS_ADVANCED_INSTR_NEXT",
		IMPINSTR_LANGUAGE: "WS_ADVANCED_IMPINSTR_LANGUAGE",
		IMPINSTR_PREVIOUS: "WS_ADVANCED_IMPINSTR_PREVIOUS",
		IMPINSTR_BEGIN: "WS_ADVANCED_IMPINSTR_BEGIN",
		LANGUAGE_CLICK: "WS_ADVANCED_LANGUAGE_CLICK",
		SECTION_CLICK: "WS_ADVANCED_SECTION_CLICK",
		QUESTION_CLICK: "WS_ADVANCED_QUESTION_CLICK",
		QUESTIONPAPER_CLICK: "WS_ADVANCED_QUESTIONPAPER_CLICK",
		INSTR_CLICK: "WS_ADVANCED_INSTR_CLICK",
		REVIEWNEXT_CLICK: "WS_ADVANCED_REVIEWNEXT_CLICK",
		CLEAR_CLICK: "WS_ADVANCED_CLEAR_CLICK",
		SAVENEXT_CLICK: "WS_ADVANCED_SAVENEXT_CLICK",
		SCROLL_UP: "WS_ADVANCED_SCROLL_UP",
		SCROLL_DOWN: "WS_ADVANCED_SCROLL_DOWN",
	},
	SCHEDULE: {
		CLASS_CLICK: "WS_SCHEDULE_CLASS_CLICK",
		TEST_CLICK: "WS_SCHEDULE_TEST_CLICK",
		PTM_CLICK: "WS_SCHEDULE_PTM_CLICK",
		COURSE_CLICK: "WS_SCHEDULE_COURSE_CLICK",
	},
	SOLUTIONS: {
		SEARCH_CLICK: "WS_SOLUTIONS_SEARCH_CLICK",
		RECORDING_CLICK: "WS_SOLUTIONS_RECORDING_CLICK",
	},
	NOTIFICATIONS: {
		VIEW_CLICK: "WS_NOTIFICATIONS_VIEW_CLICK",
		PAGE_NEXT: "WS_NOTIFICATIONS_PAGE_NEXT",
		PAGE_BACK: "WS_NOTIFICATIONS_PAGE_BACK",
		PAGE_FIRST: "WS_NOTIFICATIONS_PAGE_FIRST",
		PAGE_LAST: "WS_NOTIFICATIONS_PAGE_LAST",
	},
	PASTCLASSES: {
		LIVECLASSES_CLICK: "WS_PASTCLASSES_LIVECLASSES_CLICK",
		FILTER_SUBJECT: "WS_PASTCLASSES_FILTER_SUBJECT",
		FILTER_CHAPTER: "WS_PASTCLASSES_FILTER_CHAPTER",
		FILTER_SORT: "WS_PASTCLASSES_FILTER_SORT",
		CLASS_REPLAY: "WS_PASTCLASSES_CLASS_REPLAY",
		CLASS_DPP: "WS_PASTCLASSES_CLASS_DPP",
		CLASS_NOTES: "WS_PASTCLASSES_CLASS_NOTES",
		LOADMORE_CLICK: "WS_PASTCLASSES_LOADMORE_CLICK",
	},
	LIVECLASSES: {
		PASTCLASSES_CLICK: "WS_LIVECLASSES_PASTCLASSES_CLICK",
		JOIN_CLICK: "WS_LIVECLASSES_JOIN_CLICK",
	},
	FOOTER: {
		MAIL_CLICK: "WS_FOOTER_MAIL_CLICK",
		PLAYSTORE_CLICK: "WS_FOOTER_PLAYSTORE_CLICK",
		REFUND_CLICK: "WS_FOOTER_REFUND_CLICK",
		PRIVACY_CLICK: "WS_FOOTER_PRIVACY_CLICK",
		TERMS_CLICK: "WS_FOOTER_TERMS_CLICK",
		WHATSAPP_CLICK: "WS_FOOTER_WHATSAPP_CLICK",
		FACEBOOK_CLICK: "WS_FOOTER_FACEBOOK_CLICK",
		TWITTER_CLICK: "WS_FOOTER_TWITTER_CLICK",
		LINKEDIN_CLICK: "WS_FOOTER_LINKEDIN_CLICK",
		YOUTUBE_CLICK: "WS_FOOTER_YOUTUBE_CLICK",
		INSTAGRAM_CLICK: "WS_FOOTER_INSTAGRAM_CLICK",
	},
};

export const signUpStep = {
	REGISTER: "register",
	REGISTER_STEP1: "register_step1",
	REGISTER_STEP2: "register_step2",
	REGISTER_STEP3: "register_step3",
	CONGRATS: "congrats",
	WELCOME: "welcome",
};

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const pageTitles = {
	ROOT: "myclassroom",
	SIGNIN: "Login",
	SIGNUP: "Register",
	FORGOT_PASSWORD: "Forgot Password",
	DASHBOARD: "Dashboard",
	SOLUTIONS: "Solutions",
	NOTIFICATIONS: "Notifications",
	ATTENDANCE: "Attendance",
	TESTS: "Tests",
	SCHEDULE: "Schedule",
	PROFILE: "Profile",
	CHANGE_PASSWORD: "Change Password",
	HELP_SUPPORT: "Help and Support",
	DPP_LISTING: "Mandatory Practice Problems",
	PAST_LIVE_CLASSES: "Past Classes",
	UPCOMING_LIVE_CLASSES: "Live Classes",
	EXAM_RESULT: "Exam Result",
	EXAM_ANSWERS: "Exam Answer Keys",
	DPP_RESULT: "DPP Result",
	EDIT_PARENT: "Edit Parent",
	PROFILE_LOCKED: "Profile Locked",
	MPP_INSTRUCTIONS: "MPP Instructions",
	MPP_EXAM: "MPP Exam",
	JEE_INSTRUCTIONS: "JEE Mains Instructions",
	JEE_EXAM: "JEE Mains Exam",
	EXAM_SUBMITTED: "Exam Submitted",
	GENERIC_INSTRUCTIONS: "Generic Instructions",
	GENERIC_EXAM: "Generic Exam",
	JEE_ADV_INSTRUCTIONS: "JEE Advanced Instructions",
	JEE_ADV_IMP_INSTRUCTIONS: "JEE Advanced Important Instructions",
	JEE_ADV_EXAM: "JEE Advanced Exam",
	NCERT_CORNER: "NCERT Corner",
	JEE_CORNER: "JEE Corner",
	NEET_CORNER: "NEET Corner",
	NCERT_LECTURE: "NCERT Lecture",
	JEE_LECTURE: "JEE Lecture",
	NEET_LECTURE: "NEET Lecture",
	NCERT_EXERCISE_QUESTIONS: "NCERT Exercise Questions",
};
export const dashboardIds = {
	MPP: "mpp",
	TESTS: "tests",
	CLASSES: "classes",
	NCERT: "ncert-corner",
	JEE: "jee-corner",
	NEET: "neet-corner",
};

export const cornerType = {
	NCERT: "ncert",
	JEE: "jee",
	NEET: "neet",
};
