import React from "react";
import "./styles.css";

const QuestionReviewAnswered = ({
  width,
  height,
  customCss = {},
  children,
}) => {
  return (
    <div
      className="Review_answered mob-res"
      style={{
        ...(width && { width }),
        ...(height && { height }),
        ...customCss,
      }}
    >
      {children}
    </div>
  );
};

export default QuestionReviewAnswered;
