import { Tooltip } from "antd";
import React from "react";

const OneLineTooltip = ({ children, title, customStyle }) => {
	const [ellipsisNeeded, setEllipsisNeeded] = React.useState(false);
	const ref = React.useRef();

	React.useEffect(() => {
		const e = ref.current;
		if (e) setEllipsisNeeded(e.offsetWidth < e.scrollWidth);
	}, []);

	const style = {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "noWrap",
		...customStyle,
	};

	return ellipsisNeeded ? (
		<Tooltip title={title} placement="bottom">
			{React.cloneElement(children, {
				style,
			})}
		</Tooltip>
	) : (
		React.cloneElement(children, {
			ref,
			style,
		})
	);
};

export default OneLineTooltip;
