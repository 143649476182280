import React from "react";
import "./styles.css";
const QuestionNotVisited = ({ width, height, customCss = {}, children }) => {
  return (
    <div
      className="unanswered_inst"
      style={{
        ...(width && { width }),
        ...(height && { height }),
        ...customCss,
      }}
    >
      {children}
    </div>
  );
};

export default QuestionNotVisited;
