import React, { useEffect, useState } from "react";
import { Select, Skeleton } from "antd";
import Styles from "./CommonCard.module.css";
import "antd/dist/antd.css";
import "./customAntSelectChapter.css";
import CollapsibleItem from "./CollapsibleItem";
import NoContentDiv from "./NoContentAvailable";
import { useSelector } from "react-redux";
import { getCornerData, updateCornerSelectedClass } from "../../store/actions";
import { useDispatch } from "react-redux";

const { Option } = Select;

const CommonCard = ({ title, active, navigate, id, type }) => {
	const dashboard = useSelector((state) => state.dashboard);
	const { selectedClass, selectedSubject } = dashboard;
	const { classes, subjects, loading, loaded } = dashboard[type];
	const dispatch = useDispatch();
	const [selectedClassLoading, setSelectedClassLoading] = useState(false);

	useEffect(() => {
		if (!loaded) dispatch(getCornerData(type));
	}, []);

	const onClassChange = (value) => {
		setSelectedClassLoading(true);
		dispatch(updateCornerSelectedClass({ [type]: value }));
		dispatch(getCornerData(type, value)).then(() => {
			setSelectedClassLoading(false);
		});
	};

	return (
		<div className={Styles.container} id={id}>
			<div className={Styles.card}>
				<div className={Styles.row_flex_start}>
					{active && <div className="dashboard_content_active" style={{ marginLeft: "-2rem " }} />}

					<p className={Styles.dashboard_content_header}>{title}</p>
					<Select
						placeholder="Select Class"
						value={selectedClass[type]}
						onChange={(value) => {
							onClassChange(value);
						}}
						className="test_module_drop"
						loading={loading}>
						{classes.map((cls, index) => (
							<Option key={index} value={cls.id}>
								{cls.class_name}
							</Option>
						))}
					</Select>
				</div>

				<p
					className="dashboard_content_view_all"
					onClick={() =>
						navigate({
							classId: selectedClass[type],
						})
					}>
					See all
				</p>
			</div>
			{selectedClassLoading || loading ? (
				<div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
					<Skeleton.Input active={active} size="large" block="checked" />

					<div style={{ display: "flex", gap: 10 }}>
						<Skeleton.Avatar active={active} size="large" shape="circle" />
						<Skeleton.Input active={active} size="large" />
					</div>
					<Skeleton
						paragraph={{
							rows: 1,
						}}
					/>
					<Skeleton.Input active={active} size="large" block="checked" />
				</div>
			) : subjects.length > 0 ? (
				subjects.map((subjectData, index) => {
					return (
						<div key={index} style={{ borderRadius: 8, marginTop: 10 }}>
							<CollapsibleItem
								subjectData={subjectData}
								itemIndex={index}
								type={type}
								expandedId={selectedSubject[type]}
							/>
						</div>
					);
				})
			) : (
				<NoContentDiv message={`No ${type.toUpperCase()} content available`} subMessage="Check back later" />
			)}
		</div>
	);
};

export default React.memo(CommonCard);
