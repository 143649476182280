import React from "react";
import Avatar from "../../../assets/img/dashboard_avatar.png";
import SolidButton from "../../../components/common/SolidButton";
import { months } from "../../../utils/constants";

const AITSBanner = ({ onlineModalOpenHandler, banner }) => {
	const startDate = new Date(banner.course_start_date);

	return (
		<div className="dashboard_user_meta">
			<div className="d-flex flex-grow align-items-center">
				<div className="dashboard_user_avatar_container">
					<img src={Avatar} alt="Avatar" className="dashboard_user_avatar" />
				</div>

				<div className="dashboard_student_meta_container">
					<div className="user_heading_container">
						<p className="dashboard_text1">
							{banner.post_enrollment ? banner.pre_launch_header : banner.course_name}
						</p>
						<div className="meta_date_tag">
							Starts on {months[startDate.getMonth()]} {startDate.getDate()}
						</div>
					</div>
					{banner.post_enrollment ? (
						<div>
							<span className="pre_launch_desc">{banner.pre_launch_desc}</span>
						</div>
					) : (
						<div
							className="d-flex align-items-center"
							style={{
								flexWrap: "wrap",
							}}>
							{banner.features.map((feat, i) => (
								<React.Fragment key={i}>
									{feat.snapshot && i !== 0 && <div className="mpp_tag_seperator" />}
									<span className="mpp_card_tags">{feat.snapshot}</span>
								</React.Fragment>
							))}
						</div>
					)}
				</div>
			</div>
			{!banner.post_enrollment && (
				<div className="dpp_button_container">
					<SolidButton
						small
						style={{
							marginTop: 0,
							width: 165,
						}}
						onClick={onlineModalOpenHandler}>
						Enroll Now
					</SolidButton>
				</div>
			)}
		</div>
	);
};

export default AITSBanner;
