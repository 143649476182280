import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { Progress } from "antd";
import axios from "axios";
import throttle from "lodash.throttle";
import Swal from "sweetalert2";
import Lottie from "lottie-react";

import "../styles/common.css";
import "../styles/header.css";

import Api, { isResponseOffline } from "../../../common/Api";
import ApiUrl from "../../../common/ApiUrl";
import LoadingComp from "../../Jeemains/components/LoadingComp";
// import LoadingGraphic from "../../../images/genericV2/loading.png";
import TestReadyPNG from "../../../images/genericV2/test-ready.png";
import loadingAnimationData from "./loadingAnimation.lottie.json";
// import MyClassroomLogoSVG from "../../../images/genericV2/myclassroom-logo.svg";

import { GENERIC_OFFLINE_SET_MISC_DETAILS } from "../../../store/reducers/examReducer/miscReducer";
import { GENERIC_OFFLINE_SET_EXAM_DETAILS } from "../../../store/reducers/examReducer/examDataReducer";
import { GENERIC_OFFLINE_SET_STUDENT_EXAM_DETAILS } from "../../../store/reducers/examReducer/studentExamDataReducer";
import { examStore } from "../../../store/examStore";
import { segmentEvents } from "../../../utils../../utils/constants";

const Header = () => {
	return (
		<nav className="nav-bar">
			<div>
				<div className="genericV2-myclassroom-logo"></div>
				{/* <img className="nav-logo-img" src={MyClassroomLogoSVG} alt="myclassroom-logo" /> */}
			</div>
		</nav>
	);
};

const styles = {
	td: {
		padding: "5px 15px 5px 5px",
	},
	td2: {
		paddingRight: 10,
		fontWeight: 500,
	},
	td3: {
		fontWeight: 400,
	},
	sections: {
		marginTop: 25,
		fontWeight: 500,
	},
};

const arrayToMap = (arr = [], keyField = "id", fn = null) =>
	arr.reduce(
		(pv, el) => ({
			...pv,
			[el[keyField]]: typeof fn === "function" ? fn(el) : el,
		}),
		{}
	);

const arrayToIndexesMap = (arr = [], keyField = "id") =>
	arr.reduce(
		(pv, el, key) => ({
			...pv,
			[el[keyField]]: key,
		}),
		{}
	);

const useCurrentExam = () => {
	const examData = useSelector((state) => state.examData);
	const examMiscData = useSelector((state) => state.miscData);

	const currentExamData = useMemo(() => {
		if (examData && examMiscData.loaded && examMiscData.currentExamId && examMiscData.currentExamId in examData) {
			return examData[examMiscData.currentExamId];
		}

		return null;
	}, [examData, examMiscData.loaded, examMiscData.currentExamId]);

	return {
		currentExamData,
	};
};

const useCurrentExamSections = () => {
	const { currentExamData } = useCurrentExam();

	const examSectionsList = React.useMemo(() => {
		if (currentExamData?.examSections) {
			return Object.keys(currentExamData.examSections).reduce(
				(pv, sectionId) => [...pv, currentExamData.examSections[sectionId]],
				[]
			);
		}

		return [];
	}, [currentExamData]);

	const examSectionsInstructions = React.useMemo(() => {
		if (examSectionsList?.length > 0) {
			return examSectionsList.map((sectionData, sectionId) => (
				<div style={styles.sections} key={`section-${sectionId}`}>
					<h5>{sectionData.section_name}</h5>
					{parse(sectionData.section_instructions)}
				</div>
			));
		}
		return [];
	}, [examSectionsList]);

	return {
		examSectionsList,
		examSectionsInstructions,
	};
};

export default function GenericV2OfflineInstructions({ isDPP = false }) {
	const history = useHistory();
	const { examId } = useParams();
	const dispatch = useDispatch();

	const studentExamData = useSelector((state) => state.studentExamData);
	const { currentStudentId } = useSelector((state) => state.miscData);

	const { currentExamData } = useCurrentExam();
	const { examSectionsInstructions } = useCurrentExamSections();

	const [loading, setLoading] = useState(false);
	const [questionsLoading, setQuestionsLoading] = useState(false);
	const [downloadProgress, setDownloadProgress] = useState(0);
	const [questionsDownloaded, setQuestionsDownloaded] = useState(false);

	const throttledOnProgress = useRef(
		throttle((progressEvent) => {
			const percentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

			setDownloadProgress(percentage);
		}, 250)
	);

	const downloadQuestions = async () => {
		setQuestionsLoading(true);
		setDownloadProgress(0);

		const token = Api.isAuthenticated();

		const headers = {
			Authorization: `Token ${token}`,
			"Content-Type": "application/json",
		};

		try {
			const { data: resData } = await axios.get(`${ApiUrl.FETCH_ALL_QUESTIONS}/${examId}`, {
				headers,
				onDownloadProgress: throttledOnProgress.current,
			});

			throttledOnProgress.current?.cancel();

			if (resData?.status === true) {
				const data = resData.data;

				const sectionQuestions = {};

				for (const question of data.section_questions) {
					const sectionKey = question.exam_section_assoc;

					if (sectionKey) {
						if (sectionQuestions[sectionKey]) {
							sectionQuestions[sectionKey].push(question.serial_no);
						} else {
							sectionQuestions[sectionKey] = [question.serial_no];
						}
					}
				}

				dispatch({
					type: GENERIC_OFFLINE_SET_STUDENT_EXAM_DETAILS,
					payload: {
						questions: arrayToMap(data.section_questions, "serial_no"),
						questionsStatus: data.question_status_list,
						questionsStatusIndexes: arrayToIndexesMap(data.question_status_list, "serial_no"),
						sectionQuestions,
						examId: examId,
						studentId: data.student_id,
					},
				});

				setQuestionsDownloaded(true);
				setDownloadProgress(100);
			} else {
				Swal.fire("Error", "Failed to download questions, please try again", "error");
				setQuestionsDownloaded(false);
				setDownloadProgress(0);
			}

			setTimeout(() => {
				setQuestionsLoading(false);
			}, 500);
		} catch (error) {
			if (isResponseOffline(error?.message)) {
				Swal.fire("", "Your connection seems unstable. Please check your internet connection.", "error");
			} else {
				Swal.fire("Error", "Failed to download questions, please try again", "error");
			}
			setQuestionsDownloaded(false);
			setDownloadProgress(0);
			setQuestionsLoading(false);
		}
	};

	const handleStartExam = () => {
		history.replace({
			pathname: `/student/generic/${examId}/exam`,
		});
	};

	const handleClickNext = () => {
		Api.trackEvent(segmentEvents.GENERIC.INSTR_NEXT);
		const examStudentKey = examId && currentStudentId && `${examId}_${currentStudentId}`;

		const { studentExamData } = examStore.getState();

		if (examStudentKey in studentExamData && studentExamData[examStudentKey]) {
			handleStartExam();
		} else {
			downloadQuestions();
		}
	};

	useEffect(() => {
		const goBack = (key) => {
			const redirectPath = {
				test: "/student/objectivetest",
				home: "/student",
			};

			history.replace(redirectPath[key] || redirectPath["home"]);
		};

		const getExamInfo = async () => {
			if (!examId) return;

			setLoading(true);

			try {
				const examInfoRes = await Api.doFetch("GET", {}, `${ApiUrl.GET_EXAM_INFO_V2}/${examId}`);

				if (!examInfoRes) return;

				if (examInfoRes.status === true) {
					const examInfoData = examInfoRes.data;

					dispatch({
						type: GENERIC_OFFLINE_SET_EXAM_DETAILS,
						payload: {
							examID: examInfoData.exam_info.id,
							examData: examInfoData.exam_info,
							examSubjects: arrayToMap(examInfoData.exam_subjects),
							examSections: arrayToMap(examInfoData.exam_sections),
							examSectionsList: examInfoData.exam_sections.map((s) => s.id),
						},
					});

					const studentId = String(examInfoData.student_id);

					dispatch({
						type: GENERIC_OFFLINE_SET_MISC_DETAILS,
						payload: {
							studentId,
							examId: examId,
							loaded: true,
						},
					});
				} else {
					if (examInfoRes.message === "Validation error") {
						Swal.fire({
							title: examInfoRes.data?.message || "Exam window is closed",
						});

						return goBack("test");
					}
				}
			} catch (error) {
				setLoading(false);
			} finally {
				setLoading(false);
			}
		};

		getExamInfo();
	}, [history, dispatch, examId]);

	if (loading) return <LoadingComp />;

	if (questionsLoading || questionsDownloaded) {
		return (
			<>
				<Header />

				<div className="container genericV2-instructions">
					{questionsLoading && (
						<div className="genericV2-instructions-loading">
							<Lottie
								animationData={loadingAnimationData}
								loop
								className="genericV2-instructions-loading-graphic"
							/>
							{/* <div className="genericV2-instructions-loading-graphic">
                                <img src={LoadingGraphic} alt="genericV2-instructions-loading-graphic" />
                            </div> */}

							<div className="genericV2-instructions-percentage">{downloadProgress}%</div>

							<Progress
								trailColor="#dae5f0"
								strokeColor="#004e9b"
								percent={downloadProgress}
								style={{ maxWidth: 225 }}
								size="small"
								status="active"
								showInfo={false}
							/>

							<div className="genericV2-instructions-p1">Populating questions for you...</div>

							<div className="genericV2-instructions-p2">Just a few moments before you can start the test.</div>
						</div>
					)}

					{!questionsLoading && questionsDownloaded && (
						<div className="genericV2-instructions-loading">
							<div className="genericV2-instructions-ready">
								<img src={TestReadyPNG} alt="genericV2-instructions-ready" />
							</div>

							<div className="genericV2-instructions-p1">You can start your test now</div>

							<div className="genericV2-instructions-btn" onClick={handleStartExam}>
								Start Now
							</div>
						</div>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<Header />

			<div className="container genericV2-instructions">
				<div className="genericV2-instructions-page">
					<div className="text-right lang-select genericV2-instructions-lang">
						<p>
							<span style={{ fontWeight: "600" }}>View In:</span> &nbsp;
							<select
								name="Language"
								id=""
								className="lang-ddown"
								onClick={() => Api.trackEvent(segmentEvents.GENERIC.INSTR_LANGUAGE)}>
								<option>English</option>
							</select>
						</p>
					</div>
					<div className="genericV2-instructions-title">
						{!isDPP && (
							<p className="text-center">
								<span style={{ fontWeight: "600" }}>Read the Instructions Carefully</span>
							</p>
						)}
					</div>
					<div className="inst-deets genericV2-instructions-details">
						{!isDPP && (
							<p style={{ fontWeight: "600" }}>
								<span style={{ fontSize: "1.3em" }}>G</span>ENERAL <span style={{ fontSize: "1.3em" }}>I</span>
								NSTRUCTIONS
							</p>
						)}
						<div className="inst-inner">
							{currentExamData?.examData?.exam_instructions && parse(currentExamData.examData.exam_instructions)}

							<div className="container-fluid" style={{ paddingLeft: 0 }}>
								<div className="row instructions-row">
									<div className="col-xs-12 col-lg-7">
										<div className="marks-symbol">
											<table>
												<tbody>
													<tr className="table-row">
														<td style={styles.td}>
															<div className="not-visited-div"></div>
														</td>
														<td style={styles.td2}>Not Visited</td>
														<td style={styles.td3}>Question has not been visited yet.</td>
													</tr>

													<tr className="table-row">
														<td style={styles.td}>
															<div className="not-answered-active-div"></div>
														</td>
														<td style={styles.td2}>Not Answered</td>
														<td style={styles.td3}>Question has been visited but not answered.</td>
													</tr>

													<tr className="table-row">
														<td style={styles.td}>
															<div style={{ padding: 5 }} className="answered-active-div"></div>
														</td>
														<td style={styles.td2}>Answered</td>
														<td style={styles.td3}>
															Question has been answered and will be considered for evaluation.
														</td>
													</tr>

													<tr className="table-row">
														<td style={styles.td}>
															<div style={{ padding: 5 }} className="not-ans-mkd-active-div"></div>
														</td>
														<td style={styles.td2}>Not Answered & Marked For Review</td>
														<td style={styles.td3}>
															Question has not been answered and is marked for review.
														</td>
													</tr>

													<tr className="table-row">
														<td style={styles.td}>
															<div className="ans-mkd-active-div"></div>
														</td>
														<td style={styles.td2}>Answered & Marked For Review</td>
														<td style={styles.td3}>
															Question has been answered and is marked for review. Answer will be
															considered for evaluation.
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-xs-12 col-lg-5">
										<div className="marks-symbol disclaimer">
                                            <strong>Please note</strong>
                                            <p>You are not allowed to switch from the exam window. If you do so, you will not be able to return back to the exam</p>
                                        </div>
									</div>
								</div>
							</div>

							{examSectionsInstructions}
						</div>
					</div>
				</div>

				<div className="button-container text-right genericV2-instructions-buttons">
					<div className="container next-btn-container">
						<button className="next-btn-inst" onClick={handleClickNext}>
							Next &nbsp; &nbsp; &gt;
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
