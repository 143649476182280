import React from "react";
import parse from "html-react-parser";
import { parserConfig } from "./utils/misc";

function GenericV2QuestionSingleChoice({ currentQuestion = null, handleSelectOption, isDPP = false }) {
	if (!currentQuestion) return null;

	return (
		<div className="exam-questions">
			<div className="question-text">
				<div>{`Q${currentQuestion.serial_no}:`}</div>

				{parse(currentQuestion.question_bank_assoc?.question)}
			</div>

			{isDPP && currentQuestion?.question_bank_assoc?.tags?.length > 0 && (
				<div className="question-tag-wrapper">
					{currentQuestion.question_bank_assoc.tags.map((item, index) => {
						return (
							<span key={index} className="question-tag">
								{item.tag_name}
							</span>
						);
					})}
				</div>
			)}

			<div className="options">
				<div className={currentQuestion.ans_given === "1" ? "boxed2" : "boxed"} onClick={handleSelectOption("1")}>
					<label htmlFor="optionA">A. &nbsp; {parse(currentQuestion.question_bank_assoc?.option1, parserConfig)}</label>
				</div>
				<div className={currentQuestion.ans_given === "2" ? "boxed2" : "boxed"} onClick={handleSelectOption("2")}>
					<label htmlFor="optionB">B. &nbsp; {parse(currentQuestion.question_bank_assoc?.option2, parserConfig)}</label>
				</div>
				<div className={currentQuestion.ans_given === "3" ? "boxed2" : "boxed"} onClick={handleSelectOption("3")}>
					<label htmlFor="optionC">C. &nbsp; {parse(currentQuestion.question_bank_assoc?.option3, parserConfig)}</label>
				</div>
				<div className={currentQuestion.ans_given === "4" ? "boxed2" : "boxed"} onClick={handleSelectOption("4")}>
					<label htmlFor="optionD">D. &nbsp; {parse(currentQuestion.question_bank_assoc?.option4, parserConfig)}</label>
				</div>
			</div>
		</div>
	);
}

export default GenericV2QuestionSingleChoice;
