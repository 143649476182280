import React from "react";

const RightChevron = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "10px" }}
    >
      <path
        d="M18.164 8.00372C18.165 8.179 18.1314 8.35275 18.0652 8.51501C17.9989 8.67727 17.9012 8.82486 17.7778 8.9493L11.1188 15.6083C10.9946 15.7325 10.8472 15.831 10.6849 15.8982C10.5227 15.9654 10.3488 16 10.1732 16C9.81853 16 9.47839 15.8591 9.22761 15.6083C8.97683 15.3575 8.83594 15.0174 8.83594 14.6627C8.83594 14.3081 8.97683 13.9679 9.22761 13.7172L14.9544 8.00372L9.22761 2.29028C8.97683 2.03949 8.83594 1.69936 8.83594 1.3447C8.83594 0.990034 8.97683 0.649899 9.22761 0.399116C9.47839 0.148333 9.81853 0.00744247 10.1732 0.00744247C10.5279 0.00744247 10.868 0.148333 11.1188 0.399116L17.7778 7.05814C17.9012 7.18258 17.9989 7.33017 18.0652 7.49243C18.1314 7.6547 18.165 7.82845 18.164 8.00372Z"
        fill="#575757"
      />
      <path
        d="M10.1718 8.00372C10.1728 8.179 10.1393 8.35275 10.073 8.51501C10.0067 8.67727 9.90904 8.82486 9.78561 8.9493L3.12659 15.6083C2.8758 15.8591 2.53567 16 2.181 16C1.82634 16 1.48621 15.8591 1.23542 15.6083C0.984639 15.3575 0.84375 15.0174 0.84375 14.6627C0.84375 14.3081 0.984639 13.9679 1.23542 13.7172L6.96218 8.00372L1.23542 2.29028C0.984639 2.03949 0.84375 1.69936 0.84375 1.3447C0.84375 0.990034 0.984639 0.649899 1.23542 0.399116C1.48621 0.148333 1.82634 0.00744247 2.181 0.00744247C2.53567 0.00744247 2.8758 0.148333 3.12659 0.399116L9.78561 7.05814C9.90904 7.18258 10.0067 7.33017 10.073 7.49243C10.1393 7.6547 10.1728 7.82845 10.1718 8.00372Z"
        fill="#575757"
      />
    </svg>
  );
};

export default RightChevron;
