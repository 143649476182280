import { SvgIcon } from "@material-ui/core";
import React from "react";

const NeetNavIcon = ({ fill }) => {
	return (
		<SvgIcon
			style={{
				width: 21,
				height: 20,
				fill: "none",
			}}
			viewBox="0 0 21 20">
			<path
				d="M10.196 1.26808L13.446 6.89808C13.722 7.37508 13.558 7.98708 13.08 8.26308L11.78 9.01308L12.781 10.7451L11.049 11.7451L10.049 10.0121L8.75 10.7631C8.272 11.0391 7.66 10.8751 7.384 10.3971L5.546 7.21508C3.494 7.83708 2 9.74508 2 12.0001C2 12.6251 2.115 13.2241 2.324 13.7761C3.1 13.2841 4.016 13.0001 5 13.0001C6.684 13.0001 8.174 13.8331 9.08 15.1091L16.768 10.6701L17.768 12.4021L9.89 16.9511C9.962 17.2891 10 17.6411 10 18.0001C10 18.3431 9.966 18.6771 9.9 19.0001H18V21.0001L1 21.0011C0.372 20.1651 0 19.1261 0 18.0001C0 16.9931 0.298 16.0551 0.81 15.2701C0.293 14.2951 0 13.1821 0 12.0001C0 9.00508 1.881 6.44908 4.527 5.45008L4.134 4.76808C3.582 3.81108 3.909 2.58808 4.866 2.03608L7.464 0.536082C8.421 -0.0159177 9.644 0.311082 10.196 1.26808ZM5 15.0001C3.343 15.0001 2 16.3431 2 18.0001C2 18.3501 2.06 18.6871 2.17 19.0001H7.83C7.94 18.6871 8 18.3501 8 18.0001C8 16.3431 6.657 15.0001 5 15.0001ZM8.464 2.26808L5.866 3.76808L8.616 8.53108L11.214 7.03108L8.464 2.26808Z"
				fill={fill}
			/>
		</SvgIcon>
	);
};

export default NeetNavIcon;
