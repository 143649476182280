import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import { months, segmentEvents } from "../../../utils/constants";
import dateFormat from "dateformat";
import { secondsToDuration } from "../../../utils/helperFunctions";
import SolidButton from "../../../components/common/SolidButton";
import { MdOutlineInfo } from "react-icons/md";
import Api from "../../../common/Api";
import { checkProfileUnlocked } from "../../../common/checkProfileUnlocked";
import OneLineTooltip from "../../../components/common/OneLineTooltip";

const getList = (arr, idx) => {
	const arrClone = Array.isArray(arr) ? [...arr] : [];
	const lastPart = arrClone.splice(arrClone.length + 1 - idx, arrClone.length);
	return [arrClone, lastPart];
};

const TestCard = ({ index, exam, studentSubjects, studentData, token }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	const history = useHistory();
	const flag = exam.exam_status === 2 || exam.exam_status === 3;
	const startDate = new Date(exam.exam_start_on);
	const endDate = new Date(exam.exam_end_on);
	const chaptersRef = React.useRef(null);
	const [extraCatCount, setExtraCatCount] = React.useState(0);
	const [mainList, overflowList] = getList(Array.isArray(exam.chapters) ? exam.chapters : [], extraCatCount);

	React.useEffect(() => {
		const heightBreakpoint = 55;
		if (chaptersRef?.current?.offsetHeight > heightBreakpoint) {
			setExtraCatCount((num) => num + 1);
		}
	}, [extraCatCount, mainList]);

	const onstartclickDot = (item) => {
		if (navigator.sayswho === "Chrome 93" || "Safari 15") {
			Api.moEngageTrackEvent(segmentEvents.MOENGAGE.TEST_STARTED, {
				"Start date & time": Date(),
				"Exam Name": item.exam_name,
				"Maximum Marks": item.max_exam_marks,
				"Exam Window Start Date and Time": item.exam_start_on,
				"Exam Window End Date and Time": item.exam_end_on,
			});
			history.push({
				pathname: `/student/instruction/${item.id}`,
				state: {
					studentId: studentData.id,
					userId: studentData.user_assoc,
					token,
					ExamScheduleId: item.id,
				},
			});
		} else {
			alert("You are not using Chrome Or Safari browser.Please use chrome browser in android and safari in chrome");
		}
	};

	const onStartExam = (item) => {
		// window.open(`https://admin.zinedu.com/WEBStudent/StudentExamQuestion?ExamScheduleId=${item.id}&StudentId=${studentdData.id}`)
		if (navigator.sayswho === "Chrome 93" || "Safari 15") {
			Api.moEngageTrackEvent(segmentEvents.MOENGAGE.TEST_STARTED, {
				"Start date & time": Date(),
				"Exam Name": item.exam_name,
				"Maximum Marks": item.max_exam_marks,
				"Exam Window Start Date and Time": item.exam_start_on,
				"Exam Window End Date and Time": item.exam_end_on,
			});
			history.push({
				pathname: "/student/InstructionPage",
				state: {
					studentId: studentData.id,
					userId: studentData.user_assoc,
					token,
					ExamScheduleId: item.id,
					studentName: studentData.name,
				},
			});
		} else {
			alert("You are not using Chrome Or Safari browser.Please use chrome browser in android and safari in chrome");
		}
	};

	const onStartGeneric = (exam) => {
		Api.moEngageTrackEvent(segmentEvents.MOENGAGE.TEST_STARTED, {
			"Start date & time": Date(),
			"Exam Name": exam.exam_name,
			"Maximum Marks": exam.max_exam_marks,
			"Exam Window Start Date and Time": exam.exam_start_on,
			"Exam Window End Date and Time": exam.exam_end_on,
		});

		history.push({
			pathname: `/student/generic/${exam.id}/instructions`,
			state: {
				studentId: studentData.id,
				userId: studentData.user_assoc,
				token,
				ExamScheduleId: exam.id,
				studentName: studentData.name,
			},
		});
	};

	const onResultClickDot = (item) => {
		history.push({
			pathname: `/student/result/${item.id}`,
			state: { exam_name: item.exam_name, attempted_on: item.exam_start_on },
		});
		//TODO: Segment
		Api.moEngageTrackEvent("Web-Test Result Viewed", {
			"Start date & time": Date(),
			"Exam Name": item.exam_name,
			"Maximum Marks": item.max_exam_marks,
			"Exam Window Start Date and Time": item.exam_start_on,
			"Exam Window End Date and Time": item.exam_end_on,
		});
	};

	const downloadQuestionpaperdot = (id) => {
		Api.moEngageTrackEvent(segmentEvents.STUDENT.DOWNLOAD_QUESTION_PAPER);
		checkProfileUnlocked(() => {
			window.open(
				`${process.env.REACT_APP_DOT_URL}WEBStudent/DownloadQuestionPaper?ExamScheduleId=${id}&StudentId=${studentData.id}`,
				"_blank"
			);
		});
	};

	return (
		<div
			style={{
				paddingLeft: isMobile ? 0 : "2rem",
				paddingRight: !isMobile ? 0 : "2rem",
				scrollSnapAlign: "start",
			}}
			key={index}>
			<div className="dashboard_test_card">
				<div>
					{flag && (
						<div className="test_card_status_wrapper">
							<div
								className="test_card_status"
								style={{
									"--test_card_status": "#F5EADB",
								}}>
								<p
									className="test_card_status_text"
									style={{
										"--test_card_status_text": "#454545",
									}}>
									Active
								</p>
							</div>
						</div>
					)}
					<OneLineTooltip title={exam.exam_name}>
						<p
							className="test_card_header"
							style={{
								"--test_card_header_margin": flag ? "-0.5rem" : "1.5rem",
							}}>
							{exam.exam_name}
						</p>
					</OneLineTooltip>
					<p className="test_card_syllabus_text">Syllabus</p>

					<div className="test_card_subject_wrapper">
						{exam.subjects?.map((x, i) => {
							const subject = studentSubjects.find((sub) => sub.id === x.id);
							return (
								<p
									className="test_card_subject"
									style={{
										"--test_card_subject_bg": subject?.subject_assoc?.primary_color ?? "#E0FAFF",
									}}
									key={i}>
									{x.name}
								</p>
							);
						})}
					</div>

					<div ref={chaptersRef} className="test_card_chapter_wrapper">
						{mainList.map(({ name }, i) => {
							return (
								<div key={`tag-${i}`} className="d-flex align-items-center">
									{i !== 0 && <div className="test_chapter_seperator" />}
									<span className="test_card_chapters">{name}</span>
								</div>
							);
						})}
						{overflowList.length > 0 && (
							<div className="d-flex align-items-center">
								<div className="test_chapter_seperator" />
								<Tooltip title={overflowList.map((e) => e.name).join(", ")} placement="bottom">
									<span className="test_chapters_more">+{overflowList.length}</span>
								</Tooltip>
							</div>
						)}
					</div>

					<p className="test_card_test_window_text" style={{ marginTop: "1rem" }}>
						Test window
					</p>

					<div className="test_window_time_wrapper">
						<div className="test_window_time_container">
							<div>
								<p className="test_window_text1">
									{months[startDate.getMonth()]} {startDate.getDate()}
								</p>
								<p className="test_window_text2">{dateFormat(startDate, "hh:MM TT")}</p>
							</div>

							<div>-</div>

							<div>
								<p className="test_window_text1">
									{months[endDate.getMonth()]} {endDate.getDate()}
								</p>
								<p className="test_window_text2">{dateFormat(endDate, "hh:MM TT")}</p>
							</div>
						</div>

						<div className="test_window_duration_container">
							<p className="test_window_text1">Duration</p>
							<p className="test_window_text2">{secondsToDuration(exam.exam_duration * 60)}</p>
						</div>
					</div>
				</div>
				<CTA
					exam={exam}
					onstartclickDot={onstartclickDot}
					onStartExam={onStartExam}
					onResultClickDot={onResultClickDot}
					onStartGeneric={onStartGeneric}
					downloadQuestionpaperdot={downloadQuestionpaperdot}
				/>
			</div>
		</div>
	);
};

export default TestCard;

const CTA = (props) => {
	const { exam } = props;
	const { exam_status: examStatus, exam_theme_assoc_id: examTheme } = exam;
	if (examStatus === 1) {
		return (
			<div className="text-center">
				<SolidButton small disabled>
					Coming soon
				</SolidButton>
			</div>
		);
	} else if (examStatus === 2) {
		return (
			<div className="text-center">
				<SolidButton
					small
					onClick={() =>{
						(examTheme === 1
							? props.onstartclickDot(exam)
							: examTheme === 3
							? props.onStartGeneric(exam)
							: props.onStartExam(exam));
						Api.trackEvent(segmentEvents.DASHBOARD.TEST_START);
					}
					}>
					Start
				</SolidButton>
			</div>
		);
	} else if (examStatus === 3) {
		return (
			<div className="text-center">
				<SolidButton
					small
					onClick={() => {
						(examTheme === 1
							? props.onstartclickDot(exam)
							: examTheme === 3
							? props.onStartGeneric(exam)
							: props.onStartExam(exam));
						Api.trackEvent(segmentEvents.DASHBOARD.TEST_CONTINUE);
					}
					}>
					Continue
				</SolidButton>
			</div>
		);
	} else if (examStatus === 4) {
		return (
			<div className="text-center">
				<button 
					className="dpp_result_button" 
					onClick={() => {
						props.onResultClickDot(exam);
						Api.trackEvent(segmentEvents.DASHBOARD.TEST_RESULT);
					}}
				>
					Result
				</button>
			</div>
		);
	} else if (examStatus === 5) {
		return (
			<div className="text-center">
				<SolidButton small disabled>
					Result coming soon
				</SolidButton>
			</div>
		);
	} else if (examStatus === 7) {
		return (
			<div className="text-center">
				<div style={{ display: "flex", alignItems: "center", marginBottom: 14, marginTop: 14 }}>
					<div>
						<MdOutlineInfo fontSize={18} />
					</div>
					<div style={{ fontSize: 12, textAlign: "left", paddingLeft: 8 }}>
						Rank is not available because you are taking it outside the test window
					</div>
				</div>

				<SolidButton
					style={{ marginTop: 0 }}
					small
					onClick={() => {
						(examTheme === 1
							? props.onstartclickDot(exam)
							: examTheme === 3
							? props.onStartGeneric(exam)
							: props.onStartExam(exam));
						Api.trackEvent(segmentEvents.DASHBOARD.TEST_PRACTICE);
					}}>
					Practice
				</SolidButton>
			</div>
		);
	} else
		return (
			<div className="text-center">
				<SolidButton small 
					onClick={() => {
						props.downloadQuestionpaperdot(exam.id);
						Api.trackEvent(segmentEvents.DASHBOARD.TEST_QUESTIONPAPER);
					}}>
					Download Question Paper
				</SolidButton>
			</div>
		);
};
