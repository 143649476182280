import React, { useMemo } from "react";
import { Modal } from "antd";
import parse from "html-react-parser";
import "../styles/exam-screen.css";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";

function GenericV2SectionInstructionsModal({ show, setShow, onlyButton = false, sections = [], currentSectionId = null }) {
    const handleOpen = (e) => {
        Api.trackEvent(segmentEvents.GENERIC.SECTIONINSTR_CLICK);
        e.preventDefault();
        setShow(true);
    };

    const handleClose = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        setShow(false);
    };

    const sectionInstructions = useMemo(() => {
        if (currentSectionId && sections && sections.length > 0) {
            const currentSection = sections.find((section) => section.id === currentSectionId);

            if (currentSection) {
                return currentSection.section_instructions;
            }
        }

        return "";
    }, [sections, currentSectionId]);

    return (
        <>
            <a href="#section-instructions-modal" onClick={handleOpen} className="marking-scheme">
                Section Instructions
            </a>

            {!onlyButton && (
                <Modal width={1000} title="Section Instructions" visible={show} footer={null} onCancel={handleClose}>
                    {sectionInstructions && parse(sectionInstructions)}

                    <div className="text-center" style={{ marginTop: 10 }}>
                        <a href="#section-instructions-close" type="button" className="close-modal-btn" onClick={handleClose}>
                            Close
                        </a>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default GenericV2SectionInstructionsModal;
