import React from "react";
import AttendanceMark from "../../../assets/img/attendance_mark.svg";
import SolidButton from "../SolidButton";
import { AttendanceDummyData, SubjectDummyData } from "../../../Student/dashboard/dummyData";
import lockedImg from "../../../assets/img/lockedImg.svg";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";
import Styles from "./attendance.module.css";

const Attendance = ({ attendance, studentSubjects, isFree, requestACall, hideTitle }) => {
	const data = isFree ? AttendanceDummyData : attendance;
	const subjectsList = isFree ? SubjectDummyData : studentSubjects;

	return (
		<div>
			{!hideTitle && <p className={Styles.attendance_header_text}>Attendance</p>}
			<div className={Styles.attendance_mark_wrapper}>
				<p className={Styles.attendance_mark_text1}>{data.attendance_count}</p>

				<div className={Styles.attendance_divider} />

				<p className={Styles.attendance_mark_text2}>{data.total_classes}</p>
				<img src={AttendanceMark} alt="mark" className={Styles.attendance_mark_image} />
			</div>
			{data.Subjects.map((subject, i) => {
				const bgColor = subjectsList.find((obj) => obj.subject_assoc.subject_name === subject.subject_name)?.subject_assoc
					?.primary_color;

				return (
					<div
						className={Styles.dashboard_attendance_card}
						style={{
							"--attendance-card": bgColor ? bgColor : "#E0FAFF",
						}}
						key={i}>
						<p className={Styles.dashboard_attendance_text1}>{subject.subject_name}</p>

						<p className={Styles.dashboard_attendance_text2}>
							<span className={Styles.dashboard_attendance_text3}>{subject.subject_attendance}</span> out of{" "}
							{subject.subject_total_attendance}
						</p>
					</div>
				);
			})}
			{isFree && (
				<div className={Styles.dashboard_blur}>
					<div>
						<img src={lockedImg} alt="" />
						<br />
						Connect with us to unlock attendance.
						<br />
						<SolidButton
							small
							style={{
								marginTop: "1rem",
								width: 150,
							}}
							onClick={() => {
								Api.trackEvent(segmentEvents.FREEDASHBOARD.REQUESTCALL_ATTENDANCE);
								requestACall("Attendance");
							}}>
							Request a call
						</SolidButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default Attendance;
