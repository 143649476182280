export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE";
export const STUDENT_DETAIL_SUCCESS = "STUDENT_DETAIL_SUCCESS";
export const STUDENT_DETAIL_FAILURE = "STUDENT_DETAIL_FAILURE";
export const CHAPTER_SUCCESS = "CHAPTER_SUCCESS";
export const CHAPTER_FAILURE = "CHAPTER_FAILURE";
export const NOTE_SUCCESS = "NOTE_SUCCESS";
export const NOTE_FAILURE = "NOTE_FAILURE";
export const EMPTY_STUDENT_DETAIL = "EMPTY_STUDENT_DETAIL";
export const JEE_ADVANCE_EXAM_DETAIL_SUCCESS = "JEE_ADVANCE_EXAM_DETAIL_SUCCESS";
export const JEE_ADVANCE_EXAM_DETAIL_FAILURE = "JEE_ADVANCE_EXAM_DETAIL_FAILURE";
export const EMPTY_JEE_ADVANCE_EXAM_DETAIL = "EMPTY_JEE_ADVANCE_EXAM_DETAIL";
export const JEE_ADVANCE_EXAM_QUESTION_SUCCESS = "JEE_ADVANCE_EXAM_QUESTION_SUCCESS";
export const JEE_ADVANCE_EXAM_QUESTION_FAILURE = "JEE_ADVANCE_EXAM_QUESTION_FAILURE";
export const EMPTY_JEE_ADVANCE_EXAM_QUESTION = "EMPTY_JEE_ADVANCE_EXAM_QUESTION";
export const JEE_ADVANCE_EXAM_QUESTION_SUBMIT = "JEE_ADVANCE_EXAM_QUESTION_SUBMIT";
export const JEE_ADVANCE_UPDATE_QUESTIONS = "JEE_ADVANCE_UPDATE_QUESTIONS";
export const JEE_ADVANCE_START_LOADER = "JEE_ADVANCE_START_LOADER";
export const AUTH_PROFILE_LOCK = "AUTH_PROFILE_LOCK";
export const HANDLE_SIGN_UP = "HANDLE_SIGN_UP";
export const RESET_SIGN_UP = "RESET_SIGN_UP";
export const SET_DASHBOARD_STATE = "SET_DASHBOARD_STATE";
export const SET_DASHBOARD_SELECTED_CLASS = "SET_DASHBOARD_SELECTED_CLASS";
export const SET_DASHBOARD_SELECTED_SUBJECT = "SET_DASHBOARD_SELECTED_SUBJECT";
export const SET_CORNER_STATE = "SET_CORNER_STATE";
export const RESET_CORNER_STATE = "RESET_CORNER_STATE";
