import React from 'react'
import NoDataImg from "../../assets/img/dashboard_no_data_found.svg";


const NoContentAvailable = ({ message, subMessage }) => {
	return (
		<div style={{ marginTop: 20 }}>
			<center>
				<img src={NoDataImg} alt="No Test" />
				<div className="check_later_text1">{message}</div>
				<div className="check_later_text2">{subMessage}</div>
			</center>
		</div>
	);
};

export default NoContentAvailable