export const initialState = {
    currentStudentId: null,
    currentExamId: null,
    currentSection: null,
    currentQuestion: null,
    loaded: false,
    serverTimeOffset: 0,
};

export const GENERIC_OFFLINE_SET_MISC_DETAILS = "GENERIC_OFFLINE_SET_MISC_DETAILS";
export const GENERIC_OFFLINE_SET_MISC_ACTIVITY = "GENERIC_OFFLINE_SET_MISC_ACTIVITY";
export const GENERIC_OFFLINE_SET_SERVER_TIME_OFFSET = "GENERIC_OFFLINE_SET_SERVER_TIME_OFFSET";

export default function miscDataReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GENERIC_OFFLINE_SET_MISC_ACTIVITY: {
            if (payload) {
                const { section, question } = payload;

                return {
                    ...state,
                    currentSection: section,
                    currentQuestion: question,
                };
            }
            return state;
        }
        case GENERIC_OFFLINE_SET_MISC_DETAILS: {
            if (payload) {
                const { studentId, examId, loaded } = payload;

                return {
                    ...state,
                    currentStudentId: studentId,
                    currentExamId: examId,
                    currentSection: null,
                    currentQuestion: null,
                    loaded,
                };
            }
            
            return state;
        }

        case GENERIC_OFFLINE_SET_SERVER_TIME_OFFSET: {
            if (payload) {
                const { serverTimeOffset } = payload;

                return {
                    ...state,
                    serverTimeOffset,
                }
            }

            return state;
        };

        default:
            return state;
    }
}
