import { SvgIcon } from "@material-ui/core";
import React from "react";

const TestNavIcon = ({ fill }) => {
	return (
		<SvgIcon
			style={{
				width: 21,
				height: 20,
				fill: "none",
			}}
			viewBox="0 0 21 20">
			<path
				d="M14 0V2H17.007C17.555 2 18 2.445 18 2.993V19.007C18 19.555 17.555 20 17.007 20H0.993C0.445 20 0 19.555 0 19.007V2.993C0 2.445 0.445 2 0.993 2H4V0H14ZM4 4H2V18H16V4H14V6H4V4ZM6 14V16H4V14H6ZM6 11V13H4V11H6ZM6 8V10H4V8H6ZM12 2H6V4H12V2Z"
				fill={fill}
			/>
		</SvgIcon>
	);
};

export default TestNavIcon;
