import { STUDENT_DETAIL_SUCCESS, STUDENT_DETAIL_FAILURE, EMPTY_STUDENT_DETAIL } from "../actionType";
import ApiUrl from "../../common/ApiUrl";
import Api from "../../common/Api";

// export const getStudentDetails = (student) => async (dispatch, getState) => {
// 	const student_data = getState().StudentDetail.StudentDetail;
// 	if (Object.keys(student_data).length === 0) {
// 		try {
// 			const response = await Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_DETAILS2 + `?username=${student}`);
// 			if (response.status === true) {
// 				const { data } = response;
// 				const response2 = await Api.doFetch(
// 					"GET",
// 					{},
// 					ApiUrl.LIST_OF_SUBJECT_STUDENT + `?student_id=${data.student_data.id}`
// 				);
// 				if (response2.status === true) {
// 					const data2 = response2.data;
// 					dispatch({
// 						type: STUDENT_DETAIL_SUCCESS,
// 						payload: {
// 							...data,
// 							student_subjects: data2.student_subjects,
// 						},
// 					});
// 				}
// 			} else {
// 				// Stop alert
// 				alert(response.message);
// 			}
// 		} catch (error) {
// 			dispatch({
// 				type: STUDENT_DETAIL_FAILURE,
// 				payload: error,
// 			});
// 		}
// 	}
// };

export const getStudentDetails = (student) => async (dispatch, getState) => {
	const student_data = getState().StudentDetail.StudentDetail;

	if (Object.keys(student_data).length === 0) {
		try {
			const studentDetailsResponse = await Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_DETAILS2 + `?username=${student}`);
			if (studentDetailsResponse && studentDetailsResponse.status) {
				const studentDetails = studentDetailsResponse.data;

				Api.identifyUser(studentDetails);

				const listOfSubjectsResponse = await Api.doFetch(
					"GET",
					{},
					ApiUrl.LIST_OF_SUBJECT_STUDENT + `?student_id=${studentDetails.student_data.id}`
				);
				if (listOfSubjectsResponse && listOfSubjectsResponse.status === true) {
					dispatch({
						type: STUDENT_DETAIL_SUCCESS,
						payload: {
							...studentDetails,
							student_subjects: listOfSubjectsResponse.data.student_subjects,
						},
					});
				}
			}
		} catch (error) {
			dispatch({
				type: STUDENT_DETAIL_FAILURE,
				payload: error,
			});
		}
	}
};

export const clearStudentDetail = () => async (dispatch) => {
	dispatch({
		type: EMPTY_STUDENT_DETAIL,
		payload: {},
	});
};
