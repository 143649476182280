import React from "react";
import "./styles.css";
import QuestionNotAnswered from "../QuestionStatus/QuestionNotAnswered";
import QuestionNotVisited from "../QuestionStatus/QuestionNotVisited";
import QuestionReviewNotAnswered from "../QuestionStatus/QuestionReviewNotAnswered";
import QuestionReviewAnswered from "../QuestionStatus/QuestionReviewAnswered";
import QuestionAnswered from "../QuestionStatus/QuestionAnswered";

const QuestionNumbers = ({
  quesStatusList,
  currentQuestion,
  onChangeQuestion,
}) => {
  const props = {
    width: "34px",
    height: "34px",
    customCss: { margin: "5px" },
  };
  return (
    <div className="question_number">
      {quesStatusList.map((item, i) => (
        <div
          key={i}
          onClick={() => {
            onChangeQuestion(item);
          }}
        >
          {item.color_id === "0" ? (
            <QuestionNotVisited {...props}>{item.serial_no}</QuestionNotVisited>
          ) : item.color_id === "4" ? (
            <QuestionNotAnswered {...props}>
              {item.serial_no}
            </QuestionNotAnswered>
          ) : item.color_id === "3" ? (
            <QuestionReviewAnswered {...props}>
              {item.serial_no}
            </QuestionReviewAnswered>
          ) : item.color_id === "2" ? (
            <QuestionReviewNotAnswered {...props}>
              {item.serial_no}
            </QuestionReviewNotAnswered>
          ) : item.color_id === "1" ? (
            <QuestionAnswered {...props}>{item.serial_no}</QuestionAnswered>
          ) : (
            item.serial_no
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionNumbers;
