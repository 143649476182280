import React from "react";
import CommonCard from "../../components/corner/CommonCard";
import { useHistory } from "react-router-dom";
import { cornerType, dashboardIds } from "../../utils/constants";

const JEECorner = ({ active }) => {
	const history = useHistory();

	return (
		<CommonCard
			title="JEE Corner"
			active={active}
			navigate={(state) => {
				history.push({ pathname: "/student/jee", state });
			}}
			id={dashboardIds.JEE}
			type={cornerType.JEE}
		/>
	);
};

export default React.memo(JEECorner);
