import { toast } from "react-toastify";
import Api from "../common/Api";
import ApiUrl from "../common/ApiUrl";

export const numberWithCommas = (input) => {
	try {
		if (typeof input !== "number") {
			if (typeof input === "string" && input.trim()) {
				input = Number(input);
				if (isNaN(input)) {
					return input;
				}
			} else {
				return input;
			}
		}

		return input.toLocaleString("en-IN");
	} catch (err) {
		return input;
	}
};

export const secondsToDuration = (time) => {
	//time - total seconds
	var sec_num = parseInt(time, 10);
	var hours = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - hours * 3600) / 60);
	var seconds = sec_num - hours * 3600 - minutes * 60;

	if (hours < 10) {
		hours = "0" + hours;
	}
	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}
	return `${hours > 0 ? hours + "h " : ""}${minutes > 0 ? minutes + "m " : ""}${seconds > 0 ? seconds + "s " : ""}`;
};

export const requestACall = (type) => {
	Api.doFetch("POST", { request_type: type }, ApiUrl.REQUEST_CALL).then((res) => {
		if (res.status)
			toast.success("Request sent! We will call you shortly.", {
				position: "top-center",
				autoClose: 2000,
			});
		else
			toast.error("Something went wrong, please try again.", {
				position: "top-center",
				autoClose: 2000,
			});
	});
};

export const getVimeoUrl = (video) => {
	if (!video) {
		return { isVimeo: false, videoUrl: "" };
	}
	if (!video.includes("vimeo")) {
		return {
			isVimeo: false,
			videoUrl: video,
		};
	}
	if (video.includes(".mp4")) {
		return {
			isVimeo: false,
			videoUrl: video,
		};
	}
	return { isVimeo: true, videoUrl: video };
};
