import React from "react";
import Timer from "../../../../components/common/Timer";
import { segmentEvents } from "../../../../utils/constants";
import Api from "../../../../common/Api";
import ProfileIcon from "../../icons/ProfileIcon";
import "./styles.css";

const JeemainDetailHeader = (props) => {
	const onTimerEnd = React.useCallback(() => {
		props.onTimeEnd();
	}, [props.onTimeEnd]);

	return (
		<div>
			<div className="Detail-con">
				<div className="container">
					<div className="detail-con-inner" style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
						<div className="detail-con-inner" style={{ justifyContent: "center", alignItems: "center" }}>
							<ProfileIcon />
							<div className="detail-exam-con">
								<div>
									<div>Candidate Name</div>
									<div>Exam Name</div>
									<div>Subject Name</div>
									<div>Remaining Time</div>
								</div>
								<div>
									<div style={{ marginRight: "5px", marginLeft: "5px" }}>:</div>
									<div style={{ marginRight: "5px", marginLeft: "5px" }}>:</div>
									<div style={{ marginRight: "5px", marginLeft: "5px" }}>:</div>
									<div style={{ marginRight: "5px", marginLeft: "5px" }}>:</div>
								</div>
								<div style={{ color: "#EC7B27" }}>
									<div>{props.student_name}</div>
									<div>{props.theme === 2 ? `JEE Mains` : `NEET`}</div>
									<div>{props.exam_name}</div>
									<div className="rem-time">
										<Timer examTimeLeft={props.timeLeft} onTimerEnd={onTimerEnd} />
									</div>
								</div>
							</div>
						</div>
						<div>
							<div style={{ textAlign: "center" }}>
								<p>Choose Your Default Language</p>
								<select
									style={{ width: "80%" }}
									onClick={() => Api.trackEvent(segmentEvents.MAINS.LANGUAGE_CLICK)}>
									<option>English</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default JeemainDetailHeader;
