import React from "react";
import SolidButton from "../../components/common/SolidButton";
import { useHistory } from "react-router-dom";
import Api from "../../common/Api";
import { dashboardIds, segmentEvents } from "../../utils/constants";
import { useMediaQuery } from "@material-ui/core";
import NoDataImg from "../../assets/img/dashboard_no_data_found.svg";
import { TestDummyData } from "./dummyData";
import lockedImg from "../../assets/img/lockedImg.svg";
import TestCard from "./components/test-card";
import HorizontalScroll from "../../components/common/HorizontalScroll";

const Test = ({ exams, studentData, token, active, isFree, studentSubjects, requestACall }) => {
	const history = useHistory();
	const isMobile = useMediaQuery("(max-width: 991px)");

	const viewAll = () => {
		Api.moEngageTrackEvent(segmentEvents.STUDENT.TEST_VIEW);
		Api.trackEvent(segmentEvents.DASHBOARD.TEST_VIEWALL);
		history.push({
			pathname: "/student/objectivetest",
			state: { exam_type: 1 },
		});
	};

	return (
		<div id={dashboardIds.TESTS} className="dashboard_test_container">
			<div className="dashboard_content_header_container">
				{active && <div className="dashboard_content_active" />}
				<p className="dashboard_content_header">Test</p>

				<button className="dashboard_content_view_all" onClick={viewAll}>
					See all
				</button>
			</div>
			<div style={{ position: "relative" }}>
				<HorizontalScroll>
					{(isFree ? TestDummyData : exams).map((exam, i) => {
						return (
							<TestCard
								key={i}
								index={i}
								exam={exam}
								studentSubjects={studentSubjects}
								studentData={studentData}
								token={token}
							/>
						);
					})}
					{!isFree && exams.length > 0 && (
						<div
							onClick={viewAll}
							className="dashboard_view_all_card"
							style={{
								marginLeft: isMobile ? 0 : "2rem",
								marginRight: !isMobile ? 0 : "2rem",
							}}>
							See all
						</div>
					)}
				</HorizontalScroll>
				{!isFree && exams.length === 0 && (
					<div style={{ textAlign: "center", padding: "32px 0" }}>
						<img src={NoDataImg} alt="No Test" />
						<div className="check_later_text1">No Tests available</div>
						<div className="check_later_text2">Check back later</div>
					</div>
				)}

				{isFree && (
					<div className="dashboard_blur">
						<div>
							<img src={lockedImg} alt="" />
							<br />
							Connect with us to unlock tests.
							<br />
							<SolidButton
								small
								style={{
									marginTop: "1rem",
									width: 165,
								}}
								onClick={() => {
									Api.trackEvent(segmentEvents.FREEDASHBOARD.REQUESTCALL_TEST);
									requestACall("Test");
								}}>
								Request a call
							</SolidButton>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(Test);
