import React from "react";
import CommonCard from "../../components/corner/CommonCard";
import { useHistory } from "react-router-dom";
import { cornerType, dashboardIds } from "../../utils/constants";

const NCERT = ({ active }) => {
	const history = useHistory();

	return (
		<CommonCard
			title={"NCERT Corner"}
			active={active}
			navigate={(state) => {
				history.push({ pathname: "/student/ncert", state });
			}}
			id={dashboardIds.NCERT}
			type={cornerType.NCERT}
		/>
	);
};

export default React.memo(NCERT);
