import React, { useState, useCallback, useEffect } from "react";
import { Input } from "antd";
import phoneImg from "../../images/helpAndSupport/Help.png";
import addImg from "../../images/helpAndSupport/Vector.png";
import crossImg from "../../images/helpAndSupport/VectorClose.png";
import uploadImg from "../../images/helpAndSupport/Upload.png";
import tickImg from "../../images/helpAndSupport/Tick.png";
import { useDropzone } from "react-dropzone";
import { Tag, Tooltip } from "antd";
import { ToastContainer, toast } from "react-toastify";

import "./styles/common.css";
import "react-toastify/dist/ReactToastify.css";
// import ToastMessage from "../common/toastMessage";
import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import Spinner from "../../components/spinner/Spinner";
import Layout from "../../components/layout";
import { segmentEvents } from "../../utils/constants";

const { TextArea } = Input;

const issuesList = ["Live Class", "Tests", "Assignments", "Solutions", "Others"];

const ToastMessage = () => (
	<div>
		<img
			src={tickImg}
			alt={"success"}
			className="mr-2"
			style={{ margin: "2px", marginRight: "8px" }}
			height="20px"
			width="20px"
		/>
		Issue recorded successfully
	</div>
);

const HelpAndSupport = (props) => {
	const [issue, setIssue] = useState([]);
	const [issueText, setIssueText] = useState("");
	const [screenShots, setScreenShots] = useState([]);
	const [submitLoading, setSubmitLoading] = useState(false);

	const [studentDetails, setStudentDetails] = useState("");

	const postFeedback = async () => {
		try {
			setSubmitLoading(true);
			let formData = new FormData();

			formData.append("issue_type", issue);
			formData.append("description", issueText);

			screenShots.forEach((item, index) => {
				formData.append("images", item);
			});

			const response = await Api.doUpload(`POST`, formData, `${ApiUrl.POST_FEEDBACK}${studentDetails.id}/`);

			if (response && response.status === true) {
				toast.success(<ToastMessage />, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setIssue([]);
				setIssueText("");
				setScreenShots([]);
			} else {
				toast.error("Issue could not be recorded", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		} catch (err) {
			console.log(err);
		}

		setSubmitLoading(false);
	};

	const getStudentDetails = async () => {
		try {
			let data = localStorage.getItem("userdetail");
			data = data ? JSON.parse(data) : null;
			if (!data || !data.username) return;

			const response = await Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_DETAILS + "?username=" + data.username);

			if (response && response.status === true) {
				setStudentDetails(response.data.student_data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getStudentDetails();
	}, []);

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		// Do something with the files
		console.log({ rejectedFiles });

		if (acceptedFiles.length !== 0) {
			setScreenShots((curState) => {
				if (curState.length + acceptedFiles.length > 10) {
					toast.error("You cannot upload more than 10 files.", {
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					return curState;
				}
				return [...curState, ...acceptedFiles];
			});

			return;
		}

		if (rejectedFiles.length > 0) {
			for (const file of rejectedFiles) {
				if (file.errors && file.errors.length > 0) {
					for (const error of file.errors) {
						if (error.code === "file-invalid-type") {
							toast.error("Please upload image files.", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});

							return;
						}

						if (error.code === "too-many-files") {
							toast.error("You cannot upload more than 10 files.", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});

							return;
						}
					}
				}
			}
		}
	}, []);

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		noClick: true,
		accept: {
			"image/jpeg": [],
			"image/png": [],
		},
		maxFiles: 10,
	});

	const deleteImage = (index) => {
		let x = screenShots.filter((item, indx) => index !== indx);
		setScreenShots(x);
	};

	const openImageInNewTab = (index) => {
		const blobUrl = URL.createObjectURL(screenShots[index]);
		window.open(blobUrl, "_blank");
	};

	const onIssueSelectHandler = (issue) => {
		setIssue((curState) => {
			if (curState.includes(issue)) {
				let filterArr = curState.filter((item) => item !== issue);
				return filterArr;
			} else {
				return [...curState, issue];
			}
		});
	};

	return (
		<Layout>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<div className="help_support_container">
				<div className="help_support_info_container">
					<p className="header_text_help_support">Hi, how can we help you?</p>

					<p className="help_support_text1">
						Where are you facing an issue?<span style={{ color: "red" }}>*</span>
					</p>

					<div className="help_support_options_row">
						{issuesList.map((item, index) => {
							return (
								<p
									key={`issue-${index}`}
									onClick={() => onIssueSelectHandler(item)}
									className={issue.includes(item) ? "options_button_active" : "options_button"}>
									{item}
								</p>
							);
						})}
						{/* <p
                     onClick={()=>setIssue("Live Class")}
                     className={issue=="Live Class"?"options_button_active":"options_button"} >Live Class</p>
                    <p
                     onClick={()=>setIssue("Tests")}
                     className={issue=="Tests"?"options_button_active":"options_button"} >Tests</p>
                    <p
                     onClick={()=>setIssue("Assignments")}
                     className={issue=="Assignments"?"options_button_active":"options_button"} >Assignments</p>
                    <p
                     onClick={()=>setIssue("Solutions")}
                     className={issue=="Solutions"?"options_button_active":"options_button"} >Solutions</p>
                    <p
                     onClick={()=>setIssue("Others")}
                     className={issue=="Others"?"options_button_active":"options_button"} >Others</p> */}
					</div>

					<div className="info_col">
						<p className="help_support_text1">
							Please describe your issue so that, we can provide you a quick resolution.
						</p>

						<div className="text_area_div">
							<TextArea
								value={issueText}
								onChange={(e) => setIssueText(e.target.value)}
								placeholder="Enter here..."
								autoSize={{ minRows: 4, maxRows: 4 }}
								bordered={false}
								size="large"
							/>
							<div className="add_img_button_container" {...getRootProps()}>
								<input {...getInputProps()} />
								<Tooltip title="Upload screenshot">
									<img
										src={addImg}
										width="18px"
										alt="add"
										height="19px"
										onClick={open}
										className="add_img_button"
									/>
								</Tooltip>
							</div>
						</div>

						<div className="tags_container">
							{screenShots.map((_, index) => {
								return (
									<Tag
										onClick={() => openImageInNewTab(index)}
										key={`tag${index}`}
										icon={<img src={uploadImg} alt="upload" width="18px" height="20px" />}
										className="img_tag"
										closable
										onClose={() => deleteImage(index)}
										closeIcon={
											<Tooltip title="Delete screenshot">
												<img
													src={crossImg}
													alt="close"
													width="15px"
													height="15px"
													className="cross_img"
												/>
											</Tooltip>
										}>
										Screenshot {index + 1}
									</Tag>
								);
							})}
						</div>

						<div className="submit_button_container">
							<button
								className="submit_button_active"
								disabled={submitLoading || issue.length === 0}
								onClick={() => {
									Api.trackEvent(segmentEvents.HELP.SUBMIT_CLICK);
									postFeedback();
								}}
							>
								Submit
								{submitLoading && <Spinner />}
							</button>
						</div>
					</div>
				</div>

				<div className="help_support_img_container">
					<img src={phoneImg} alt="phone" width="325px" height="275px" />
				</div>
			</div>
		</Layout>
	);
};

export default HelpAndSupport;
