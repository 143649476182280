import {
  JEE_ADVANCE_EXAM_DETAIL_SUCCESS,
  JEE_ADVANCE_EXAM_DETAIL_FAILURE,
} from "../actionType";

export const initialState = {
  examDetail: {
    // id: '',
    // exam_name: "",
    // exam_instructions: "",
    // exam_start_on: '',
    // exam_end_on: '',
    // exam_result_on: '',
    // exam_duration: '',
    // max_exam_marks: '',
    // max_exam_questions: '',
    // exam_type_assoc: '',
    // monitoring_type: '',
    // timer_status: '',
    // is_disabled: false,
    // exam_subjects:[]
  },
  loading: true,
  error: null,
  success: false,
};

export default function jeeAdvanceExamDetailReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  //console.log("payload",payload);
  switch (type) {
    case JEE_ADVANCE_EXAM_DETAIL_SUCCESS:
      return {
        //...state,
        examDetail: payload,
        //   examDetail: {
        //     ...state.examDetail,
        //     id: payload.id,
        //     exam_name: payload.exam_name,
        //     exam_instructions: payload.exam_instructions,
        //     exam_start_on: payload.exam_start_on,
        //     exam_end_on: payload.exam_end_on,
        //     exam_result_on: payload.exam_result_on,
        //     exam_duration: payload.exam_duration,
        //     max_exam_marks: payload.max_exam_marks,
        //     max_exam_questions: payload.max_exam_questions,
        //     exam_type_assoc: payload.exam_type_assoc,
        //     monitoring_type: payload.monitoring_type,
        //     timer_status: payload.timer_status,
        //     is_disabled: payload.is_disabled,
        //     exam_subjects:payload.exam_subjects
        // },
        loading: false,
        error: false,
        success: true,
      };
    case JEE_ADVANCE_EXAM_DETAIL_FAILURE:
      return {
        loading: true,
        error: payload,
        success: false,
      };
    default:
      return state;
  }
}
