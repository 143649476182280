import { useCallback, useEffect, useReducer } from "react";

function reducer(state, action) {
    switch (action.type) {
        case "set": {
            return {
                ...state,
                time: action.payload.newTime,
            };
        }
        case "start": {
            const { initialTime } = action.payload;
            const time = initialTime;

            let startedDate = Date.now();
            let expectedEndDate = startedDate + time * 1000;

            startedDate = Math.floor(startedDate / 1000) * 1000;
            expectedEndDate = Math.floor(expectedEndDate / 1000) * 1000;

            return {
                ...state,
                status: "RUNNING",
                time,
                startedDate,
                expectedEndDate,
            };
        }
        case "stop": {
            return {
                ...state,
                status: "STOPPED",
                time: 0,
            };
        }
        default:
            return state;
    }
}

const TIMER_TYPE = "DECREMENTAL";
const STEP = 1;
const INTERVAL = 1000;

export const useTimer = ({ initialStatus = "STOPPED", initialTime = 0 } = {}) => {
    const [state, dispatch] = useReducer(reducer, {
        status: initialStatus,
        time: initialTime,
        startedDate: Date.now(),
        expectedEndDate: Date.now(),
    });

    const { status, time, startedDate, expectedEndDate } = state;

    const start = useCallback((initialTime) => {
        dispatch({ type: "start", payload: { initialTime } });
    }, []);

    useEffect(() => {
        let intervalId = null;

        if (status === "RUNNING") {
            intervalId = setInterval(() => {
                let newTime = TIMER_TYPE === "DECREMENTAL" ? time - STEP : time + STEP;

                if (newTime <= 0) {
                    dispatch({
                        type: "stop",
                        payload: { newTime: 0 },
                    });

                    clearInterval(intervalId);
                } else {
                    const time2 = Math.ceil((expectedEndDate - Date.now()) / 1000);
                    if (time2 < newTime) newTime = time2;

                    dispatch({
                        type: "set",
                        payload: { newTime },
                    });
                }
            }, INTERVAL);
        } else if (intervalId) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [status, time, expectedEndDate]);

    return { start, status, time, startedDate, expectedEndDate };
};

export const formatSecondsToTime = (time) => {
    // const time = time1 < time2 ? time1 : time2;

    var hours = Math.floor(time / 3600);

    var divisor_for_minutes = time % 3600;
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    hours = hours < 10 ? "0" + hours : hours;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}:${seconds}`;
};
