import { NOTE_SUCCESS, NOTE_FAILURE } from '../actionType'

export const initialState = {
    notes: [],
    loading: true,
    error: null,
    success: false,
}

export default function noteReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {   
    case NOTE_SUCCESS:
      return {
        notes: payload,
        loading: false,
        error: false,
        success:true,
      }
    case NOTE_FAILURE:
      return {        
        loading: true,
        error: payload,
        success: false
      }
    default:
      return state
  }
}