import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import { SET_DASHBOARD_STATE, SET_DASHBOARD_SELECTED_CLASS, SET_DASHBOARD_SELECTED_SUBJECT } from "../actionType";
import * as qs from "query-string";

export const getDashboardExams = (studentId) => async (dispatch) => {
	const response = await Api.doFetch("GET", {}, `${ApiUrl.ALL_EXAM_LIST_V2}${studentId}/?exam_type_id=1&skip=0&limit=10`);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { exams: response.data, loadingExams: false },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { exams: [], loadingExams: false },
		});
	}
};

export const getAttendence = (studentCourseId) => async (dispatch) => {
	const response = await Api.doFetch(
		"GET",
		{},
		ApiUrl.GET_STUDENT_ATTENDANCE + `?student_section_course_id=${studentCourseId}`
	);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { attendance: response.data, loadingAttendance: false, attendanceLoaded: true },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { attendance: {}, loadingAttendance: false },
		});
	}
};

export const getDashboardDpps = () => async (dispatch) => {
	const response = await Api.doFetch(
		"POST",
		{
			skip: 0,
			limit: 10,
		},
		`${ApiUrl.GET_STUDENT_DPPS}`
	);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { dpps: response.data.dpps, loadingDpps: false },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { dpps: [], loadingDpps: false },
		});
	}
};

export const getDashboardClasses = () => async (dispatch) => {
	const response = await Api.doFetch("GET", {}, `${ApiUrl.GET_DASHBOARD_CLASSES}`);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { classes: response.data.classes, loadingClasses: false },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { classes: [], loadingClasses: false },
		});
	}
};

export const getDashboardBannerDetails = () => async (dispatch) => {
	const response = await Api.doFetch("GET", {}, ApiUrl.GET_BANNER_DETAILS);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { banner: response.data, loadingBanner: false, bannerLoaded: true },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { banner: {}, loadingBanner: false },
		});
	}
};

export const getCourses = (studentId) => async (dispatch) => {
	const response = await Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_COURSE_LIST + "?student_id=" + studentId);
	if (response.status) {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { courses: response.data.courses.filter((c) => c.status), loadingCourses: false },
		});
	} else {
		dispatch({
			type: SET_DASHBOARD_STATE,
			payload: { courses: [], loadingCourses: false },
		});
	}
};

export const startDashboardLoader = () => (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_STATE,
		payload: {
			loadingExams: true,
			loadingDpps: true,
			loadingAttendance: true,
			loadingBanner: true,
			loadingCourses: true,
			loadingClasses: true,
		},
	});
};

export const updateCornerSelectedClass = (data) => (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_SELECTED_CLASS,
		payload: data,
	});
};

export const updateCornerSelectedSubject = (data) => (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_SELECTED_SUBJECT,
		payload: data,
	});
};

export const getCornerData = (type, classId) => async (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_STATE,
		payload: {
			[type]: {
				classes: [],
				subjects: [],
				loading: true,
			},
		},
	});
	let url = `${ApiUrl.GET_DASHBOARD_CORNER}`;
	const query = {};
	if (type !== null) {
		query["corner_type"] = type;
	}
	if (classId !== null) {
		query["class_assoc"] = classId;
	}
	const queryString = qs.stringify(query, { skipEmptyString: true, skipNull: true });
	if (queryString.length > 0) {
		url += "?" + queryString;
	}
	const cornerRes = await Api.doFetch("GET", {}, url);
	if (!classId) {
		dispatch(updateCornerSelectedClass({ [type]: cornerRes.data.class_id }));
	}
	dispatch(updateCornerSelectedSubject({ [type]: cornerRes.data.dashboard[0]?.subject.id ?? null }));

	dispatch({
		type: SET_DASHBOARD_STATE,
		payload: {
			[type]: {
				classes: cornerRes.data.class,
				subjects: cornerRes.data.dashboard,
				loading: false,
				loaded: true,
			},
		},
	});
};

export const resetDPPState = () => async (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_STATE,
		payload: { dpps: [], loadingDpps: true },
	});
};

export const resetExamsState = () => async (dispatch) => {
	dispatch({
		type: SET_DASHBOARD_STATE,
		payload: { exams: [], loadingDpps: true },
	});
};
