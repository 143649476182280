import React from "react";
import Api from "../../common/Api";
import myclassroom_logo from "../icons/myclassroom_logo.svg";
import profileLockedPNG from "./profile-locked.png";
import Styles from "../studentnav.module.css";
import profileStyles from "./profile-locked.module.css";
import { useEffect } from "react";
import ApiUrl from "../../common/ApiUrl";
import { useState } from "react";
import { useRef } from "react";
import { numberWithCommas } from "../../utils/helperFunctions";
import { useHistory } from "react-router-dom";

export default function ProfileLocked() {
	const subscribed = useRef(true);
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [amountDue, setAmountDue] = useState(null);

	const handleLogout = (e) => {
		if (e && e.preventDefault) e.preventDefault();

		Api.logout();
	};

	useEffect(() => {
		subscribed.current = true;

		return () => {
			subscribed.current = false;
		};
	}, []);

	useEffect(() => {
		const getPaymentDetails = async () => {
			setLoading(true);
			try {
				const response = await Api.doFetch("GET", {}, ApiUrl.PAYMENT_DUE);

				if (response && response.status === true) {
					if (subscribed.current) {
						if (response.data.profileLocked === false) {
							history.replace("/student");

							return;
						}

						setLoading(false);
						setAmountDue(response.data.amountDue);
					}
				} else {
					alert(response.message || "Some error occurred");
				}
			} catch (error) {
				console.log(error);
				alert("Some error occurred");
			}
		};

		getPaymentDetails();
	}, [history]);

	if (loading) return null;

	return (
		<>
			<nav className={Styles.navbar}>
				<div className="container-fluid" style={{ margin: "0 30px 0 30px" }}>
					<div className="navbar-header">
						<button
							type="button"
							id={Styles.btn1}
							className="navbar-toggle"
							data-toggle="collapse"
							data-target="#myNavbar">
							<i className="fas fa-bars" style={{ fontSize: "30px" }}></i>
						</button>
						<div className="nav_heading" style={{ maxWidth: "310px" }}>
							<img src={myclassroom_logo} style={{ width: "75%", marginTop: "15px" }} alt="logo" />
						</div>
					</div>

					<div className="collapse navbar-collapse" id="myNavbar">
						<ul className="nav navbar-nav navbar-right" id="close">
							<div id={Styles.collapsed} className="my-linkss">
								<li onClick={handleLogout}>Logout</li>
							</div>

							<div className={Styles.dropdown}>
								<button className={Styles.dropbtn}>
									<i className="fas fa-user" id={Styles.icon1}></i>
								</button>

								<div className={Styles.dropdownContent}>
									<a onClick={handleLogout} style={{ cursor: "pointer" }} href="#logout">
										<button
											style={{
												border: "none",
												background: "none",
												fontFamily: "Montserrat",
												fontSize: "14px",
												fontWeight: "500",
											}}>
											Logout
										</button>
									</a>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</nav>

			<div className={profileStyles.profileLocked}>
				<div className={profileStyles.container}>
					<img src={profileLockedPNG} alt="profile-locked" className={profileStyles.lockedImage} />

					<div className={profileStyles.helperText}>
						Oops. Your account is locked.
						<br />
						Please pay your outstanding fee to access your study material.
					</div>

					<div className={profileStyles.outstandingFee}>
						<span>Total outstanding fee:&nbsp;</span>
						<span className={profileStyles.outstandingFeeAmount}> ₹{numberWithCommas(amountDue)}</span>
					</div>

					<div>
						<a className={profileStyles.contactCounsellor} href="#contact-counsellor">
							Contact your counsellor
						</a>
					</div>
				</div>
			</div>

			<div className={profileStyles.profileLockedFooter}>
				<span>Facing any issues?&nbsp;</span>
				<span>
					Please write to us at <a href="mailto:care@myclassroom.digital">care@myclassroom.digital</a>
				</span>
			</div>
		</>
	);
}
