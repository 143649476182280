import { Dialog } from "@material-ui/core";
import React from "react";
import successGif from "../../assets/img/96237-success.gif";

const SuccessPopup = ({ open, time }) => {
	return (
		<>
			<Dialog open={open} onClose={() => {}} maxWidth="xs">
				<div className="popup-container-batch">
					<div className="popupHurrayHeading">Hurrey!</div>
					<div className="popupLightHeading">Sit back and relax, we shall inform you nearer to the test date</div>

					<img src={successGif} alt="Success Gif" className="redirectingSuccessGif" />

					<div className="redirectingLine">Redirecting in {time} s...</div>
				</div>
			</Dialog>
		</>
	);
};

export default SuccessPopup;
