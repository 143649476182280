import React from "react";
import { Modal } from "antd";
import "../styles/exam-screen.css";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";

function GenericV2MarkingSchemeModalMobile({
    show,
    setShow,
    onlyButton = false,
    answeredCount,
    unansweredCount,
    answeredReviewCount,
    unansweredReviewCount,
    notVisitedCount,
}) {
    const handleOpen = (e) => {
        Api.trackEvent(segmentEvents.GENERIC.MARKSCHEME_CLICK);
        e.preventDefault();
        setShow(true);
    };

    const handleClose = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        setShow(false);
    };

    return (
        <>
            <a href="#marking-scheme" className="marking-scheme mobile-ques" onClick={handleOpen}>
                <i className="far fa-question-circle"></i>
            </a>

            {!onlyButton && (
                <Modal width={1000} title="Marking Scheme" visible={show} footer={null} onCancel={handleClose}>
                    <table>
                        <tbody>
                            <tr className="table-row">
                                <td className="table-data">
                                    <div className="not-visited-div-1-mobile">
                                        {notVisitedCount}
                                    </div>
                                </td>
                                <td className="table-data" style={{ fontWeight: 600 }}>
                                    <div>Not Visited</div>
                                </td>
                                <td className="table-data" style={{ fontWeight: "400" }}>
                                    Question has not been visited yet.
                                </td>
                            </tr>

                            <tr className="table-row">
                                <td className="table-data">
                                    <div className="not-answered-active-div-1-mobile">
                                        {unansweredCount}
                                        
                                    </div>
                                </td>
                                <td className="table-data" style={{ fontWeight: 600 }}>
                                    <div>Not Answered</div>
                                </td>
                                <td className="table-data" style={{ fontWeight: "400" }}>
                                    Question has been visited but not answered.
                                </td>
                            </tr>

                            <tr className="table-row">
                                <td className="table-data">
                                    <div className="answered-active-div-1-mobile">{answeredCount}</div>
                                    
                                </td>
                                <td className="table-data" style={{ fontWeight: 600 }}>
                                    <div>Answered</div>
                                </td>
                                <td className="table-data" style={{ fontWeight: "400" }}>
                                    Question has been answered and will be considered for evaluation.
                                </td>
                            </tr>

                            <tr className="table-row">
                                <td className="table-data">
                                    <div className="not-ans-mkd-active-div-1-mobile">
                                        {unansweredReviewCount}
                                    </div>
                                </td>
                                <td className="table-data" style={{ fontWeight: 600 }}>
                                    <div>Not Answered & Marked For Review</div>
                                </td>
                                <td className="table-data" style={{ fontWeight: "400" }}>
                                    Question has not been answered and is marked for review.
                                </td>
                            </tr>

                            <tr className="table-row">
                                <td className="table-data">
                                    <div className="ans-mkd-active-div-1-mobile">
                                        {answeredReviewCount}
                                    </div>
                                </td>
                                <td className="table-data" style={{ fontWeight: 600 }}>
                                    Answered & Marked For Review
                                </td>
                                <td className="table-data" style={{ fontWeight: "400" }}>
                                    Question has been answered and is marked for review. Answer will be considered for evaluation.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="text-center" style={{ marginTop: 10 }}>
                        <a href="#marking-scheme-close" type="button" className="close-modal-btn" onClick={handleClose}>
                            Close
                        </a>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default GenericV2MarkingSchemeModalMobile;
