import React from "react";
import "./styles.css";

const ExamSummaryReview = ({
  quesStatusList,
  quesStatusCount,
  currentQuestion,
  onEndExam,
  changeSubmitStatus,
}) => {
  return (
    <div>
      <p className="detail-para">Exam Summary</p>
      <br />
      <div className="container">
        <div className="Exam-summary-details">
          <div className="Exam-summary-box">
            No of Questions
            <div className="Exam-number-data">{quesStatusList.length}</div>
          </div>
          <div className="Exam-summary-box-ex">
            Answered
            <div className="Exam-number-data">{quesStatusCount.answered}</div>
          </div>
          <div className="Exam-summary-box">
            Not Answered
            <div className="Exam-number-data">
              {currentQuestion.total_seconds === 0
                ? quesStatusCount.notAnswered + 1
                : quesStatusCount.notAnswered}
            </div>
          </div>
          <div className="Exam-summary-box">
            Marked for Review
            <div className="Exam-number-data">
              {quesStatusCount.markedForReview}
            </div>
          </div>
          <div className="Exam-summary-box">
            Answered & Marked for Review (will be considered for evaluation)
            <div className="Exam-number-data">
              {quesStatusCount.answeredAndMarkedReview}
            </div>
          </div>
          <div className="Exam-summary-box">
            Not Visited
            <div className="Exam-number-data">
              {currentQuestion.total_seconds === 0
                ? quesStatusCount.notVisited - 1
                : quesStatusCount.notVisited}
            </div>
          </div>
        </div>
        <hr></hr>
        <br />
        <div className="submission-details">
          Are you sure you want to submit for final marking?
          <br />
          No changes will be allowed after submission.
        </div>
        <br />
        <div>
          <center>
            <button className="yes-btn" onClick={() => onEndExam()}>
              Yes
            </button>
            <button
              className="no-btn"
              onClick={() => changeSubmitStatus(false)}
            >
              No
            </button>
          </center>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ExamSummaryReview);
