import FacultyImg from "../../assets/img/faculty_avatar.svg";

export const SubjectDummyData = [
	{
		id: 780445,
		student_assoc: 11109,
		subject_assoc: {
			id: 2,
			subject_name: "Physics XI",
			is_main_subject: true,
			logo: "https://zinedumain.s3.amazonaws.com/subject_logo/2.png",
			primary_color: "#E0FAFF",
			secondary_color: "#CFEDF3",
		},
	},
	{
		id: 780446,
		student_assoc: 11109,
		subject_assoc: {
			id: 3,
			subject_name: "Chemistry XI",
			is_main_subject: true,
			logo: "https://zinedumain.s3.amazonaws.com/subject_logo/3.png",
			primary_color: "#FFECF7",
			secondary_color: "#FADAED",
		},
	},
	{
		id: 780554,
		student_assoc: 11109,
		subject_assoc: {
			id: 997,
			subject_name: "Chemistry Surprise Test [24.03.21]",
			is_main_subject: true,
			logo: null,
			primary_color: "#C41229",
			secondary_color: "#E4D626",
		},
	},
	{
		id: 780560,
		student_assoc: 11109,
		subject_assoc: {
			id: 7,
			subject_name: "Math XI",
			is_main_subject: true,
			logo: "https://zinedumain.s3.amazonaws.com/subject_logo/7.png",
			primary_color: "#FFEBD9",
			secondary_color: "#FFDEBF",
		},
	},
];

export const MppDummyData = [
	{
		id: 8365,
		name: "Biomolecules-3",
		chapter_name: "Biomolecules",
		subject_name: "Biology XI",
		tag_ids: [7352, 7350],
		tag_names: ["Secondary Metabolites", "Nucleic Acid"],
		num_attempted: 0,
		total_questions: 6,
		button: "Start",
		status: "Pending since 13 days",
		teacher_id: 24,
		teacher_name: "Dr. Gagan Vohra",
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/11.png",
		subject_primary_color: "#E8FFF8",
		start_date: "2022-10-07 13:06:16",
		max_exam_marks: 24,
		chapter_id: 161,
		subject_id: 11,
	},
	{
		id: 8339,
		name: "Hydrocarbons-1",
		chapter_name: "Hydrocarbons",
		subject_name: "Chemistry XI",
		tag_ids: [6871],
		tag_names: ["Electrophilic Aromatic Substitution"],
		num_attempted: 0,
		total_questions: 3,
		button: "Start",
		status: "Pending since 22 days",
		teacher_id: 5,
		teacher_name: "Piyush",
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/3.png",
		subject_primary_color: "#FFECF7",
		start_date: "2022-09-28 12:52:15",
		max_exam_marks: 12,
		chapter_id: 471,
		subject_id: 3,
	},
	{
		id: 8329,
		name: "MIAP_DPP",
		chapter_name: "Motion in a Plane",
		subject_name: "Physics XI",
		tag_ids: [6153, 2792, 2790],
		tag_names: ["Horizontal Range", "Condition of Collision", "Angle of Velocity with Horizontal"],
		num_attempted: 0,
		total_questions: 10,
		button: "Start",
		status: "Pending since 48 days",
		teacher_id: null,
		teacher_name: null,
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/2.png",
		subject_primary_color: "#E0FAFF",
		start_date: "2022-09-02 13:05:00",
		max_exam_marks: 120,
		chapter_id: 476,
		subject_id: 2,
	},
	{
		id: 8326,
		name: "Physics Dpp",
		chapter_name: "Mechanical Properties of Fluids",
		subject_name: "Physics XI",
		tag_ids: [8255, 6264, 402, 6272, 408, 407, 6285, 396],
		tag_names: [
			"density",
			"Pressure due to a Fluid Column",
			"Archimedes’ Principle",
			"Buoyant Force",
			"Velocity of Efflux",
			"Bernoulli’s Equation",
			"Stokes’ Law",
			"Problems on Hydrostatic Pressure",
		],
		num_attempted: 0,
		total_questions: 10,
		button: "Start",
		status: "Pending since 48 days",
		teacher_id: null,
		teacher_name: null,
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/2.png",
		subject_primary_color: "#E0FAFF",
		start_date: "2022-09-02 12:54:00",
		max_exam_marks: 120,
		chapter_id: 824,
		subject_id: 2,
	},
	{
		id: 8304,
		name: "Ramesh Test Dev DPP",
		chapter_name: "Motion in a Plane",
		subject_name: "Physics XI",
		tag_ids: [6153, 2792, 2790],
		tag_names: ["Horizontal Range", "Condition of Collision", "Angle of Velocity with Horizontal"],
		num_attempted: 0,
		total_questions: 2,
		button: "Start",
		status: "Pending since 94 days",
		teacher_id: null,
		teacher_name: null,
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/2.png",
		subject_primary_color: "#E0FAFF",
		start_date: "2022-07-18 14:44:00",
		max_exam_marks: 40,
		chapter_id: 476,
		subject_id: 2,
	},
	{
		id: 8303,
		name: "Ramesh Test DPP Latest",
		chapter_name: "Motion in a Plane",
		subject_name: "Physics XI",
		tag_ids: [6153, 2792, 2790],
		tag_names: ["Horizontal Range", "Condition of Collision", "Angle of Velocity with Horizontal"],
		num_attempted: 0,
		total_questions: 2,
		button: "Start",
		status: "Pending since 94 days",
		teacher_id: null,
		teacher_name: null,
		subject_logo: "https://zinedumain.s3.us-east-2.amazonaws.com/subject_logo/2.png",
		subject_primary_color: "#E0FAFF",
		start_date: "2022-07-18 13:35:00",
		max_exam_marks: 40,
		chapter_id: 476,
		subject_id: 2,
	},
	{
		id: 8019,
		name: "Sample Advance Test 008",
		chapter_name: null,
		subject_name: null,
		tag_ids: null,
		tag_names: null,
		num_attempted: 0,
		total_questions: 0,
		button: "Start",
		status: "Pending since 334 days",
		teacher_id: null,
		teacher_name: null,
		subject_logo: null,
		subject_primary_color: null,
		start_date: "2021-11-20 15:09:30",
		max_exam_marks: 198,
		chapter_id: null,
		subject_id: null,
	},
];

export const ClassesDummyData = [
	{
		type: "past",
		class_type: "Lecture",
		chapter_name: "Motion in a Plane",
		subject_name: "Physics XI",
		subject_primary_color: "#E0FAFF",
		subject_secondary_color: "#CFEDF3",
		lecture: 1,
		faculty_name: "Ajit Lulla",
		faculty_thumbnail: FacultyImg,
		start_date: "2021-09-18T07:45:00Z",
		end_date: null,
		tags: [],
		notes_file: null,
		replay_url: "https://classrecording.s3.amazonaws.com/1974.mp4",
		schedule_type: "Zoom",
	},
	{
		type: "past",
		class_type: "Lecture",
		chapter_name: "Introdution",
		subject_name: "Math XI",
		subject_primary_color: "#FFEBD9",
		subject_secondary_color: "#FFDEBF",
		lecture: 1,
		faculty_name: "Arka Sen",
		faculty_thumbnail: FacultyImg,
		start_date: "2021-09-04T10:50:00Z",
		end_date: null,
		tags: [],
		notes_file: null,
		replay_url: "https://classrecording.s3.amazonaws.com/1321.mp4",
		schedule_type: "Zoom",
	},
	{
		type: "past",
		class_type: "Lecture",
		chapter_name: "Motion in a Plane",
		subject_name: "Physics XI",
		subject_primary_color: "#E0FAFF",
		subject_secondary_color: "#CFEDF3",
		lecture: 1,
		faculty_name: "Ajit Lulla",
		faculty_thumbnail: FacultyImg,
		start_date: "2021-09-18T07:45:00Z",
		end_date: null,
		tags: [],
		notes_file: null,
		replay_url: "https://classrecording.s3.amazonaws.com/1974.mp4",
		schedule_type: "Zoom",
	},
	{
		type: "past",
		class_type: "Lecture",
		chapter_name: "Introdution",
		subject_name: "Math XI",
		subject_primary_color: "#FFEBD9",
		subject_secondary_color: "#FFDEBF",
		lecture: 1,
		faculty_name: "Arka Sen",
		faculty_thumbnail: FacultyImg,
		start_date: "2021-09-04T10:50:00Z",
		end_date: null,
		tags: [],
		notes_file: null,
		replay_url: "https://classrecording.s3.amazonaws.com/1321.mp4",
		schedule_type: "Zoom",
	},
];

export const TestDummyData = [
	{
		id: 8317,
		exam_name: "Generic",
		exam_start_on: "2022-07-27T05:36:00Z",
		exam_end_on: "2022-10-31T05:35:00Z",
		exam_result_on: "2022-10-31T05:35:00Z",
		exam_duration: 180,
		max_exam_marks: 120,
		max_exam_questions: 30,
		exam_type_assoc: 1,
		exam_theme_assoc_id: 5,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
		],
		chapters: [
			{
				id: 2338,
				name: "Linear Programming",
			},
		],
		exam_status: 2,
		Subject: "Physics XII",
	},
	{
		id: 8294,
		exam_name: "Generic Advanced",
		exam_start_on: "2022-06-27T09:14:00Z",
		exam_end_on: "2022-08-27T09:14:00Z",
		exam_result_on: "2022-08-27T09:14:00Z",
		exam_duration: 720,
		max_exam_marks: 270,
		max_exam_questions: 54,
		exam_type_assoc: 4,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 499,
				name: "Math XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 497,
				name: "Physics XII",
			},
		],
		chapters: [
			{
				id: 2275,
				name: "Solid State",
			},
			{
				id: 2283,
				name: "Haloalkanes and Haloarenes",
			},
			{
				id: 2346,
				name: "Inverse Trigonometric Functions",
			},
			{
				id: 2348,
				name: "Continuity and Differentiability",
			},
			{
				id: 2353,
				name: "Definite Integration",
			},
		],
		exam_status: 5,
		Subject: "Math XII",
	},
	{
		id: 8293,
		exam_name: "Generic Mains",
		exam_start_on: "2022-06-27T09:10:00Z",
		exam_end_on: "2022-08-27T09:10:00Z",
		exam_result_on: "2022-08-27T09:10:00Z",
		exam_duration: 720,
		max_exam_marks: 300,
		max_exam_questions: 75,
		exam_type_assoc: 6,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 499,
				name: "Math XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 497,
				name: "Physics XII",
			},
		],
		chapters: [
			{
				id: 460,
				name: "Some Basic Concepts of Chemistry",
			},
			{
				id: 474,
				name: "Chemical Equilibrium",
			},
			{
				id: 671,
				name: "GASEOUS STATE",
			},
			{
				id: 885,
				name: "Chemistry",
			},
			{
				id: 2289,
				name: "Chemistry in Everyday Life",
			},
			{
				id: 2601,
				name: "Chemistry",
			},
			{
				id: 4077,
				name: "Acids, Bases and Salts",
			},
		],
		exam_status: 5,
		Subject: "Math XII",
	},
	{
		id: 8274,
		exam_name: "Generic test",
		exam_start_on: "2022-05-26T06:22:00Z",
		exam_end_on: "2022-05-31T18:22:00Z",
		exam_result_on: "2022-05-31T18:22:00Z",
		exam_duration: 180,
		max_exam_marks: 300,
		max_exam_questions: 75,
		exam_type_assoc: 6,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [],
		exam_status: 5,
		Subject: "Physics XII",
	},
	{
		id: 8273,
		exam_name: "Advanced",
		exam_start_on: "2022-05-24T10:44:00Z",
		exam_end_on: "2022-06-08T10:44:00Z",
		exam_result_on: "2022-06-08T10:44:00Z",
		exam_duration: 180,
		max_exam_marks: 180,
		max_exam_questions: 54,
		exam_type_assoc: 4,
		exam_theme_assoc_id: 1,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [],
		exam_status: 5,
		Subject: "Physics XII",
	},
	{
		id: 8272,
		exam_name: "Mains",
		exam_start_on: "2022-05-24T10:31:00Z",
		exam_end_on: "2022-06-08T10:31:00Z",
		exam_result_on: "2022-06-08T10:31:00Z",
		exam_duration: 180,
		max_exam_marks: 300,
		max_exam_questions: 75,
		exam_type_assoc: 6,
		exam_theme_assoc_id: 2,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [
			{
				id: 2287,
				name: "Biomolecules",
			},
			{
				id: 4089,
				name: "Area Related to Circles",
			},
		],
		exam_status: 5,
		Subject: "Physics XII",
	},
	{
		id: 8271,
		exam_name: "Mains",
		exam_start_on: "2022-05-13T11:20:00Z",
		exam_end_on: "2022-05-20T15:20:00Z",
		exam_result_on: "2022-05-20T15:20:00Z",
		exam_duration: 180,
		max_exam_marks: 300,
		max_exam_questions: 75,
		exam_type_assoc: 6,
		exam_theme_assoc_id: 2,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [
			{
				id: 2267,
				name: "Atoms",
			},
			{
				id: 4076,
				name: "Chemical Reactions and Equations",
			},
		],
		exam_status: 6,
		Subject: "Physics XII",
	},
	{
		id: 8270,
		exam_name: "Generic mains",
		exam_start_on: "2022-05-13T06:42:00Z",
		exam_end_on: "2022-05-19T18:42:00Z",
		exam_result_on: "2022-05-19T18:42:00Z",
		exam_duration: 180,
		max_exam_marks: 300,
		max_exam_questions: 75,
		exam_type_assoc: 6,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [
			{
				id: 2250,
				name: "Current Electricity",
			},
			{
				id: 2276,
				name: "Solutions",
			},
		],
		exam_status: 5,
		Subject: "Physics XII",
	},
	{
		id: 8269,
		exam_name: "Generic Advanced 2",
		exam_start_on: "2022-05-09T06:35:00Z",
		exam_end_on: "2022-05-16T17:35:00Z",
		exam_result_on: "2022-05-16T17:35:00Z",
		exam_duration: 180,
		max_exam_marks: 270,
		max_exam_questions: 54,
		exam_type_assoc: 4,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [
			{
				id: 2280,
				name: "p-block Elements (Group 15, 16, 17 and 18)",
			},
			{
				id: 2350,
				name: "Applications of Derivatives",
			},
			{
				id: 2356,
				name: "Determinants and Matrices",
			},
		],
		exam_status: 6,
		Subject: "Physics XII",
	},
	{
		id: 8268,
		exam_name: "Generic Advanced 1",
		exam_start_on: "2022-05-09T06:32:00Z",
		exam_end_on: "2022-05-16T17:32:00Z",
		exam_result_on: "2022-05-16T17:32:00Z",
		exam_duration: 180,
		max_exam_marks: 270,
		max_exam_questions: 54,
		exam_type_assoc: 4,
		exam_theme_assoc_id: 3,
		monitoring_type: "0",
		timer_status: "1",
		subjects: [
			{
				id: 497,
				name: "Physics XII",
			},
			{
				id: 242,
				name: "Chemistry XII",
			},
			{
				id: 499,
				name: "Math XII",
			},
		],
		chapters: [
			{
				id: 470,
				name: "GOC (Basic Concepts of Organic Chemistry)",
			},
			{
				id: 1007,
				name: "Electrochemistry",
			},
			{
				id: 2249,
				name: "Electrostatic Potential and Capacitance",
			},
		],
		exam_status: 6,
		Subject: "Physics XII",
	},
];

export const AttendanceDummyData = {
	total_classes: 32,
	attendance_count: 1,
	online_attendance_count: 1,
	offline_attendance_count: 0,
	attendance_percentage: 2.0408163265306123,
	Subjects: [
		{
			subject_name: "Chemistry XI",
			subject_total_attendance: 17,
			subject_attendance: 1,
		},
		{
			subject_name: "Physics XI",
			subject_total_attendance: 11,
			subject_attendance: 0,
		},
		{
			subject_name: "Math XI",
			subject_total_attendance: 4,
			subject_attendance: 0,
		},
	],
};
