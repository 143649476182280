import { Component } from 'react';
import Config from './config.json';
import { connect } from 'react-redux';
import { clearStudentDetail } from './store/actions/index';
import Api from './common/Api';
import ApiUrl from './common/ApiUrl';

class ErrorPage extends Component {
    constructor() {
        super();
        this.state = {};
    }
    Logout = () => {
        try {
            Api.logout();
            // this.clearCacheData();
            // localStorage.clear();
            // this.props.clearStudentDetail();
            // this.props.history.push('/');
        } catch (e) {
            console.log(e)
        }
    }
    clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };
    componentDidMount = () => {
        var formdata = new FormData();
        formdata.append("page_name", "error-page");

        let data = localStorage.getItem("userdetail");
        let token;

        if (data) {
            data = JSON.parse(data);

            if (data.token) {
                token = data.token;
            }

            var requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow",
                headers: {
                    Authorization: "Token " + token,
                },
            };

            
            Api.doUpload("POST", formdata, ApiUrl.POST_ANALYTICS)
                .then((response) => {
                    // console.log('response', response)
                    // if (response && response.status === 401) {
                        alert("You are logged into Another device");
                        this.Logout();
                    // }
                })
                .catch((error) => {
                    this.Logout();
                });

            // fetch(process.env.REACT_APP_BASE_URL + "users/post-analytics/", requestOptions)
            //     .then((response) => {
            //         response.json();
            //         if (response.status === 401) {
            //             alert("You are logged into Another device");
            //             this.Logout();
            //         }
            //     })
            //     .then((json) => {
            //         // console.log(json);
            //         // this.props.history.push('/');
            //     })
            //     .catch((error) => {
            //         this.Logout();
            //     });
        }
    }
    render() {
        return (
            <div>
                AWW! Snap Something went wrong Check your connection or try again later!!
                {/* <div>ERROR:{this.props.history.location.state.err}</div><br /> */}
                <button className='btn btn-primary' onClick={this.Logout}>Logout</button>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {};
};
export default connect(mapStateToProps, { clearStudentDetail })(ErrorPage)
