/**
 * @typedef {Object} ExamDataType
 * @property {object} examData
 * @property {any[]} examSubjects
 * @property {any[]} examSections 
 */

/** @type { { [examID: string]: ExamDataType } } */
export const initialState = {};

export const GENERIC_OFFLINE_SET_EXAM_DETAILS = "GENERIC_OFFLINE_SET_EXAM_DETAILS";

export default function examDataReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GENERIC_OFFLINE_SET_EXAM_DETAILS: {
            if (payload) {
                const { examID, examData, examSections, examSubjects, examSectionsList } = payload;

                if (examData) {
                    const currentState = {...state};
                    
                    currentState[examID] = {
                        examData,
                        examSectionsList,
                        examSections,
                        examSubjects,
                        updatedAt: Date.now(),
                    }

                    return currentState;
                }
            }
        }
        default:
            return state;
    }
}
