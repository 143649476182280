import React from "react";
import footerModuleCss from "./footer.module.css";
import mailImg from "../../assets/img/mail-line.svg";
import googlePlayImg from "../../assets/img/google-play.svg";
import copyright from "../../assets/img/copyright-line.svg";

import whatsappIcon from "../../assets/img/whatsapp-fill.svg";
import facebookIcon from "../../assets/img/facebook-circle-fill.svg";
import twitterIcon from "../../assets/img/twitter-fill.svg";
import linkedInICcon from "../../assets/img/linkedin-box-fill.svg";
import youtubeIcon from "../../assets/img/youtube-fill.svg";
import instagramIcon from "../../assets/img/instagram-fill.svg";
import Api from "../../common/Api";
import { segmentEvents } from "../../utils/constants";

const Footer = () => {
	return (
		<>
			<div className={footerModuleCss.footerMain}>
				<div className={`${footerModuleCss.footer} ${footerModuleCss.dashboard_container}`}>
					<div className={footerModuleCss.mailDiv}>
						<a
							href="mailto:care@myclassroom.digital"
							target="_blank"
							rel="noreferrer"
							className={footerModuleCss.mailAnchor}
							onClick={() => {
								Api.trackEvent(segmentEvents.FOOTER.MAIL_CLICK);
							}}
						>
							<img src={mailImg} alt="" /> care@myclassroom.digital
						</a>
					</div>

					<div className={footerModuleCss.googleCopyrightFlex}>
						<div className={footerModuleCss.googlePlay}>
							<a
								href="https://play.google.com/store/apps/details?id=com.myclassroom.digital"
								className={footerModuleCss.mailAnchor}
								target="_blank"
								rel="noreferrer"
								onClick={() => {
									Api.trackEvent(segmentEvents.FOOTER.PLAYSTORE_CLICK);
								}}
							>
								<img src={googlePlayImg} alt="google play" /> Google Play
							</a>
						</div>

						<div className={footerModuleCss.copyright}>
							<img src={copyright} alt="" /> {new Date().getFullYear()} myclassroom
						</div>
					</div>

					<div className={footerModuleCss.socialsContainer}>
						<p style={{ marginBottom: 0 }}>
							<a 
								href="https://myclassroom.digital/refund-policy/" 
								className={footerModuleCss.linksStyle}
								onClick={() => {
									Api.trackEvent(segmentEvents.FOOTER.REFUND_CLICK);
								}}
							>
								Refund Policy
							</a>
						</p>

						<p className={footerModuleCss.bulletDivider} style={{ marginBottom: 0 }}>
							•
						</p>

						<p style={{ marginBottom: 0 }}>
							<a 
								href="https://myclassroom.digital/privacy-policy/"
								className={footerModuleCss.linksStyle}
								onClick={() => {
									Api.trackEvent(segmentEvents.FOOTER.PRIVACY_CLICK);
								}}
							>
								Privacy Policy
							</a>
						</p>

						<p className={footerModuleCss.bulletDivider} style={{ marginBottom: 0 }}>
							•
						</p>

						<p style={{ marginBottom: 0 }}>
							<a 
								href="https://myclassroom.digital/terms-and-conditions/" 
								className={footerModuleCss.linksStyle}
								onClick={() => {
									Api.trackEvent(segmentEvents.FOOTER.TERMS_CLICK);
								}}
							>
								Terms and Conditions
							</a>
						</p>
					</div>

					<div className={footerModuleCss.socialsContainer}>
						<a href="https://wa.me/919289739534" title="WhatsApp" target="_blank" rel="noreferrer" onClick={() => Api.trackEvent(segmentEvents.FOOTER.WHATSAPP_CLICK)}>
							<img src={whatsappIcon} alt="" />
						</a>
						<a href="https://www.facebook.com/MyClassrooom" title="Facebook" target="_blank" rel="noreferrer"onClick={() => Api.trackEvent(segmentEvents.FOOTER.FACEBOOK_CLICK)}>
							<img src={facebookIcon} alt="" />
						</a>
						<a href="https://twitter.com/my_classroom_" title="Twitter" target="_blank" rel="noreferrer" onClick={() => Api.trackEvent(segmentEvents.FOOTER.TWITTER_CLICK)}>
							<img src={twitterIcon} alt="" />
						</a>
						<a
							href="https://www.linkedin.com/company/my-class-room/"
							target="_blank"
							rel="noreferrer"
							title="LinkedIn"
							onClick={() => Api.trackEvent(segmentEvents.FOOTER.LINKEDIN_CLICK)}
						>
							<img src={linkedInICcon} alt="" />
						</a>
						<a href="https://www.youtube.com/c/myClassroomm" title="Youtube" target="_blank" rel="noreferrer" onClick={() => Api.trackEvent(segmentEvents.FOOTER.YOUTUBE_CLICK)}>
							<img src={youtubeIcon} alt="" />
						</a>
						<a href="https://instagram.com/myclassroom_learning/" title="Instagram" target="_blank" rel="noreferrer" onClick={() => Api.trackEvent(segmentEvents.FOOTER.INSTAGRAM_CLICK)}>
							<img src={instagramIcon} alt="" />
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
