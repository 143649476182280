import { SvgIcon } from "@material-ui/core";
import React from "react";

const LeftArrowIcon = ({ fill = "#000000", style }) => {
	return (
		<SvgIcon viewBox="0 0 20 20" fill="none" style={{ transform: "rotate(180deg)", marginRight: 16, ...style }}>
			<path
				d="M15.215 8.75009L8.51 2.04509L10.2775 0.277588L20 10.0001L10.2775 19.7226L8.51 17.9551L15.215 11.2501H0V8.75009H15.215Z"
				fill={fill}
			/>
		</SvgIcon>
	);
};

export default LeftArrowIcon;
