import React from "react";
import Api from "../../../../common/Api";
import { segmentEvents } from "../../../../utils/constants";
import LeftChevron from "../../icons/LeftChevron";
import RightChevron from "../../icons/RightChevron";
import "./styles.css";

const QuestionButtons = ({
  question,
  saveAndNext,
  resetQuestion,
  saveAndMarkForReview,
  markForeReviewAndNext,
  getNextQuestion,
  getPrevQuestion,
  changeSubmitStatus,
  totalQuestions,
}) => {
  return (
    <div className="buttons-container">
      <div>
        <button className="save_btn" onClick={saveAndNext}>
          SAVE & NEXT
        </button>
        <button className="clear_btn" onClick={resetQuestion}>
          CLEAR
        </button>
        <button className="review_btn" onClick={saveAndMarkForReview}>
          SAVE & Mark For Review
        </button>
        <button className="review_next_btn" onClick={markForeReviewAndNext}>
          Mark For Review & Next
        </button>
      </div>
      <div className="submit-con">
        <button
          className={
            question.serial_no === 1
              ? "submit-con-btn-disable"
              : "submit-con-btn"
          }
          onClick={getPrevQuestion}
        >
          <LeftChevron />
          BACK
        </button>
        <button
          className={
            question.serial_no < totalQuestions
              ? "submit-con-btn"
              : "submit-con-btn-disable"
          }
          onClick={() =>
            question.serial_no < totalQuestions
              ? getNextQuestion(false, false)
              : null
          }
        >
          NEXT
          <RightChevron />
        </button>
        <button
          className="submit-con-btn-O"
          onClick={() => {
            Api.trackEvent(segmentEvents.MAINS.SUBMIT_CLICK);
            changeSubmitStatus(true);
          }}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default QuestionButtons;
