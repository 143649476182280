import { SvgIcon } from "@material-ui/core";
import React from "react";

const NcertNavIcon = ({ fill }) => {
	return (
		<SvgIcon
			style={{
				width: 21,
				height: 20,
				fill: "none",
			}}
			viewBox="0 0 21 20">
			<path
				d="M11 18V20H9V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734786 0.105357 0.480432 0.292893 0.292896C0.48043 0.10536 0.734784 2.80864e-06 1 2.80864e-06H7C7.56759 -0.000670604 8.12878 0.119759 8.64612 0.35325C9.16346 0.586741 9.62502 0.92792 10 1.354C10.375 0.92792 10.8365 0.586741 11.3539 0.35325C11.8712 0.119759 12.4324 -0.000670604 13 2.80864e-06H19C19.2652 2.80864e-06 19.5196 0.10536 19.7071 0.292896C19.8946 0.480432 20 0.734786 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H11ZM18 16V2H13C12.4696 2 11.9609 2.21072 11.5858 2.58579C11.2107 2.96086 11 3.46957 11 4V16H18ZM9 16V4C9 3.46957 8.78929 2.96086 8.41421 2.58579C8.03914 2.21072 7.53043 2 7 2H2V16H9Z"
				fill={fill}
			/>
		</SvgIcon>
	);
};

export default NcertNavIcon;
