import {
  JEE_ADVANCE_EXAM_QUESTION_SUCCESS,
  JEE_ADVANCE_EXAM_QUESTION_FAILURE,
  JEE_ADVANCE_EXAM_QUESTION_SUBMIT,
  JEE_ADVANCE_UPDATE_QUESTIONS,
  JEE_ADVANCE_START_LOADER,
} from "../actionType";

export const initialState = {
  examQuestions: {
    curr_question_assoc_id: "",
    exam_section_assoc_id: "",
    exam_status_id: "",
    exam_subject_assoc_id: "",
    num_seconds: "",
    question_status_list: [],
    section_questions: [],
  },
  loading: true,
  error: null,
  success: false,
};

export default function jeeAdvanceExamQuestionReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case JEE_ADVANCE_START_LOADER:
      return {
        ...state,
        loading: true,
      };
    case JEE_ADVANCE_EXAM_QUESTION_SUCCESS:
      return {
        //examQuestions:payload,
        examQuestions: {
          ...state.examQuestions,
          ...payload.data,
          exam_section_assoc_id: parseInt(payload.data.exam_section_assoc_id),
        },
        loading: false,
        error: false,
        success: true,
      };
    case JEE_ADVANCE_EXAM_QUESTION_SUBMIT:
      let currentindex = state.examQuestions.section_questions.findIndex(
        (o) => o.id === payload.question_info.id
      );
      let section_questions = state.examQuestions.section_questions;
      let oldData = section_questions[currentindex];
      payload.question_info.question_bank_assoc = oldData?.question_bank_assoc;
      payload.question_info.exam_master_assoc = oldData?.exam_master_assoc;
      section_questions[currentindex] = payload.question_info;
      return {
        examQuestions: {
          ...state.examQuestions,
          question_status_list: payload.question_status_list,
          section_questions: section_questions,
        },
        loading: false,
        error: false,
        success: true,
      };
    case JEE_ADVANCE_EXAM_QUESTION_FAILURE:
      return {
        examQuestions: payload,
        loading: false,
        error: true,
        success: false,
      };
    case JEE_ADVANCE_UPDATE_QUESTIONS:
      return {
        examQuestions: {
          ...state.examQuestions,
          ...payload,
        },
        loading: false,
        error: false,
        success: true,
      };

    default:
      return state;
  }
}
