import { useEffect } from "react";
import { pageTitles } from "../constants";

export const useDocumentTitle = (title, options = {}) => {
	useEffect(() => {
		const pageTitle = getPageTitle(title);

		if (document && !options.skipSetTitle) {
			document.title = pageTitle;
		}
	}, [title, options]);

	return null;
};

export const getPageTitle = (title) => {
	return title ? `${title} | ${pageTitles.ROOT}` : pageTitles.ROOT;
};
