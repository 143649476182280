import { RESET_CORNER_STATE, SET_CORNER_STATE } from "../actionType";

export const initialState = {
	filterState: {
		selectedClass: null,
		selectedSubject: null,
		selectedChapter: null,
	},
	filters: {
		class: [],
		subject: [],
		chapter: [],
	},
	subjects: [],
	chapterBank: {},
	ncertQuestionsBank: {},
	loading: true,
	loadingChapter: null,
	expandedChapter: null,
};

export default function dashboardReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SET_CORNER_STATE:
			return { ...state, ...payload };
		case RESET_CORNER_STATE:
			return { ...initialState };
		default:
			return state;
	}
}
