import {
  JEE_ADVANCE_EXAM_DETAIL_SUCCESS,
  JEE_ADVANCE_EXAM_DETAIL_FAILURE,
} from "../actionType";
import Api, { handleAPIError } from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";

export const getJeeAdvanceExamDetail =
  (exam_id, student, requestOptions) => async (dispatch, getState) => {
    const exam_data = getState().JeeAdvanceExam.examDetail;
    if (Object.keys(exam_data).length === 0) {
      if (student?.id) {
        try {
          const response = await Api.doFetch(
            "GET",
            {},
            ApiUrl.GET_EXAM_INFO + `${exam_id}/?student_id=${student.id}`
          );

          if (response.status === true) {
            dispatch({
              type: JEE_ADVANCE_EXAM_DETAIL_SUCCESS,
              payload: response.data,
            });
          } else {
            // Stop alert
            handleAPIError(response.message);
          }
        } catch (error) {
          dispatch({
            type: JEE_ADVANCE_EXAM_DETAIL_FAILURE,
            payload: error,
          });
        }
      }
    }
  };

export const getJeeAdvanceExamDetailFixed =
  (exam_id, student) => async (dispatch, getState) => {
    const exam_data = getState().JeeAdvanceExam.examDetail;

    if (Object.keys(exam_data).length === 0) {
      if (student?.id) {
        try {
          const response = await Api.doFetch(
            "GET",
            {},
            ApiUrl.GET_EXAM_INFO + `${exam_id}/?student_id=${student.id}`
          );

          if (response.status === true) {
            dispatch({
              type: JEE_ADVANCE_EXAM_DETAIL_SUCCESS,
              payload: response.data,
            });
          } else {
            handleAPIError(response.message);
          }
        } catch (error) {
          dispatch({
            type: JEE_ADVANCE_EXAM_DETAIL_FAILURE,
            payload: error,
          });
        }
      }
    }
  };
