import { Dialog } from "@material-ui/core";
import React from "react";
import useMediaQuery from "../../utils/hooks/use-media-query";
import SolidButton from "../../components/common/SolidButton";
import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import Swal from "sweetalert2";

const OnlineCoursePopup = ({ open, handleClose, successModalOpenHandler, banner, studentData, errorModalOpenHandler }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	const [loading, setLoading] = React.useState(false);
	const payNow = () => {
		setLoading(true);
		Api.moEngageTrackEvent("Web - Free user payment clicked", {
			Timestamp: Date(),
			"course name": banner.course_name,
			price: banner.course_online_price,
		});
		Api.doFetch("POST", {}, `${ApiUrl.ENROLL_COURSE}/${banner.course_binding}`).then((res) => {
			if (res.status) {
				displayRazorpay(res.data.order_id);
			} else setLoading(false);
		});
	};

	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	};

	const displayRazorpay = async (orderId) => {
		const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

		if (!res) {
			Swal.fire("", "Razorpay SDK failed to load. Are you online?", "error");
			return;
		}

		let amount_obj = banner.course_online_price * 100;
		const options = {
			key: process.env.REACT_APP_RZP_ID,
			amount: amount_obj.toString(),
			currency: "INR",
			description: "myclassroom",
			order_id: orderId,
			handler: async function (response) {
				Api.doFetch("POST", {}, `${ApiUrl.COMPLETE_COURSE_PAYMENT}/${response.razorpay_payment_id}`).then(() => {
					handleClose();
					setLoading(false);
					successModalOpenHandler();
				});
			},
			modal: {
				ondismiss: function () {
					setLoading(false);
				},
			},
			prefill: {
				name: studentData.name,
				email: studentData.email ? studentData.email : "student@myclassroom.digital",
				contact: "+91" + studentData.mobile_number,
			},
			theme: {
				color: "#61dafb",
			},
		};
		const paymentObject = new window.Razorpay(options);
		paymentObject.on("payment.failed", function () {
			Api.doFetch("POST", {}, `${ApiUrl.FAILED_COURSE_PAYMENT}/${orderId}`).then(() => {
				setLoading(false);
				paymentObject.close();
				errorModalOpenHandler();
				handleClose();
				const rzpContainers = document.getElementsByClassName("razorpay-container");
				for (let i = 0; i < rzpContainers.length; i++) {
					rzpContainers.item(i).remove();
				}
			});
		});

		paymentObject.open();
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" PaperProps={{ style: { borderRadius: 10 } }}>
			<div className="popup-container-batch onlineCoursePopup">
				<div className="headerFlex">
					<div className="popupHeading"></div>
				</div>

				<img
					src={isMobile ? banner.course_img_mobile : banner.course_img_web}
					alt="aits-popup-top-img"
					className="aits-popup-top-img"
				/>
				<div className="benefitsContainer">
					<div className="benefits-heading">Benifits</div>

					<div className="courseBenefits">
						{banner.features.map((item, index) => {
							if (!item.details) return null;
							return (
								<div className="benefitsEachCourse" key={index}>
									{item.details}
								</div>
							);
						})}
					</div>

					<div className="bottomCtaContainer">
						<div className="CtaContainerText">{banner.bottom_nav_communication}</div>
						<SolidButton
							onClick={payNow}
							small
							loading={loading}
							style={{
								width: "fit-content",
								padding: "8px 10px",
								marginTop: 0,
							}}
							loaderStyle={{ marginRight: 18 }}>
							Pay ₹ {banner.course_online_price}
						</SolidButton>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default OnlineCoursePopup;
