import { NOTIFICATION_SUCCESS, NOTIFICATION_FAILURE } from '../actionType'

export const initialState = {
    notificationList: [],
    loading: true,
    error: null,
    success: false,
}

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {   
    case NOTIFICATION_SUCCESS:
      return {
        notificationList: payload,
        loading: false,
        error: false,
        success:true,
      }
    case NOTIFICATION_FAILURE:
      return {        
        loading: true,
        error: payload,
        success: false
      }
    default:
      return state
  }
}