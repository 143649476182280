import { Dialog } from "@material-ui/core";
import React from "react";
import errorGif from "../../assets/img/error.gif";

const ErrorPopup = ({ open }) => {
	return (
		<>
			<Dialog open={open} onClose={() => {}} maxWidth="xs">
				<div className="popup-container-batch">
					<div className="popupHurrayHeading">Oops!</div>
					<div className="popupLightHeading">Your payment didn’t happen</div>
					<div className="justify-content-center d-flex">
						<img src={errorGif} alt="Success Gif" className="redirectingErrorGif" />
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default ErrorPopup;
