import { signUpStep } from "../../utils/constants";
import { HANDLE_SIGN_UP, RESET_SIGN_UP } from "../actionType";

export const initialState = {
	step: signUpStep.REGISTER,
	mobile: "",
	mobileConfirmed: false,
	otp: "",
	otpConfirmed: false,
	name: "",
	standard: "",
	aspiration: "",
	state: "",
	city: "",
	tempToken: "",
	goToStep: signUpStep.REGISTER_STEP1,
};

export default function signupReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case HANDLE_SIGN_UP:
			return {
				...state,
				...payload,
			};
		case RESET_SIGN_UP:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
