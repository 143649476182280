export const initialState = {};

export const GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL = "GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL";
export const GENERIC_OFFLINE_QUESTION_SAVE_FAILED = "GENERIC_OFFLINE_QUESTION_SAVE_FAILED";
export const GENERIC_OFFLINE_QUESTION_SYNC_QUEUE = "GENERIC_OFFLINE_QUESTION_SYNC_QUEUE";
export const GENERIC_OFFLINE_QUESTION_CLEAR_QUEUE = "GENERIC_OFFLINE_QUESTION_CLEAR_QUEUE";

export default function studentAnswerDataReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GENERIC_OFFLINE_QUESTION_SAVE_FAILED: {
			if (payload) {
				const { examId, studentId, questionId } = payload;

				const examStudentKey = examId && studentId && `${examId}_${studentId}`;

				if (examStudentKey) {
					const currentState = { ...state };

					if (currentState[examStudentKey]) {
						const currentDataQuestionsFailedToSave = {
							...currentState[examStudentKey].questionsFailedToSave,
						};

						if (!(questionId in currentDataQuestionsFailedToSave)) {
							currentDataQuestionsFailedToSave[questionId] = true;
						}

						currentState[examStudentKey] = {
							...currentState[examStudentKey],
							questionsFailedToSave: currentDataQuestionsFailedToSave,
						};
					} else {
						currentState[examStudentKey] = {
							questionsFailedToSave: {
								[questionId]: true,
							},
							questionsQueuedToSync: [],
						};
					}

					return currentState;
				}
			}

			return state;
		}

		case GENERIC_OFFLINE_QUESTION_SAVE_SUCCESSFULL: {
			if (payload) {
				const { examId, studentId, questionId } = payload;

				const examStudentKey = examId && studentId && `${examId}_${studentId}`;

				if (examStudentKey) {
					const currentState = { ...state };

					if (currentState[examStudentKey] && currentState[examStudentKey].questionsFailedToSave) {
						const currentDataQuestionsFailedToSave = {
							...currentState[examStudentKey].questionsFailedToSave,
						};

						if (questionId in currentDataQuestionsFailedToSave) {
							delete currentDataQuestionsFailedToSave[questionId]
						}

						currentState[examStudentKey] = {
							...currentState[examStudentKey],
							questionsFailedToSave: currentDataQuestionsFailedToSave,
						};

						return currentState;
					}

				}
			}

			return state;
		}

		case GENERIC_OFFLINE_QUESTION_SYNC_QUEUE: {
			if (payload) {
				const { examId, studentId } = payload;

				const examStudentKey = examId && studentId && `${examId}_${studentId}`;

				if (examStudentKey) {
					const currentState = { ...state };

					if (currentState[examStudentKey]) {
						let { questionsFailedToSave, questionsQueuedToSync } = currentState[examStudentKey];

						const questionKeys = Object.keys(questionsFailedToSave);

						questionsQueuedToSync = Array.from(new Set([...questionsQueuedToSync, ...questionKeys]));

						currentState[examStudentKey] = {
							questionsFailedToSave: {},
							questionsQueuedToSync,
						};
					}

					return currentState;
				}
			}

			return state;
		}

		case GENERIC_OFFLINE_QUESTION_CLEAR_QUEUE: {
			if (payload) {
				const { examId, studentId } = payload;

				const examStudentKey = examId && studentId && `${examId}_${studentId}`;

				if (examStudentKey) {
					const currentState = { ...state };

					if (currentState[examStudentKey]) {
						currentState[examStudentKey] = {
							...currentState[examStudentKey],
							questionsQueuedToSync: [],
						};

						return currentState;
					}
				}
			}

			return state;
		}

		default:
			return state;
	}
}
