import React from "react";
import { useEffect, useRef } from "react";
import { formatSecondsToTime, useTimer } from "../../../utils/hooks/use-timer";

const areEqual = (prevProps, nextProps) => {
  return prevProps.examTimeLeft === nextProps.examTimeLeft;
};

const Timer = ({ examTimeLeft, onTimerEnd = () => {} }) => {
  const timerRef = useRef(false);
  const {
    time,
    status,
    start: startTimer,
  } = useTimer({ timerType: "DECREMENTAL" });

  useEffect(() => {
    if (status === "STOPPED") {
      if (timerRef.current && typeof onTimerEnd === "function") {
        onTimerEnd();
      }
    }
  }, [status, onTimerEnd]);

  useEffect(() => {
    if (typeof examTimeLeft === "number") {
      timerRef.current = true;
      if (examTimeLeft > 0) {
        startTimer(examTimeLeft);
      } else {
        if (typeof onTimerEnd === "function") {
          onTimerEnd();
        }
      }
    }
  }, [examTimeLeft, startTimer, onTimerEnd]);

  return <>{formatSecondsToTime(time)}</>;
};

export default React.memo(Timer, areEqual);
