import Api from "./Api";
import ApiUrl from "./ApiUrl";


const generator = () => {
    let beingCalled = false;

    const checkProfileUnlocked = (callback) => {
        if (beingCalled) return;
        beingCalled = true;

        Api.doFetch("GET", null, ApiUrl.CHECK_LOCKED).then((response) => {
            if (response && response.status === true) {
                callback();
            }
        }).finally(() => {
            beingCalled = false;
        });
    };

    return {
        checkProfileUnlocked,
    }
}

export const { checkProfileUnlocked } = generator();