import React from "react";
import parse from "html-react-parser";

function GenericV2QuestionInput({ currentQuestion = null, handleNumericInput, isDPP = false }) {
	if (!currentQuestion) return null;

	return (
		<div className="exam-questions">
			<div className="question-text">
				Q{currentQuestion.serial_no}: {parse(currentQuestion.question_bank_assoc.question)}
			</div>

			{isDPP && currentQuestion?.question_bank_assoc?.tags?.length > 0 && (
				<div className="question-tag-wrapper">
					{currentQuestion.question_bank_assoc.tags.map((item, index) => {
						return (
							<span key={index} className="question-tag">
								{item.tag_name}
							</span>
						);
					})}
				</div>
			)}

			<div className="options">
				<div>
					<br />
					{/* ENG-306 */}
					{/* <span className="fill-ups-inst">Round-off the answer to 2 decimal places</span> <br /> <br /> */}
					<input
						type="text"
						onChange={handleNumericInput}
						value={currentQuestion.ans_given}
						placeholder="Enter answer"
						className="fill-ups-text"
					/>
				</div>
			</div>
		</div>
	);
}

export default GenericV2QuestionInput;
