import { combineReducers } from "redux";
import examDataReducer from "./examDataReducer";
import miscDataReducer from "./miscReducer";
import studentAnswerDataReducer from "./studentAnswerDataReducer";
import studentExamDataReducer from "./studentExamDataReducer";

const examReducer = combineReducers({
    examData: examDataReducer,
    studentExamData: studentExamDataReducer,
    studentAnswerData: studentAnswerDataReducer,
    miscData: miscDataReducer,
    // TODO: Add queue after finishing others
});

export default examReducer