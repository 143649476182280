/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { Collapse } from "antd";
import styled from "styled-components";
import Styles from "./CommonCard.module.css";
import QuestionCard from "./QuestionCard";
import OneLineTooltip from "../common/OneLineTooltip";
import VideoGallery from "./VideoGallery";
import { useMediaQuery } from "@material-ui/core";
import ChapterTag from "../common/ChapterTag";
import { cornerType } from "../../utils/constants";
import NoContentDiv from "./NoContentAvailable";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCornerSelectedSubject } from "../../store/actions";

const { Panel } = Collapse;

const CustomCollapse = ({ subjectData, child, setIsOpen, index, expandedId, type, isMobile, ...restProps }) => {
	const dispatch = useDispatch();
	return (
		<Collapse
			accordion
			bordered={false}
			activeKey={expandedId === subjectData.subject.id ? 0 : null}
			onChange={() => {
				if (expandedId === subjectData.subject.id) {
					dispatch(updateCornerSelectedSubject({ [type]: null }));
					return;
				}
				dispatch(updateCornerSelectedSubject({ [type]: subjectData.subject.id }));
			}}
			expandIconPosition="end"
			expandIcon={({ isActive }) => {
				setIsOpen(isActive);
				return (
					<HiChevronDown
						size="2.2em"
						style={{
							transition: "all 0.3s",
							...(isActive && {
								transform: "rotate(180deg) translate(0, 13px)",
							}),
						}}
					/>
				);
			}}
			{...restProps}>
			<Panel
				header={
					<span
						style={{
							fontFamily: "Montserrat",
							fontWeight: isMobile ? 500 : 600,
							fontSize: isMobile ? 15 : 20,
						}}>
						{subjectData?.subject?.subject_name}
					</span>
				}
				key={0}>
				{child}
			</Panel>
		</Collapse>
	);
};

const StyledCollapse = styled(CustomCollapse)`
	.ant-collapse-header {
		border-radius: 10px !important;
		background: ${(props) => props.subjectData?.subject?.primary_color || "#fff"};
	}
	.ant-collapse-borderless {
		background: black;
	}
`;

const CollapsibleItem = ({ subjectData, itemIndex, type, expandedId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMediaQuery("(max-width: 991px)");
	const history = useHistory();

	const ExpandContent = () => {
		return (
			<div style={{ marginTop: 10 }}>
				<div className={Styles.chapterDetails} style={{ marginBottom: 10 }}>
					<ChapterTag
						title="Chapter 1"
						className={Styles.tag}
						accent={subjectData?.subject?.primary_color}
						outlined
						small
					/>
					<OneLineTooltip
						title={subjectData?.chapter?.chapter_name}
						customStyle={{
							marginLeft: "1rem",
							...(isMobile && { whiteSpace: "wrap", marginLeft: 0 }),
						}}>
						<span className={Styles.text}>{subjectData?.chapter?.chapter_name}</span>
					</OneLineTooltip>
				</div>
				{(
					type === cornerType.NCERT
						? subjectData?.video?.length === 0 && (subjectData?.questions?.length === 0 || !subjectData?.questions)
						: subjectData?.video?.length === 0 && subjectData?.notes?.length === 0
				) ? (
					<NoContentDiv
						message={`Chapter 1 content unavailable`}
						subMessage={`Click ‘See all’ to view other chapters`}
					/>
				) : (
					<>
						{subjectData?.video.length > 0 ? (
							<div
								style={{
									marginTop: "2.5rem",
								}}>
								<VideoGallery
									videoList={subjectData?.video}
									type={type}
									chapterId={subjectData?.chapter?.id}
									subjectData={subjectData}
									noLeftPadding
									isHome
								/>
							</div>
						) : (
							<div style={{ marginBottom: "2.5rem" }}>
								<NoContentDiv
									message={`Chapter 1 lectures unavailable`}
									subMessage={`Click ‘See all’ to view other chapters`}
								/>
							</div>
						)}

						{(type === cornerType.JEE || type === cornerType.NEET) && (
							<>
								<div
									style={{
										marginTop: isMobile ? 18 : 0,
									}}
									className={Styles.text}>
									Notes
								</div>
								<div
									className={`d-flex-wrap ${subjectData?.notes?.length === 0 && "justify-content-centre"}`}
									style={{
										gap: isMobile ? "12px" : "auto",
									}}>
									{subjectData?.notes?.length > 0 ? (
										<>
											{subjectData.notes.slice(0, 3).map((data, i) => {
												return (
													<QuestionCard
														questionName={data.note_name}
														questionId={data.question_id}
														isNote
														key={i}
														pdfLink={data.notes_pdf_url}
													/>
												);
											})}

											{subjectData.notes.length > 3 && (
												<div
													className={Styles.solution_div + " " + Styles.see_all}
													style={{ justifyContent: "center" }}
													onClick={() => {
														history.push({
															pathname: `/student/${type}`,
															state: {
																subjectId: subjectData.subject.id,
																classId: subjectData.subject.class_assoc,
															},
														});
													}}>
													<span className={Styles.text}>See all</span>
												</div>
											)}
										</>
									) : (
										<NoContentDiv
											message={`Chapter 1 notes unavailable`}
											subMessage={`Click ‘See all’ to view other chapters`}
										/>
									)}
								</div>
							</>
						)}

						{type === cornerType.NCERT && (
							<>
								<div
									style={{
										marginTop: isMobile ? 18 : 0,
									}}
									className={Styles.text}>
									Exercise solutions
								</div>
								<div
									className={`d-flex-wrap ${subjectData?.questions?.length === 0 && "justify-content-centre"}`}
									style={{
										gap: isMobile ? "12px" : "auto",
									}}>
									{subjectData?.questions?.length > 0 ? (
										<>
											{subjectData.questions.slice(0, 3).map((data, i) => {
												return (
													<QuestionCard
														questionName={"Question " + data.question_sno}
														questionId={data.question_id}
														key={i}
														chapterId={subjectData?.chapter?.id}
														exerciseId={data.exercise_assoc}
													/>
												);
											})}

											{subjectData.questions.length > 3 && (
												<div
													className={Styles.solution_div + " " + Styles.see_all}
													style={{ justifyContent: "center" }}
													onClick={() => {
														history.push({
															pathname: `/student/ncert`,
															state: {
																subjectId: subjectData.subject.id,
																classId: subjectData.subject.class_assoc,
															},
														});
													}}>
													<span className={Styles.text}>See all</span>
												</div>
											)}
										</>
									) : (
										<NoContentDiv
											message={`${subjectData?.chapter?.chapter_name} exercise soultions unavailable`}
											subMessage={`Click ‘See all’ to view other chapters`}
										/>
									)}
								</div>
							</>
						)}
					</>
				)}
			</div>
		);
	};

	return (
		<div
			style={{
				transition: "all 0.3s",
				overflow: "hidden",

				...(isOpen
					? { padding: 10, backgroundColor: "#F8F8F8", borderRadius: 12 }
					: { backgroundColor: "white", borderRadius: 10 }),
			}}>
			<StyledCollapse
				subjectData={subjectData}
				child={<ExpandContent />}
				setIsOpen={setIsOpen}
				index={itemIndex}
				isMobile={isMobile}
				expandedId={expandedId}
				type={type}
			/>
		</div>
	);
};

export default CollapsibleItem;
