import { SvgIcon } from "@material-ui/core";
import React from "react";

const JeeNavIcon = ({ fill }) => {
	return (
		<SvgIcon
			style={{
				width: 21,
				height: 20,
				fill: "none",
			}}
			viewBox="0 0 21 20">
			<path
				d="M2.596 1L7.5 6.928L12.404 1H15L8.798 8.497L15 15.994V16H12.41L7.5 10.066L2.59 16H0V15.994L6.202 8.497L0 1H2.596ZM18.55 2.58C18.6529 2.4823 18.7279 2.359 18.7675 2.22273C18.807 2.08647 18.8096 1.94215 18.7749 1.80456C18.7403 1.66697 18.6697 1.54107 18.5703 1.43977C18.471 1.33847 18.3465 1.2654 18.2096 1.22807C18.0727 1.19074 17.9284 1.19049 17.7914 1.22733C17.6544 1.26417 17.5296 1.33679 17.4299 1.43774C17.3302 1.53869 17.2592 1.66434 17.224 1.8018C17.1889 1.93926 17.191 2.0836 17.23 2.22L16.075 2.55C15.99 2.25217 15.9753 1.93867 16.0321 1.6342C16.0889 1.32973 16.2156 1.04259 16.4022 0.795396C16.5888 0.548202 16.8302 0.347701 17.1075 0.209678C17.3848 0.0716549 17.6903 -0.000121492 18 1.54367e-07C18.4004 5.51893e-05 18.7916 0.120292 19.1229 0.345148C19.4542 0.570004 19.7104 0.889127 19.8583 1.26121C20.0062 1.63329 20.039 2.04121 19.9525 2.43215C19.866 2.82309 19.6641 3.17907 19.373 3.454L17.744 5H20V6H16V5L18.55 2.58Z"
				fill={fill}
			/>
		</SvgIcon>
	);
};

export default JeeNavIcon;
