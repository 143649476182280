import React, { useEffect, useState } from "react";
import LeftArrowIcon from "../../../images/dashboard/LeftArrowIcon";
import RightArrowIcon from "../../../images/dashboard/RightArrowIcon";
import Styles from "./horizontal-scroll.module.css";

const HorizontalScroll = ({ children }) => {
	const [leftButtonColor, setLeftButtonColor] = useState("#000000");
	const [rightButtonColor, setRightButtonColor] = useState("#000000");

	const scrollRef = React.useRef();

	useEffect(() => {
		window.addEventListener("resize", setControlsVisibility);
		setControlsVisibility();
		return () => window.removeEventListener("resize", setControlsVisibility);
	}, []);

	useEffect(() => {
		const scroller = scrollRef.current;
		scroller.addEventListener("scroll", updateControls);
		updateControls();
		return () => scroller.removeEventListener("scroll", updateControls);
	}, []);

	const setControlsVisibility = () => {
		const scroller = scrollRef.current;
		if (scroller.scrollWidth > scroller.offsetWidth) {
			updateControls();
		}
	};

	const updateControls = () => {
		const scroller = scrollRef.current;
		setLeftButtonColor(scroller.scrollLeft === 0 ? "#868686" : "#000000");
		setRightButtonColor(scroller.scrollLeft + scroller.offsetWidth + 1 >= scroller.scrollWidth ? "#868686" : "#000000");
	};

	const scrollLeft = () => {
		const scroller = scrollRef.current;
		scroller.scrollLeft -= scroller.offsetWidth;
	};

	const scrollRight = () => {
		const scroller = scrollRef.current;
		scroller.scrollLeft += scroller.offsetWidth;
	};

	return (
		<div style={{ position: "relative" }}>
			<div className={Styles.scrollerWrapper} ref={scrollRef}>
				{children}
			</div>

			{children && children.length > 0 && (
				<div className={Styles.arrowWrapper}>
					<div style={{ cursor: leftButtonColor === "#000000" ? "pointer" : "default" }} onClick={scrollLeft}>
						<LeftArrowIcon className={Styles.leftArrow} fill={leftButtonColor} />
					</div>
					<div style={{ cursor: rightButtonColor === "#000000" ? "pointer" : "default" }} onClick={scrollRight}>
						<RightArrowIcon className={Styles.rightArrow} fill={rightButtonColor} />
					</div>
				</div>
			)}
		</div>
	);
};

export default HorizontalScroll;
