import React from "react";
import { Modal } from "antd";
import "../styles/exam-screen.css";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";

function GenericV2DPPSubmitModal({ show, setShow, handleSubmitExamConfirm, handleCancelSubmit }) {
    const handleOpen = (e) => {
        Api.trackEvent(segmentEvents.GENERIC.SUBMIT_CLICK);
        e.preventDefault();
        setShow(true);
    };

    const handleClose = (e) => {
        if (e && e.preventDefault) e.preventDefault();
        setShow(false);
    };

    return (
        <>
            <button onClick={handleOpen} className="btn submit-btn" style={{ alignSelf: "center" }}>
                SUBMIT
            </button>

            <Modal centered width={400} title={null} visible={show} footer={null} onCancel={handleClose}>
                <div className="container-modal">
                    <p style={{ fontWeight: 600, color: "rgb(1, 44, 99)", textAlign: "center", marginTop: "2rem" }}>
                        Are you sure you want to submit the exam?
                    </p>

                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                        <button onClick={handleSubmitExamConfirm} type="button" className="btn submit-modal-submit">
                            Yes
                        </button>

                        <button onClick={handleCancelSubmit} type="button" className="btn submitmodalclose" style={{ backgroundColor: '#e5e5e5' }}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default GenericV2DPPSubmitModal;
