import { useEffect, useState } from "react";

// Refer: https://usehooks-ts.com/react-hook/use-media-query

const getMatches = (query) => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
        return window.matchMedia(query).matches;
    }
    return false;
};

function useMediaQuery(query) {
    const [matches, setMatches] = useState(getMatches(query));

    useEffect(() => {
        function handleChange() {
            setMatches(getMatches(query));
        }

        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // Listen matchMedia
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener("change", handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener("change", handleChange);
            }
        };
    }, [query]);

    return matches;
}

export default useMediaQuery;
