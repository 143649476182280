import Styles from "./CommonCard.module.css";
import { AiFillPlayCircle, AiFillFile } from "react-icons/ai";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const QuestionCard = ({ isNote, chapterId, questionId, exerciseId, pdfLink, questionName, replaceOnRedirect }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	const history = useHistory();
	return (
		<div
			className={Styles.solution_div}
			style={{ width: 200 }}
			onClick={() => {
				if (isNote) window.open(pdfLink, "_blank");
				else if (replaceOnRedirect)
					history.replace({
						pathname: `/student/ncert/${chapterId}/${exerciseId}/question/${questionId}`,
					});
				else
					history.push({
						pathname: `/student/ncert/${chapterId}/${exerciseId}/question/${questionId}`,
					});
			}}>
			<div className={Styles.text + " " + Styles.small} dangerouslySetInnerHTML={{ __html: questionName }}></div>
			<div
				style={{
					display: "flex",
					width: isMobile ? 30 : 45,
					height: isMobile ? 28 : 45,
					backgroundColor: "#F3F3F3",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: 8,
				}}>
				{isNote ? (
					isMobile ? (
						<AiFillFile color="#0E0D0D" fontSize="16" />
					) : (
						<AiFillFile color="#0E0D0D" fontSize="22" />
					)
				) : isMobile ? (
					<AiFillPlayCircle color="#0E0D0D" fontSize="16" />
				) : (
					<AiFillPlayCircle color="#0E0D0D" fontSize="22" />
				)}
			</div>
		</div>
	);
};
export default QuestionCard;
