import React, { useCallback, useState } from "react";
import GenericV2InstructionsModal from "./instructions-modal";
import GenericV2Timer from "./timer";

// import MyClassroomLogoSVG from "../../../images/genericV2/myclassroom-logo.svg";

function GenericV2SubmitExam({
    questionCount,
    answeredCount,
    unansweredCount,
    answeredReviewCount,
    unansweredReviewCount,
    notVisitedCount,
    examTimeLeft,
    handleSubmitExamConfirm = () => {},
    handleCancelSubmit = () => {},
    isOffline = false,
}) {
    const [showInstructions, setShowInstructions] = useState(false);

    const handleTimeEnd = useCallback(() => {}, []);

    return (
        <>
            <nav className="nav-bar">
                <div>
                    <div className="genericV2-myclassroom-logo"></div>
                    {/* <img className="nav-logo-img" src={MyClassroomLogoSVG} alt="myclassroom-logo" /> */}
                </div>
                <div className="nav-bar-right">
                    <GenericV2Timer examTimeLeft={examTimeLeft} onTimerEnd={handleTimeEnd} />

                    <span className="info" style={{ alignSelf: "center" }}>
                        <GenericV2InstructionsModal isOffline={isOffline} show={showInstructions} setShow={setShowInstructions} />
                    </span>
                </div>
            </nav>

            <p className="detail-para">Exam Summary</p>
            <br />
            <div className="container">
                <div className="Exam-summary-details">
                    <div className="Exam-summary-box">
                        <div className="exam-number-text">No of Questions</div>
                        <div className="Exam-number-data">{questionCount}</div>
                    </div>
                    <div className="Exam-summary-box-ex">
                        <div className="exam-number-text">Answered</div>
                        <div className="Exam-number-data">{answeredCount}</div>
                    </div>
                    <div className="Exam-summary-box">
                        <div className="exam-number-text">Not Answered</div>
                        <div className="Exam-number-data">{unansweredCount}</div>
                    </div>
                    <div className="Exam-summary-box">
                        <div className="exam-number-text">Marked for Review</div>
                        <div className="Exam-number-data">{unansweredReviewCount}</div>
                    </div>
                    <div className="Exam-summary-box">
                        <div className="exam-number-text">{"Answered & Marked"} for Review<br/> (will be considered for evaluation)</div>
                        <div className="Exam-number-data">{answeredReviewCount}</div>
                    </div>
                    <div className="Exam-summary-box">
                        <div className="exam-number-text">Not Visited</div>
                        <div className="Exam-number-data">{notVisitedCount}</div>
                    </div>
                </div>
                <hr></hr>
                <br />
                <div className="submission-details">
                    Are you sure you want to submit for final marking?
                    <br />
                    No changes will be allowed after submission.
                </div>
                <br />
                <div>
                    <center>
                        <button className="yes-btn" onClick={handleSubmitExamConfirm}>
                            Yes
                        </button>
                        <button className="no-btn" onClick={handleCancelSubmit}>
                            No
                        </button>
                    </center>
                </div>
            </div>
        </>
    );
}

export default GenericV2SubmitExam;
