import React from "react";
import SolidButton from "../../components/common/SolidButton";
import { useHistory } from "react-router-dom";
import Api from "../../common/Api";
import { dashboardIds, segmentEvents } from "../../utils/constants";
import { useMediaQuery } from "@material-ui/core";
import NoDataImg from "../../assets/img/dashboard_no_data_found.svg";
import { MppDummyData } from "./dummyData";
import lockedImg from "../../assets/img/lockedImg.svg";
import MppCard from "./components/mpp-card";
import HorizontalScroll from "../../components/common/HorizontalScroll";

const Mpp = ({ dpps, studentData, token, active, isFree, requestACall }) => {
	const history = useHistory();
	const isMobile = useMediaQuery("(max-width: 991px)");

	const viewAll = () => {
		Api.moEngageTrackEvent(segmentEvents.STUDENT.ASSIGNMENT_VIEW);
		Api.trackEvent(segmentEvents.DASHBOARD.MPP_VIEWALL);
		history.push({
			pathname: "/student/dpp-list/all",
			state: { exam_type: 1 },
		});
	};

	return (
		<div className="dashboard_mpp_container" id={dashboardIds.MPP}>
			<div className="dashboard_content_header_container">
				{active && <div className="dashboard_content_active" />}

				<p className="dashboard_content_header">Mandatory practice problems</p>

				<p className="dashboard_content_view_all" onClick={viewAll}>
					See all
				</p>
			</div>
			<div style={{ position: "relative" }}>
				<HorizontalScroll>
					{(isFree ? MppDummyData : dpps).map((data, i) => {
						return <MppCard key={i} index={i} data={data} studentData={studentData} token={token} />;
					})}
					{!isFree && dpps.length > 0 && (
						<div
							onClick={viewAll}
							className="dashboard_view_all_card"
							style={{
								marginLeft: isMobile ? 0 : "2rem",
								marginRight: !isMobile ? 0 : "2rem",
							}}>
							See all
						</div>
					)}
				</HorizontalScroll>
				{!isFree && dpps.length === 0 && (
					<div style={{ textAlign: "center", padding: "32px 0" }}>
						<img src={NoDataImg} alt="No Test" />
						<div className="check_later_text1">No MPPs available</div>
						<div className="check_later_text2">Check back later</div>
					</div>
				)}
				{isFree && (
					<div className="dashboard_blur">
						<div>
							<img src={lockedImg} alt="" />
							<br />
							Connect with us to unlock MPPs.
							<br />
							<SolidButton
								small
								style={{
									marginTop: "1rem",
									width: 165,
								}}
								onClick={() => {
									Api.trackEvent(segmentEvents.FREEDASHBOARD.REQUESTCALL_MPP);
									requestACall("MPP");
								}}
							>
								Request a call
							</SolidButton>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(Mpp);
