import React from "react";
import Styles from "./tag.module.css";

const ChapterTag = ({ title, accent = "#E0FAFF", outlined = false, small = false, className = "" }) => {
	return (
		<div
			className={`${Styles.container} ${className} ${small && Styles.small}`}
			style={{
				border: `2px solid ${accent}`,
				...(!outlined && { background: accent }),
			}}>
			{title}
		</div>
	);
};

export default ChapterTag;
