import { Component } from "react";
import Styles from "./studentnav.module.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clearStudentDetail } from "./../store/actions/index";
import myclassroom_logo from "./icons/myclassroom_logo.svg";
import Api from "../common/Api";
import ApiUrl from "../common/ApiUrl";
import Swal from "sweetalert2";
import { segmentEvents } from "../utils/constants";

class Studentnav extends Component {
	constructor(props) {
		super(props);
		let data = localStorage.getItem("userdetail");
		data = JSON.parse(data);
		this.state = {
			student: data.username,
			token: data.token,
			section_name: "",
			enable_learning_module: false,
			switch_class: false,
			mobile_number: data.username,
		};
	}
	Logout = (e) => {
		if (e && e.preventDefault) e.preventDefault();
		Api.logout();
		// window.Moengage.destroy_session();
		// this.clearCacheData();
		// localStorage.clear();
		// this.props.clearStudentDetail();
		// this.props.history.push("/");
	};
	clearCacheData = () => {
		try {
			caches.keys().then((names) => {
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		} catch (error) {}
	};

	reloadFunc = () => {
		this.props.history.push("/student");
		document.location.reload();
		alert("Redirecting ....");
	};

	switchClass = () => {
		let req = {
			redirect: "follow",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Token " + this.state.token,
			},
		};

		let formdata = new FormData();
		formdata.append("MobileNumber", this.state.student);

		Api.doUpload("POST", formdata, ApiUrl.SWITCH_USER)
			.then((response) => {
				var receiveddata = JSON.stringify(response.payload);
				localStorage.clear();
				localStorage.setItem("userdetail", receiveddata);
				this.reloadFunc();
			})
			.catch((err) => {
				this.props.history.push("/error");
			});

		// axios
		//     .post(
		//         process.env.REACT_APP_BASE_URL + "users/switch-user/",
		//         formdata,
		//         req
		//     )
		//     .then((response) => {
		//         // console.log(response.data.payload);
		//         var receiveddata = JSON.stringify(response.data.payload);
		//         localStorage.clear();
		//         localStorage.setItem("userdetail", receiveddata);
		//         this.reloadFunc();

		//         // const reloadFunc = () => {

		//         // }
		//         // setTimeout(
		//         //   reloadFunc()
		//         //  ,10000
		//         // )
		//         // toast("Class Switched Successfully!"
		//         //  , {
		//         //   position: "top-center",
		//         //   autoClose: 10000,
		//         //   hideProgressBar: true,
		//         //   closeOnClick: true,
		//         //   pauseOnHover: true,
		//         //   draggable: true,
		//         //   progress: undefined,
		//         //   style: { backgroundColor: "rgba(68, 178, 0, 1)", color: "white" },
		//         // });
		//     })
		//     .catch((err) => {
		//         // console.log(err.data + "err data switch");
		//         this.props.history.push("/error");
		//     });
	};

	componentDidMount() {
		var requestOptions1 = {
			redirect: "follow",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Token " + this.state.token,
			},
		};

		Api.doFetch("GET", {}, ApiUrl.GET_STUDENT_DETAILS2 + "?username=" + this.state.student)
			.then((response) => {
				if (response.status === true) {
					const data = response.data;
					const user = Api.getUserData()?.student_data;
					if (!user || !user.id) {
						let temp = {
							student_data: {
								name: data.student_data.name,
								id: data.student_data.id,
								email: data.student_data.email,
								mobile_number: data.student_data.mobile_number,
							},
						};
						localStorage.setItem("userData", JSON.stringify(temp));
						Api.identifyUser(data);
						Api.moEngageTrackEvent(segmentEvents.GENERAL.LOGIN);
					}

					this.setState({
						section_name: data.student_data.section_assoc.section_name,
						enable_learning_module: data.student_data.enable_learning_module,
						switch_class: data.student_data.switch_class,
						mobile_number: data.student_data.mobile_number,
					});
				} else {
					// alert(response.message)
				}
				// console.log("data",this.state.section_name);
			})
			.catch((err) => {
				console.log(err, err?.response?.status);

				if (err && err.response && err.response.status === 401) {
					const swalOptions = {
						title: "Logged in to another device, please login here again",
						showDenyButton: false,
						showCancelButton: false,
						confirmButtonText: "Okay",
						timer: 1500,
					};

					Swal.fire("", "Logged in to another device, please login here again", "error");

					window.Moengage.destroy_session();
					this.clearCacheData();
					localStorage.clear();
					this.props.clearStudentDetail();
					setTimeout(() => {
						window.location.href = "/";
					}, 1000);
				}
			});

		// axios
		//     .get(
		//         process.env.REACT_APP_BASE_URL +
		//         "users/get-student-details2/?username=" +
		//         this.state.student,
		//         requestOptions1
		//     )
		//     .then((data) => {
		//         var current = moment().startOf('day');
		//         window.Moengage.add_unique_user_id(data.data.student_data.id);
		//         window.Moengage.add_user_name(data.data.student_data.name ? data.data.student_data.name : "");
		//         window.Moengage.add_mobile(data.data.student_data.mobile_number ? data.data.student_data.mobile_number : "");
		//         window.Moengage.add_email(data.data.student_data.email ? data.data.student_data.email : "");
		//         window.Moengage.add_user_attribute("city", data.data.student_data.city_assoc ? data.data.student_data.city_assoc : "");
		//         window.Moengage.add_user_attribute(
		//             "class",
		//             data.data.student_data.program_name ? data.data.student_data.program_name : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "section",
		//             data.data.student_data.section_assoc.section_name ? data.data.student_data.section_assoc.section_name : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "course",
		//             data.data.student_data.program_name ? data.data.student_data.program_name : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "centre",
		//             data.data.student_data.school_assoc.name ? data.data.student_data.school_assoc.name : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "overdue amount",
		//             data.data.student_data.fee_pending ? data.data.student_data.fee_pending : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "registration date",
		//             data.data.student_data.date_joined ? data.data.student_data.date_joined : ""
		//         );
		//         window.Moengage.add_user_attribute("user type", "student");

		//         window.Moengage.add_user_attribute("overdue days", data.data.installment_data.next_installment_date == null ? "0" : moment.duration(data.data.installment_data.next_installment_date.diff(current)).asDays());
		//         window.Moengage.add_user_attribute(
		//             "test completion percentage",
		//             data.data.student_data.testcompletions ? data.data.student_data.testcompletions : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "course end date",
		//             data.data.student_data.student_course_end_date ? data.data.student_data.student_course_end_date : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "dpp completion percentage",
		//             data.data.student_data.dppcompletions ? data.data.student_data.dppcompletions : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "last 7 Days attendance percentage",
		//             data.data.attendance_past_week.attendance_percentage ? data.data.attendance_past_week.attendance_percentage : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "yesterday's attendance",
		//             data.data.attendance_yesterday.attendance_percentage ? data.data.attendance_yesterday.attendance_percentage : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "next installment amount",
		//             data.data.installment_data.due_amount ? data.data.installment_data.due_amount : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "next installment date",
		//             data.data.installment_data.next_installment_date ? data.data.installment_data.next_installment_date : ""
		//         );
		//         window.Moengage.add_user_attribute("gender", data.data.student_data.gender ? data.data.student_data.gender : "");

		//         window.Moengage.add_user_attribute(
		//             "email opt in",
		//             data.data.student_data.email_opt_in ? data.data.student_data.email_opt_in : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "whatsapp opt in",
		//             data.data.student_data.whatsapp_opt_in ? data.data.student_data.whatsapp_opt_in : ""
		//         );

		//         window.Moengage.add_user_attribute(
		//             "state",
		//             data.data.student_data.school_assoc.state ? data.data.student_data.school_assoc.state : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "parent name",
		//             data.data.parents_data[0].name ? data.data.parents_data[0].name : ""
		//         );
		//         window.Moengage.add_user_attribute(
		//             "parent mobile number",
		//             data.data.parents_data[0].mobile_number ? data.data.parents_data[0].mobile_number : ""
		//         );

		//         this.setState({
		//             section_name: data.data.student_data.section_assoc.section_name,
		//             enable_learning_module: data.data.student_data.enable_learning_module,
		//             switch_class: data.data.student_data.switch_class,
		//             mobile_number: data.data.student_data.mobile_number,
		//         });
		//         // console.log("data",this.state.section_name);
		//     })
		//     .catch((err) => {
		//         console.log(err);
		//     });
	}
	render() {
		return (
			<div>
				<nav className={Styles.navbar}>
					<div className="container-fluid" style={{ margin: "0 30px 0 30px" }}>
						<div className="navbar-header">
							<button
								type="button"
								id={Styles.btn1}
								className="navbar-toggle"
								data-toggle="collapse"
								data-target="#myNavbar">
								<i className="fas fa-bars" style={{ fontSize: "30px" }}></i>
							</button>
							<div className="nav_heading" style={{ maxWidth: "310px" }}>
								<Link to="/">
									<img src={myclassroom_logo} style={{ width: "75%", marginTop: "15px" }} alt="logo" />
								</Link>
							</div>
						</div>

						<div className="collapse navbar-collapse" id="myNavbar">
							<ul className="nav navbar-nav navbar-right" id="close">
								<div id={Styles.collapsed} className="my-linkss">
									{/* {this.state.switch_class && (
                    <li>
                      <button
                        style={{
                          backgroundColor: "#E97A26",
                          color: "#ffffff",
                          padding: "10px",
                          border: "0",
                        }}
                        onClick={this.switchClass}
                      >
                        Switch Class
                      </button>
                    </li>
                  )} */}
									{this.state.enable_learning_module && (
										<li>
											<Link
												to={{
													pathname: "/student/practice-session",
												}}>
												Practice<sup>(New)</sup>
											</Link>
										</li>
									)}
									{(this.state.section_name == "Agra_DrpE_Sept21_AKMC" ||
										this.state.section_name == "DPSG_11E_May21" ||
										this.state.section_name == "Faridabad_DrpE_Sept21_AKMC" ||
										this.state.section_name == "Indirapuram_DrpE_Sept21_AKMC") && (
										<a href="https://myclassroom.speedlabs.in/loginAKMC.aspx">
											<li>Self Learning Module </li>
										</a>
									)}

									<Link to="/student/Myschedule">
										<li>My Schedule</li>
									</Link>

									<Link
										to={{
											pathname: "/student/objectivetest",
											state: { exam_type: 1 },
										}}>
										<li>My Test</li>
									</Link>

									<Link
										to={{
											pathname: "/student/dpp-list/all",
											state: { exam_type: 2 },
										}}>
										<li>My Assignment</li>
									</Link>

									<Link
										to={{
											pathname: "/student/Quizlist",
											state: { exam_type: 3 },
										}}>
										<li>My Quiz</li>
									</Link>

									<Link to="/student/profile">
										<li>My Profile </li>
									</Link>
									{/* <Link to="/student/profile">
                    <li>Fee Summary </li>
                  </Link> */}
									<Link to="/student/changepassword">
										<li> Change Password </li>
									</Link>

									<Link to="/student/help-and-support">
										<li>Help & Support</li>
									</Link>

									<a href="#logout" onClick={this.Logout}>
										<li>Logout</li>
									</a>
								</div>

								{/* {this.state.switch_class && (
                  <div className={Styles.dropdown}>
                    <li>
                      <button
                        style={{
                          backgroundColor: "#E97A26",
                          color: "#ffffff",
                          padding: "10px",
                          border: "0",
                          margin: "15px",
                        }}
                        onClick={this.switchClass}
                      >
                        Switch Class
                      </button>
                    </li>
                  </div>
                )} */}
								{(this.state.section_name == "Agra_DrpE_Sept21_AKMC" ||
									this.state.section_name == "DPSG_11E_May21" ||
									this.state.section_name == "Faridabad_DrpE_Sept21_AKMC" ||
									this.state.section_name == "Indirapuram_DrpE_Sept21_AKMC") && (
									<div className={Styles.dropdown}>
										<a href="https://myclassroom.speedlabs.in/loginAKMC.aspx">
											<button
												style={{
													fontFamily: "Montserrat",
													fontSize: "14px",
													marginRight: "20px",
													textDecoration: "none",
													color: "white",
													fontWeight: "700",
													height: "2.5em",
													backgroundColor: "#ed7a27",
													borderRadius: "20px",
													padding: "6px",
													margin: "10px",
												}}>
												<p> Self Learning Module</p>
											</button>
										</a>
									</div>
								)}
								{this.state.enable_learning_module && (
									<div className={Styles.dropdown}>
										<Link
											to="/student/practice-session"
											style={{
												border: "none",
												fontFamily: "Montserrat",
												fontSize: "14px",
												marginRight: "20px",
												textDecoration: "none",
												color: "#fff",
												background: "#EA7A26",
												borderRadius: "23px",
												padding: "5px",
												fontWeight: "600",
											}}>
											Practice<sup>(New)</sup>
										</Link>
									</div>
								)}
								<div className={Styles.dropdown}>
									<Link
										to="/"
										style={{
											border: "none",
											background: "none",
											fontFamily: "Montserrat",
											fontSize: "14px",
											marginRight: "20px",
											textDecoration: "none",
											color: "black",
											fontWeight: "500",
										}}>
										Home
									</Link>
								</div>
								<div className={Styles.dropdown}>
									<Link
										to="/student/Myschedule"
										style={{
											border: "none",
											background: "none",
											fontFamily: "Montserrat",
											fontSize: "14px",
											marginRight: "20px",
											textDecoration: "none",
											color: "black",
											fontWeight: "500",
										}}>
										My Schedule
									</Link>
								</div>

								<div className={Styles.dropdown}>
									<button
										style={{
											border: "none",
											background: "none",
											fontFamily: "Montserrat",
											fontSize: "14px",
											marginRight: "20px",
											color: "#000",
											fontWeight: "500",
										}}>
										My Content <i className="fas fa-angle-down"></i>
									</button>

									<div className={Styles.dropdownContent}>
										<Link
											to={{
												pathname: "/student/objectivetest",
												state: { exam_type: 1 },
											}}>
											My Test
										</Link>

										<Link
											to={{
												pathname: "/student/dpp-list/all",
												state: { exam_type: 2 },
											}}>
											My Assignment
										</Link>

										<Link
											to={{
												pathname: "/student/Quizlist",
												state: { exam_type: 3 },
											}}>
											My Quiz
										</Link>
									</div>
								</div>

								<div className={Styles.dropdown}>
									<button className={Styles.dropbtn}>
										<i className="fas fa-user" id={Styles.icon1}></i>
									</button>

									<div className={Styles.dropdownContent}>
										<Link to="/student/profile">My Profile</Link>
										<Link to="/student/changepassword">Change Password</Link>
										<Link to="/student/help-and-support">Help & Support</Link>

										{/* <Link to="/student/feesummary">Fee Summary</Link> */}
										<a onClick={this.Logout} style={{ cursor: "pointer" }} href="#logout">
											<button
												style={{
													border: "none",
													background: "none",
													fontFamily: "Montserrat",
													fontSize: "14px",
													fontWeight: "500",
												}}>
												Logout
											</button>
										</a>
									</div>
								</div>
							</ul>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {};
};
export default connect(mapStateToProps, { clearStudentDetail })(withRouter(Studentnav));
