import React from "react";
import QuestionNotVisited from "../QuestionStatus/QuestionNotVisited";
import QuestionNotAnswered from "../QuestionStatus/QuestionNotAnswered";
import QuestionAnswered from "../QuestionStatus/QuestionAnswered";
import QuestionReviewNotAnswered from "../QuestionStatus/QuestionReviewNotAnswered";
import QuestionReviewAnswered from "../QuestionStatus/QuestionReviewAnswered";
import "./styles.css";

const AttemptSummary = ({ currentQuestion, quesStatusCount }) => {
  const list = [
    {
      icon: (
        <QuestionNotVisited>{quesStatusCount.notVisited}</QuestionNotVisited>
      ),
      label: "Not Visited",
    },
    {
      icon: (
        <QuestionNotAnswered>{quesStatusCount.notAnswered}</QuestionNotAnswered>
      ),

      label: "Not Answered",
    },
    {
      icon: <QuestionAnswered>{quesStatusCount.answered}</QuestionAnswered>,
      label: "Answered",
    },
    {
      icon: (
        <QuestionReviewNotAnswered>
          {quesStatusCount.markedForReview}
        </QuestionReviewNotAnswered>
      ),
      label: "Marked for Review",
    },
    {
      icon: (
        <QuestionReviewAnswered>
          {quesStatusCount.answeredAndMarkedReview}
        </QuestionReviewAnswered>
      ),
      label: "Answered & Marked for Review (will be considered for evaluation)",
    },
  ];

  return (
    <div className="Mark_scheme_con">
      {list.map((item, i) => (
        <div
          className="summary-info"
          style={{
            width:
              i === list.length - 1 && list.length % 2 !== 0 ? "100%" : "50%",
          }}
          key={i}
        >
          <div style={{ position: "relative" }}>{item.icon}</div>
          <div
            style={{
              fontSize: "16px",
              margin: "10px",
              color: "#636363",
            }}
          >
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AttemptSummary;
