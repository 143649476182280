import React from "react";
import SolidButton from "../../components/common/SolidButton";
import { useMediaQuery } from "@material-ui/core";
import NoDataImg from "../../assets/img/dashboard_no_data_found.svg";
import { useHistory } from "react-router-dom";
import { ClassesDummyData } from "./dummyData";
import lockedImg from "../../assets/img/lockedImg.svg";
import ClassCard from "./components/class-card";
import HorizontalScroll from "../../components/common/HorizontalScroll";
import Api from "../../common/Api";
import { segmentEvents, dashboardIds } from "../../utils/constants";

const Classes = ({ active, isFree, classes, requestACall }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	const history = useHistory();

	const viewAll = () => {
		Api.trackEvent(segmentEvents.DASHBOARD.CLASSES_VIEWALL);
		history.push({
			pathname: "/student/liveclass/pastliveclass",
			state: { exam_type: 1 },
		});
	};

	return (
		<div className="dashboard_class_container" id={dashboardIds.CLASSES}>
			<div className="dashboard_content_header_container">
				{active && <div className="dashboard_content_active" />}

				<p className="dashboard_content_header">Classes</p>

				<p className="dashboard_content_view_all" onClick={viewAll}>
					See all
				</p>
			</div>
			<div style={{ position: "relative" }}>
				<HorizontalScroll>
					{(isFree ? ClassesDummyData : classes).map((data, i) => {
						return <ClassCard key={i} data={data} />;
					})}
					{!isFree && classes.length > 0 && (
						<div
							onClick={viewAll}
							className="dashboard_view_all_card"
							style={{
								marginLeft: isMobile ? 0 : "2rem",
								marginRight: !isMobile ? 0 : "2rem",
							}}>
							See all
						</div>
					)}
				</HorizontalScroll>
				{!isFree && classes.length === 0 && (
					<div style={{ textAlign: "center", padding: "32px 0" }}>
						<img src={NoDataImg} alt="No Test" />
						<div className="check_later_text1">No Classes available</div>
						<div className="check_later_text2">Check back later</div>
					</div>
				)}

				{isFree && (
					<div className="dashboard_blur">
						<div>
							<img src={lockedImg} alt="" />
							<br />
							Connect with us to unlock classes.
							<br />
							<SolidButton
								small
								style={{
									marginTop: "1rem",
									width: 165,
								}}
								onClick={() => {
									Api.trackEvent(segmentEvents.FREEDASHBOARD.REQUESTCALL_CLASSES);
									requestACall("Class");
								}}>
								Request a call
							</SolidButton>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default React.memo(Classes);
