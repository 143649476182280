import { Dialog } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Api from "../../common/Api";
import ApiUrl from "../../common/ApiUrl";
import { segmentEvents } from "../../utils/constants";
import SolidButton from "../common/SolidButton";
import "./layout.css";

const BatchPopup = ({ open, handleClose, courses, studentData }) => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const currentIndex = React.useRef(0);
	const history = useHistory();

	React.useEffect(() => {
		currentIndex.current = courses.findIndex((c) => c.is_enable);
		if (courses.length > 0) setSelectedIndex(currentIndex.current);
	}, [courses]);

	const switchCourse = () => {
		if (currentIndex.current === selectedIndex) {
			handleClose();
		} else {
			const formdata = new FormData();
			formdata.append("student_id", studentData.id);
			formdata.append("student_course_id", courses[selectedIndex].student_course_id);
			Api.doUpload("POST", formdata, ApiUrl.SWITCH_COURSE)
				.then((response) => {
					if (response.status === true) {
						history.push({
							pathname: "/student",
						});
						document.location.reload();
					} else {
						alert(response.message);
					}
				})
				.catch(() => {
					this.props.history.push("/error");
				});
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: { width: "100%" },
				}}>
				<div className="popup-container-batch">
					<div className="headerFlex">
						<div className="popupHeading">Switch Batch</div>
					</div>

					<div className="batchList">
						{courses.map((item, i) => {
							return (
								<div
									onClick={() => setSelectedIndex(i)}
									className={`batchName ${i === selectedIndex ? "selectedBatch" : ""}`}
									key={i}>
									{item.section_name}
								</div>
							);
						})}
					</div>

					<div className="buttonContainerPopup">
						<SolidButton 
							small 
							style={{ width: 90 }} 
							onClick={() => {
								Api.trackEvent(segmentEvents.SWITCHBATCH.DONE_CLICK);
								switchCourse();
							}}
						>
							Done
						</SolidButton>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default BatchPopup;
