import React, { Suspense, lazy, useEffect } from "react";
import packageJson from "../package.json";
import axios from "axios";
import { ProtectedRoute } from "./protected";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ExamScreen from "./Student/JeeMainsV2";
// import ExamScreen from "./Student/Jeemains/ExamScreen";
import Quizlist from "./Quizlist";
import Dashboard from "./Student/dashboard";

import InstructionPage from "./Student/JeeMainsV2/instructions";
import Loader from "./components/Loader";
import GenericV2ExamScreen from "./Student/genericV2";
import HelpAndSupport from "./Student/helpAndSupport";
// import Api from "./common/Api";
// import ApiUrl from './common/ApiUrl'

import ProfileLocked from "./Student/profile-locked";
import PublicRoute from "./publicRoute";
// import NotFound404 from "./notfound-404";
//Miscllenous

import GenericV2Offline from "./Student/genericV2/offline";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./errorpage";
import { pageTitles } from "./utils/constants";
import { useSelector } from "react-redux";
import { EventPublisher } from "./utils/analytics";

//Miscllenous
// import NotFound404 from "./notfound-404";
// import ZestResults from './Miscllenous/ZestResults.js';
const ZestResults = lazy(() => import("./Miscllenous/ZestResults.js"));
const JeeadvanceInstruction = lazy(() => import("./Student/JeeadvanceIns"));
//Permission

// import Blankpage from './blank';
const Blankpage = lazy(() => import("./blank"));

// import Landing from './landing/landing';
// const Landing = lazy(() => import("./landing/landing"));

// import CustomerURL from './School/feesmodule/customerurl';
const CustomerURL = lazy(() => import("./School/feesmodule/customerurl"));

// import Whoweare from './land/whoweare';
const Whoweare = lazy(() => import("./land/whoweare"));

// const ErrorPage = lazy(() => import("./errorpage"));

//-------------------------------Student module pages

// import ResetPassword from './Student/resetpassword';
// const ResetPassword = lazy(() => import("./Student/resetpassword"));

//feeportal

// import Feeportal from './Student/Feeportal';
const Feeportal = lazy(() => import("./Student/Feeportal"));

// import PayNow from './Student/PayNow';
const PayNow = lazy(() => import("./Student/PayNow"));

// import Installment from './Student/installments';
const Installment = lazy(() => import("./Student/installments"));

//objectivemodule

// import Objectivetest from './Student/objectivetest';
const Objectivetest = lazy(() => import("./Student/objectivetest"));

// import ObjectiveExamInstruction from './Student/objectiveexaminstruction';
const ObjectiveExamInstruction = lazy(() => import("./Student/objectiveexaminstruction"));

// import Objectiveexam from './Student/objectiveexam';
const Objectiveexam = lazy(() => import("./Student/objectiveexam"));

// import ObjectiveExamComplete from './Student/objectiveexamcomplete.js'
const ObjectiveExamComplete = lazy(() => import("./Student/objectiveexamcomplete.js"));

//objectiveexamresult

// import PercentileAndScore from './Student/objective result/PercentileAndScore.js';
const PercentileAndScore = lazy(() => import("./Student/objective result/PercentileAndScore.js"));

// import DifficultyLevelWiseScore from './Student/objective result/DifficultyLevelWiseScore.js';
const DifficultyLevelWiseScore = lazy(() => import("./Student/objective result/DifficultyLevelWiseScore.js"));

// import TimePerQuestion from './Student/objective result/TimePerQuestion.js';
const TimePerQuestion = lazy(() => import("./Student/objective result/TimePerQuestion.js"));

// import SolutionsObjectiveResult from './Student/objective result/Solutions.js';
const SolutionsObjectiveResult = lazy(() => import("./Student/objective result/Solutions.js"));

// import ChapterWiseScore from './Student/objective result/ChapterWiseScore.js';
const ChapterWiseScore = lazy(() => import("./Student/objective result/ChapterWiseScore.js"));

//liveclass

// import PastLiveClass from './Student/live class/PastLiveClass.js';
const PastLiveClass = lazy(() => import("./Student/live class/PastLiveClass.js"));

// import UpcomingLiveClass from './Student/live class/UpcomingLiveClass.js';
const UpcomingLiveClass = lazy(() => import("./Student/live class/UpcomingLiveClass.js"));

// import PastLiveClassStudent from './Student/pastrecordings/PastLiveClass';
const PastLiveClassStudent = lazy(() => import("./Student/pastrecordings/PastLiveClass"));

//assignment module

// import StudentAssignment from './Student/studentassignment';
const StudentAssignment = lazy(() => import("./Student/studentassignment"));

// import AssignmentSubmit from './Student/AssignmentSubmit';
const AssignmentSubmit = lazy(() => import("./Student/AssignmentSubmit"));

// import SubmittedAssignment from './Student/SubmitedAssignment';
const SubmittedAssignment = lazy(() => import("./Student/SubmitedAssignment"));

// import UpdateSubmittedAssignment from './Student/UpdateSubmittedAssignment';
const UpdateSubmittedAssignment = lazy(() => import("./Student/UpdateSubmittedAssignment"));

//video module

// import VideoLectures from './Student/videomodule';
const VideoLectures = lazy(() => import("./Student/videomodule"));

// import SubjectVideoLands from './Student/subjectvideosland';
const SubjectVideoLands = lazy(() => import("./Student/subjectvideosland"));

// import VideoPlayer from './Student/videoplayer';
const VideoPlayer = lazy(() => import("./Student/videoplayer"));

//doubtmodule

// import StudentDoubt from './Student/doubtmodule/studentdoubt';
const StudentDoubt = lazy(() => import("./Student/doubtmodule/studentdoubt"));

// import DoubtSolution from './Student/doubtmodule/doubtsolution';
const DoubtSolution = lazy(() => import("./Student/doubtmodule/doubtsolution"));

// import UploadDoubtStudent from './Student/doubtmodule/UploadDoubt.js';
const UploadDoubtStudent = lazy(() => import("./Student/doubtmodule/UploadDoubt.js"));

// import ListOfChaptersStudentDoubt from './Student/doubtmodule/ListOfChapters.js';
const ListOfChaptersStudentDoubt = lazy(() => import("./Student/doubtmodule/ListOfChapters.js"));

// import ListOfQuestionsStudentDoubt from './Student/doubtmodule/ListOfQuestions.js';
const ListOfQuestionsStudentDoubt = lazy(() => import("./Student/doubtmodule/ListOfQuestions.js"));

// import QuestionDetailsStudentDoubt from './Student/doubtmodule/QuestionDetails.js'
const QuestionDetailsStudentDoubt = lazy(() => import("./Student/doubtmodule/QuestionDetails.js"));

// import PendingDoubts from './Student/doubtmodule/PendingDoubts.js';
const PendingDoubts = lazy(() => import("./Student/doubtmodule/PendingDoubts.js"));

// import SearchQuestion from './Student/doubtmodule/SearchQuestion';
const SearchQuestion = lazy(() => import("./Student/doubtmodule/SearchQuestion"));

//notes

// import NotesSubjects from './Student/notes/NotesSubjects.js';
const NotesSubjects = lazy(() => import("./Student/notes/NotesSubjects.js"));

// import NotesChapters from './Student/notes/NotesChapters.js';
const NotesChapters = lazy(() => import("./Student/notes/NotesChapters.js"));

// import ListOfNotes from './Student/notes/ListOfNotes.js';
const ListOfNotes = lazy(() => import("./Student/notes/ListOfNotes.js"));

// import ReadNotes from './Student/readnotes';
const ReadNotes = lazy(() => import("./Student/readnotes"));

// import ReadSubjectNotes from './Student/readsubjectnotes';
const ReadSubjectNotes = lazy(() => import("./Student/readsubjectnotes"));

// import NoteViewer from './Student/noteviewer';
const NoteViewer = lazy(() => import("./Student/noteviewer"));

// import Notifications from './Student/notes/Notifications';
const Notifications = lazy(() => import("./Student/notes/Notifications"));

//profile

// import Profile from './Student/profile/Profile.js';
const Profile = lazy(() => import("./Student/profile/Profile.js"));

// import ChangePassword from './Student/profile/ChangePassword.js';
const ChangePassword = lazy(() => import("./Student/profile/ChangePassword.js"));
const EditParent = lazy(() => import("./Student/profile/EditParent.js"));
//feemodule

// import InstallmentPageStudent from './Student/feemodule/InstallmentPage.js';
const InstallmentPageStudent = lazy(() => import("./Student/feemodule/InstallmentPage.js"));

// import PaymentStudent from './Student/feemodule/Payment.js';
const PaymentStudent = lazy(() => import("./Student/feemodule/Payment.js"));

// import PaymentStatusStudent from './Student/feemodule/PaymentStatus.js';
const PaymentStatusStudent = lazy(() => import("./Student/feemodule/PaymentStatus.js"));

// import Feesummary from './Student/Feesummary';
const Feesummary = lazy(() => import("./Student/Feesummary"));

// import jeepage from './landing/Jeepage';
const jeepage = lazy(() => import("./landing/Jeepage"));

// import Myschedule from './Student/Myschedule';
const Myschedule = lazy(() => import("./Student/Myschedule"));

// import AssignmentsList_student from '../src/Student/assignments/AssignmentsList_Student';
// const AssignmentsList_student = lazy(() =>
//   import("../src/Student/assignments/AssignmentsList_Student")
// );

// import AssignmetsSubjects from '../src/Student/assignments/AssignmentsSubjects'
const AssignmetsSubjects = lazy(() => import("../src/Student/assignments/AssignmentsSubjects"));

// import AssignmentsChapters from '../src/Student/assignments/AssignmentsChapters'
const AssignmentsChapters = lazy(() => import("../src/Student/assignments/AssignmentsChapters"));

// import Payment_successPage from './components/Payment_successPage';
const Payment_successPage = lazy(() => import("./components/Payment_successPage"));

// import StudyMaterialSubject from './Student/StudyMaterial/StudyMaterialSubject';
const StudyMaterialSubject = lazy(() => import("./Student/StudyMaterial/StudyMaterialSubject"));

// import StudyMaterial_List from './Student/StudyMaterial/StudyMaterial_List';
const StudyMaterial_List = lazy(() => import("./Student/StudyMaterial/StudyMaterial_List"));

// import StudentObjectiveTest from './Student/StudentObjectiveTest';
const StudentObjectiveTest = lazy(() => import("./Student/StudentObjectiveTest"));

// import QuestionPaper from './Student/objective result/DownloadQues';
const QuestionPaper = lazy(() => import("./Student/objective result/DownloadQues"));

// import AssignmentsList_student from './Student/assignments/AssignmentsList_Student';
// const AssignmentsList_student = lazy(() => import('./Student/assignments/AssignmentsList_Student'));

// import AssignmentsSubjects from './Student/assignments/AssignmentsSubjects';
// const AssignmentsSubjects = lazy(() => import('./Student/assignments/AssignmentsSubjects'));

// import AssignmentsChapters from './Student/assignments/AssignmentsChapters';
// const AssignmentsChapters = lazy(() => import('./Student/assignments/AssignmentsChapters'));

const JeeAdvanceScreen = lazy(() => import("./Student/jeeAdvancedV2"));
const Instruction = lazy(() => import("./Student/jeeAdvancedV2/instructions"));
// const JEEAdvancedV2Instructions = lazy(() => import('./Student/jeeAdvancedV2/instructions'))
const ImportantInstruction = lazy(() => import("./Student/jeeAdvancedV2/important-instructions"));

const AssignmentsList = lazy(() => import("../src/Student/assignments/AssignmentsList"));

const AssignmentsAllList = lazy(() => import("../src/Student/assignments/AssignmentsAllList"));

// const InstructionsGenericDpp = lazy(() => import("./Student/assignments/genericDpp/InstructionsGenericDpp"));
const InstructionsGenericSecondDpp = lazy(() => import("./Student/assignments/genericDpp/InstructionsGenericSecondDpp"));
// const ExamScreenGenericDpp = lazy(() => import("./Student/assignments/genericDpp/ExamScreenGenericDpp"));

//Past Class Subject-Wise
const LiveClassSubjects = lazy(() => import("./Student/pastclasses/LiveClassSubjects.js"));

const LiveClassChapters = lazy(() => import("./Student/pastclasses/LiveClassChapters.js"));

const Adaptive = lazy(() => import("./Student/adaptive/AdaptiveScreen"));
const PracticeSession = lazy(() => import("./Student/adaptive/practice-session"));
const SessionReport = lazy(() => import("./Student/adaptive/session-report"));

// const InstructionsGeneric = lazy(() =>
//   import("./Student/Generic/InstructionsGeneric")
// );
const InstructionsGenericSecond = lazy(() => import("./Student/Generic/InstructionsGenericSecond"));
// const ExamScreenGeneric = lazy(() =>
//   import("./Student/Generic/examScreenGeneric")
// );

const SubmitSuccessfully = lazy(() => import("./Student/JeeMainsV2/submit-successful"));

// Generic V2
const GenericV2Instructions = lazy(() => import("./Student/genericV2/instructions"));

// DPP LISTING V2
const DppListing = lazy(() => import("./Student/assignments/dppListing"));

const Signup = lazy(() => import("./components/auth"));
const Signin = lazy(() => import("./components/auth/signin"));
const ForgotPassword = lazy(() => import("./components/auth/forgot-password"));
const Attendance = lazy(() => import("./Student/attendance"));

const ExamResult = lazy(() => import("./Student/ExamResult/index"));
const AnswerKey = lazy(() => import("./Student/ExamResult/AnswerKey/index"));
const DppResult = lazy(() => import("./Student/ExamResult/DppResult/index"));

const NCERTCorner = lazy(() => import("./Student/NCERT/NcertAll"));
const JEECorner = lazy(() => import("./Student/JEECorner/JEEAll"));
const NEETCorner = lazy(() => import("./Student/NEETCorner/NEETAll"));
const ChapterPage = lazy(() => import("./Student/chapter"));
const QuestionPage = lazy(() => import("./Student/question-page"));

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 12 }}>
					<p>̇Something went wrong, please refresh</p>

					<a href="/student" className="btn btn-primary mt-5">
						Go Home
					</a>
				</div>
			);
		}

		return <div>{this.props.children}</div>;
	}
}

const App = () => {
	const studentDetails = useSelector((state) => state.StudentDetail.StudentDetail);
	const analyticsInitiated = React.useRef(false);
	useEffect(() => {
		if (!analyticsInitiated.current && studentDetails.student_data) {
			new EventPublisher(studentDetails);
			analyticsInitiated.current = true;
		}
	}, [studentDetails]);

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_BASE_URL + `support/get-version/?platform=akmc_web`)
			.then((data) => {
				if (data) {
					try {
						if (data.data.data.current_version !== packageJson.version) {
							caches.keys().then((names) => {
								names.forEach((name) => {
									caches.delete(name);
								});
							});
							window.location.reload(true);
						}
					} catch (err) {
						console.log(err);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});

		window.onstorage = (event) => {
			if (!event) return;

			let authChange = false;

			if (event.key === "userdetail") {
				// on localStorage.removeItem('userdetails')
				authChange = true;
			} else if (event.key === null && event.newValue === null) {
				// on localStorage.clear()
				authChange = true;
			}

			if (authChange) {
				window.location.href = "/";
			}
		};

		return () => {
			window.onstorage = null;
		};
	}, []);

	return (
		<ErrorBoundary>
			{/* <Online> */}
			<BrowserRouter>
				<Suspense
					fallback={
						<div
							style={{
								flex: 1,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100vh",
								flexDirection: "column",
							}}>
							<Loader />
							<h3>Loading... please wait.</h3>
						</div>
					}>
					<Switch>
						<PublicRoute exact path="/" component={Signin} title={pageTitles.SIGNIN} />
						<PublicRoute exact path="/signin" component={Signin} title={pageTitles.SIGNIN} />
						<PublicRoute exact path="/signup" component={Signup} title={pageTitles.SIGNUP} />
						<PublicRoute
							exact
							path="/forgot-password"
							component={ForgotPassword}
							title={pageTitles.FORGOT_PASSWORD}
						/>

						<PublicRoute exact path="/jee_advance_crash_course" component={jeepage} value="Landing" />
						<PublicRoute exact path="/error" component={ErrorPage} />
						<PublicRoute exact path="/whoweare" component={Whoweare} />
						<PublicRoute exact path="/blankpage" component={Blankpage} />
						<PublicRoute exact path="/parentpayment/:orderid" component={CustomerURL} />
						<PublicRoute exact path="/izest/result" component={ZestResults} />
						<PublicRoute exact path="/pastrecordings/:student_id" component={PastLiveClassStudent} />

						{/* <PublicRoute exact path="/layout" component={Student} value="Dashboard" /> */}
						{/* <PublicRoute exact path="/login" component={Login} /> */}
						{/* <PublicRoute exact path="/forgotPassword" component={Mobile} /> */}
						{/* <PublicRoute exact path="/ChoosePassword" component={Password} /> */}

						<ProtectedRoute
							exact
							path="/student/doubtmodule/SearchQuestion"
							component={SearchQuestion}
							value="Student"
							title={pageTitles.SOLUTIONS}
						/>
						<ProtectedRoute
							exact
							path="/student/notes/Notifications"
							component={Notifications}
							value="Student"
							title={pageTitles.NOTIFICATIONS}
						/>
						<ProtectedRoute
							exact
							path="/student"
							component={Dashboard}
							value="Student"
							title={pageTitles.DASHBOARD}
						/>
						<ProtectedRoute
							exact
							path="/student/attendance"
							component={Attendance}
							value="Student"
							title={pageTitles.ATTENDANCE}
						/>
						<ProtectedRoute exact path="/student/assignment" component={StudentAssignment} value="Student" />
						<ProtectedRoute exact path="/student/submitassignment" component={AssignmentSubmit} value="Student" />
						<ProtectedRoute
							exact
							path="/student/submittedassignment"
							component={SubmittedAssignment}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/updateassignment"
							component={UpdateSubmittedAssignment}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/videolectures" component={VideoLectures} value="Student" />
						<ProtectedRoute exact path="/student/subjectvideos" component={SubjectVideoLands} value="Student" />
						<ProtectedRoute exact path="/student/videoplayer" component={VideoPlayer} value="Student" />
						<ProtectedRoute exact path="/student/readsubjectnotes" component={ReadSubjectNotes} value="Student" />
						<ProtectedRoute exact path="/student/readnotes" component={ReadNotes} value="Student" />
						<ProtectedRoute exact path="/student/notes" component={NoteViewer} value="Student" />
						<ProtectedRoute exact path="/student/askyourdoubt" component={StudentDoubt} value="Student" />
						<ProtectedRoute exact path="/student/doubtsolutions" component={DoubtSolution} value="Student" />
						<ProtectedRoute
							exact
							path="/student/doubtmodule/uploaddoubt"
							component={UploadDoubtStudent}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/doubtmodule/chapterlist"
							component={ListOfChaptersStudentDoubt}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/doubtmodule/questionlist"
							component={ListOfQuestionsStudentDoubt}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/doubtmodule/questiondetails"
							component={QuestionDetailsStudentDoubt}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/doubtmodule/pendingdoubts"
							component={PendingDoubts}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectivetest"
							component={Objectivetest}
							value="Student"
							title={pageTitles.TESTS}
						/>
						<ProtectedRoute
							exact
							path="/student/JeeExamScreen"
							component={ExamScreen}
							value="Student"
							title={pageTitles.JEE_EXAM}
						/>
						<ProtectedRoute exact path="/student/JeeAdvanceExamScreen" component={JeeAdvanceScreen} value="Student" />
						<ProtectedRoute exact path="/student/Quizlist" component={Quizlist} value="Student" />
						<ProtectedRoute exact path="/student/objectivetest" component={Objectivetest} value="Student" />
						<ProtectedRoute exact path="/student/JeeadvanceIns" component={JeeadvanceInstruction} value="Student" />
						<ProtectedRoute
							exact
							path="/student/InstructionPage"
							component={InstructionPage}
							value="Student"
							title={pageTitles.JEE_INSTRUCTIONS}
						/>
						<ProtectedRoute path="/student/generic/:examId" component={GenericV2Offline} value="Student" skipSetTitle />
						<ProtectedRoute
							exact
							path="/student/InstructionsGeneric"
							component={GenericV2Instructions}
							value="Student"
							title={pageTitles.GENERIC_INSTRUCTIONS}
						/>
						<ProtectedRoute
							exact
							path="/student/InstructionsGenericSecond"
							component={InstructionsGenericSecond}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/ExamScreenGeneric"
							component={GenericV2ExamScreen}
							value="Student"
							title={pageTitles.GENERIC_EXAM}
						/>
						<ProtectedRoute
							exact
							path="/student/SubmitSuccessfully"
							component={SubmitSuccessfully}
							value="Student"
							title={pageTitles.EXAM_SUBMITTED}
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveexaminstruction"
							component={ObjectiveExamInstruction}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/objectiveexam" component={Objectiveexam} value="Student" />
						<ProtectedRoute
							exact
							path="/student/StudentObjectiveTest"
							component={StudentObjectiveTest}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/instruction" component={Instruction} value="Student" />
						<ProtectedRoute
							exact
							path="/student/important-instruction"
							component={ImportantInstruction}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveexamcomplete"
							component={ObjectiveExamComplete}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/percentileandscore"
							component={PercentileAndScore}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/difficultylevelwisescore"
							component={DifficultyLevelWiseScore}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/timeperquestion"
							component={TimePerQuestion}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/solutions"
							component={SolutionsObjectiveResult}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/chapterwisescore"
							component={ChapterWiseScore}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/objectiveresult/questionpaper"
							component={QuestionPaper}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/liveclass/pastliveclass"
							component={PastLiveClass}
							value="Student"
							title={pageTitles.PAST_LIVE_CLASSES}
						/>
						<ProtectedRoute
							exact
							path="/student/liveclass/upcomingliveclass"
							component={UpcomingLiveClass}
							value="Student"
							title={pageTitles.UPCOMING_LIVE_CLASSES}
						/>
						<ProtectedRoute exact path="/student/notes/notessubjects" component={NotesSubjects} value="Student" />
						<ProtectedRoute exact path="/student/notes/noteschapters" component={NotesChapters} value="Student" />
						<ProtectedRoute exact path="/student/notes/noteslist" component={ListOfNotes} value="Student" />
						<ProtectedRoute exact path="/student/payment" component={Feeportal} value="Student" />
						<ProtectedRoute exact path="/student/payment/installment" component={Installment} value="Student" />
						<ProtectedRoute exact path="/student/payment/paynow" component={PayNow} value="Student" />
						<ProtectedRoute
							exact
							path="/student/profile"
							component={Profile}
							value="Student"
							title={pageTitles.PROFILE}
						/>
						<ProtectedRoute exact path="/student/feesummary" component={Feesummary} value="Student" />
						<ProtectedRoute
							exact
							path="/student/changepassword"
							component={ChangePassword}
							value="Student"
							title={pageTitles.CHANGE_PASSWORD}
						/>
						<ProtectedRoute
							exact
							path="/student/editparent"
							component={EditParent}
							value="Student"
							title={pageTitles.EDIT_PARENT}
						/>
						<ProtectedRoute
							exact
							path="/student/feemodule/installmentpage"
							component={InstallmentPageStudent}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/feemodule/payment" component={PaymentStudent} value="Student" />
						<ProtectedRoute
							exact
							path="/student/feemodule/paymentstatus/:status"
							component={PaymentStatusStudent}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/myschedule"
							component={Myschedule}
							value="Student"
							title={pageTitles.SCHEDULE}
						/>
						<ProtectedRoute exact path="/student/assignmentslist" component={AssignmentsList} value="Student" />
						<ProtectedRoute
							exact
							path="/student/assignmentssubjects"
							component={AssignmetsSubjects}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/assignmentschapters"
							component={AssignmentsChapters}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/PaymentSuccess" component={Payment_successPage} value="Student" />
						<ProtectedRoute
							exact
							path="/student/StudyMaterialSubject"
							component={StudyMaterialSubject}
							value="Student"
						/>
						<ProtectedRoute exact path="/student/StudyMaterialList" component={StudyMaterial_List} value="Student" />

						<ProtectedRoute
							exact
							path="/student/pastclasses/subjects"
							component={LiveClassSubjects}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/pastclasses/chapters"
							component={LiveClassChapters}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/InstructionsGenericDpp"
							component={(props) => <GenericV2Instructions {...props} isDPP />}
							value="Student"
							title={pageTitles.MPP_INSTRUCTIONS}
						/>
						<ProtectedRoute
							exact
							path="/student/InstructionsGenericSecondDpp"
							component={InstructionsGenericSecondDpp}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/ExamScreenGenericDpp"
							component={(props) => <GenericV2ExamScreen {...props} isDPP />}
							value="Student"
							title={pageTitles.MPP_EXAM}
						/>
						<ProtectedRoute
							exact
							path="/student/assignments_all_list"
							component={AssignmentsAllList}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/important-instruction/:exam_id"
							component={ImportantInstruction}
							value="Student"
							title={pageTitles.JEE_ADV_IMP_INSTRUCTIONS}
						/>
						<ProtectedRoute
							exact
							path="/student/JeeAdvanceExamScreen/:exam_id"
							component={JeeAdvanceScreen}
							value="Student"
							title={pageTitles.JEE_ADV_EXAM}
						/>
						<ProtectedRoute
							exact
							path="/student/instruction/:exam_id"
							component={Instruction}
							value="Student"
							title={pageTitles.JEE_ADV_INSTRUCTIONS}
						/>
						<ProtectedRoute exact path="/student/adaptive" component={Adaptive} value="Student" />
						<ProtectedRoute exact path="/student/practice-session" component={PracticeSession} value="Student" />
						<ProtectedRoute
							exact
							path="/student/session-report/:session_id"
							component={SessionReport}
							value="Student"
						/>
						<ProtectedRoute
							exact
							path="/student/help-and-support"
							component={HelpAndSupport}
							value="Student"
							title={pageTitles.HELP_SUPPORT}
						/>
						<ProtectedRoute
							exact
							path="/student/dpp-list/all"
							component={DppListing}
							value="Student"
							title={pageTitles.DPP_LISTING}
						/>
						<ProtectedRoute
							exact
							path="/student/result/:examId"
							component={ExamResult}
							value="Student"
							title={pageTitles.EXAM_RESULT}
						/>
						<ProtectedRoute
							exact
							path="/student/answer/:examId"
							component={AnswerKey}
							value="Student"
							title={pageTitles.EXAM_ANSWERS}
						/>
						<ProtectedRoute
							exact
							path="/student/dpp-result/:examId"
							component={DppResult}
							value="Student"
							title={pageTitles.DPP_RESULT}
						/>

						<ProtectedRoute
							exact
							path="/student/ncert"
							component={NCERTCorner}
							value="Student"
							title={pageTitles.NCERT_CORNER}
						/>
						<ProtectedRoute
							exact
							path="/student/jee"
							component={JEECorner}
							value="Student"
							title={pageTitles.JEE_CORNER}
						/>
						<ProtectedRoute
							exact
							path="/student/neet"
							component={NEETCorner}
							value="Student"
							title={pageTitles.NEET_CORNER}
						/>
						<ProtectedRoute
							exact
							path="/student/:type/:chapterId/:videoId"
							component={ChapterPage}
							value="Student"
							skipSetTitle
						/>
						<ProtectedRoute
							exact
							path="/student/ncert/:chapterId/:exerciseId/question/:questionId"
							component={QuestionPage}
							value="Student"
							title={pageTitles.NCERT_EXERCISE_QUESTIONS}
						/>
						<Route exact path="/student/locked" component={ProfileLocked} title={pageTitles.PROFILE_LOCKED} />
						<Route exact path="*" component={ErrorPage} />
					</Switch>
				</Suspense>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

export default App;
