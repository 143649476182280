import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import HorizontalScroll from "../common/HorizontalScroll";
import Styles from "./CommonCard.module.css";

const ImageCard = ({ url, index, onClick, videoName, noLeftPadding }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");
	return (
		<div
			style={{
				...(noLeftPadding
					? { marginLeft: isMobile ? 0 : index === 0 ? 0 : "2rem" }
					: { paddingLeft: isMobile ? 0 : "2rem" }),
				paddingRight: !isMobile ? 0 : "2rem",
				cursor: "pointer",
			}}
			className={Styles.video_div}
			onClick={onClick}>
			{!url ? (
				<div className={Styles.no_image_div}>{videoName}</div>
			) : (
				<img className={Styles.image_div} src={url} alt="lecture" />
			)}
			<span className={Styles.text + " " + Styles.small}>{videoName}</span>
		</div>
	);
};

const VideoGallery = ({ videoList, type, chapterId, subjectData, noLeftPadding, isHome }) => {
	const { push } = useHistory();
	const isMobile = useMediaQuery("(max-width: 991px)");
	return (
		<div className={Styles.mb_1}>
			<HorizontalScroll>
				{videoList.map((video, i) => {
					return (
						<ImageCard
							url={video.video_icon_url}
							index={i}
							key={i}
							id={video.video_id}
							onClick={() => {
								push({
									pathname: `/student/${type}/${chapterId}/${video.video_id}`,
								});
							}}
							videoName={video.video_name}
							noLeftPadding={noLeftPadding}
						/>
					);
				})}
				{videoList.length >= 5 && isHome && (
					<div>
						<div
							onClick={() => {
								push({
									pathname: `/student/${type}`,
									state: {
										subjectId: subjectData.subject.id,
										classId: subjectData.subject.class_assoc,
									},
								});
							}}
							className={Styles.seeAll_Card}
							style={{
								marginLeft: isMobile ? 0 : "2rem",
								marginRight: !isMobile ? 0 : "2rem",
							}}>
							See all
						</div>
						<div className={Styles.text + " " + Styles.small}>&nbsp;</div>
					</div>
				)}
			</HorizontalScroll>
		</div>
	);
};

export default VideoGallery;
