import React from "react";
import Logo from "../landing/img/myclassroom-logo.svg";
import "./JeemainHeader.css";
const JeemainHeader = () => {
  return (
    <div className="con-top">
      <img src={Logo} alt="logo" id={{ marginVertical: 10 }} width="190px" />
    </div>
  );
};

export default JeemainHeader;
