import React from "react";
import parse from "html-react-parser";
import { parserConfig } from "./utils/misc";

function GenericV2QuestionMultipleChoice({ currentQuestion = null, handleCheckOption, isDPP = false }) {
	if (!currentQuestion) return null;

	return (
		<div className="exam-questions">
			<div className="question-text">
				Q{currentQuestion.serial_no}: {parse(currentQuestion.question_bank_assoc.question)}
			</div>

			{isDPP && currentQuestion?.question_bank_assoc?.tags?.length > 0 && (
				<div className="question-tag-wrapper">
					{currentQuestion.question_bank_assoc.tags.map((item, index) => {
						return (
							<span key={index} className="question-tag">
								{item.tag_name}
							</span>
						);
					})}
				</div>
			)}

			<div className="options">
				<div className={currentQuestion.is_option1_selected ? "boxed2" : "boxed"}>
					<label htmlFor="optA">
						<input
							type="checkbox"
							id="optA"
							name="optA"
							value="optA"
							checked={currentQuestion.is_option1_selected}
							onChange={handleCheckOption(1)}
						/>{" "}
						&nbsp; A. &nbsp; {parse(currentQuestion.question_bank_assoc.option1, parserConfig)}
					</label>
				</div>
				<div className={currentQuestion.is_option2_selected ? "boxed2" : "boxed"}>
					<label htmlFor="optB">
						<input
							type="checkbox"
							id="optB"
							name="optB"
							value="optB"
							checked={currentQuestion.is_option2_selected}
							onChange={handleCheckOption(2)}
						/>{" "}
						&nbsp; B. &nbsp; {parse(currentQuestion.question_bank_assoc.option2, parserConfig)}
					</label>
				</div>
				<div className={currentQuestion.is_option3_selected ? "boxed2" : "boxed"}>
					<label htmlFor="optC">
						<input
							type="checkbox"
							id="optC"
							name="optC"
							value="optC"
							checked={currentQuestion.is_option3_selected}
							onChange={handleCheckOption(3)}
						/>{" "}
						&nbsp; C. &nbsp; {parse(currentQuestion.question_bank_assoc.option3, parserConfig)}
					</label>
				</div>
				<div className={currentQuestion.is_option4_selected ? "boxed2" : "boxed"}>
					<label htmlFor="optD">
						<input
							type="checkbox"
							id="optD"
							name="optD"
							value="optD"
							checked={currentQuestion.is_option4_selected}
							onChange={handleCheckOption(4)}
						/>{" "}
						&nbsp; D. &nbsp; {parse(currentQuestion.question_bank_assoc.option4, parserConfig)}
					</label>
				</div>
			</div>
		</div>
	);
}

export default GenericV2QuestionMultipleChoice;
