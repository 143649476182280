import React from "react";

const LeftChevron = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "10px" }}
    >
      <path
        d="M0.000473022 8.00372C-0.000540733 7.82846 0.0330524 7.65471 0.0993261 7.49246C0.1656 7.3302 0.263252 7.18262 0.38668 7.05818L7.0454 0.399467C7.16956 0.275297 7.31698 0.176801 7.47921 0.109601C7.64145 0.0424004 7.81533 0.0078125 7.99093 0.0078125C8.34558 0.0078125 8.6857 0.148695 8.93647 0.399467C9.18724 0.650239 9.32812 0.99036 9.32812 1.34501C9.32812 1.69965 9.18724 2.03977 8.93647 2.29054L3.20997 8.00372L8.93647 13.7169C9.18724 13.9677 9.32812 14.3078 9.32813 14.6624C9.32813 15.0171 9.18724 15.3572 8.93647 15.608C8.6857 15.8587 8.34558 15.9996 7.99093 15.9996C7.63629 15.9996 7.29617 15.8587 7.0454 15.608L0.38668 8.94926C0.263252 8.82482 0.1656 8.67724 0.0993261 8.51499C0.0330524 8.35273 -0.000540733 8.17899 0.000473022 8.00372Z"
        fill="#575757"
      />
      <path
        d="M7.98875 8.00372C7.98774 7.82846 8.02133 7.65471 8.08761 7.49246C8.15388 7.3302 8.25153 7.18262 8.37496 7.05818L15.0337 0.399467C15.2844 0.148695 15.6246 0.0078125 15.9792 0.0078125C16.3339 0.0078125 16.674 0.148695 16.9248 0.399467C17.1755 0.650239 17.3164 0.99036 17.3164 1.34501C17.3164 1.69965 17.1755 2.03977 16.9248 2.29054L11.1983 8.00372L16.9248 13.7169C17.1755 13.9677 17.3164 14.3078 17.3164 14.6624C17.3164 15.0171 17.1755 15.3572 16.9248 15.608C16.674 15.8587 16.3339 15.9996 15.9792 15.9996C15.6246 15.9996 15.2844 15.8587 15.0337 15.608L8.37496 8.94926C8.25153 8.82482 8.15388 8.67724 8.08761 8.51499C8.02133 8.35273 7.98774 8.17899 7.98875 8.00372Z"
        fill="#575757"
      />
    </svg>
  );
};

export default LeftChevron;
