import { combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import studentDetailReducer from "./studentDetailReducer";
import chapterReducer from "./chapterReducer";
import noteReducer from "./noteReducer";
import jeeAdvanceExamDetailReducer from "./jeeAdvanceExamDetailReducer";
import jeeAdvanceExamQuestionReducer from "./jeeAdvanceExamQuestions";
import authReducer from "./authReducer";
import signupReducer from "./signupReducer";
import dashboardReducer from "./dashboardReducer";
import cornerReducer from "./cornerReducer";

const mainReducer = combineReducers({
	Notification: notificationReducer,
	StudentDetail: studentDetailReducer,
	ChapterList: chapterReducer,
	NoteList: noteReducer,
	JeeAdvanceExam: jeeAdvanceExamDetailReducer,
	JeeAdvanceQuestion: jeeAdvanceExamQuestionReducer,
	auth: authReducer,
	signup: signupReducer,
	dashboard: dashboardReducer,
	corner: cornerReducer,
});

export default mainReducer;
