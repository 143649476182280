import React from "react";
import { useMediaQuery } from "@material-ui/core";
import JoinImg from "../../../assets/img/class_join.svg";
import RepeatImg from "../../../assets/img/class_repeat.svg";
import NotesImg from "../../../assets/img/class_notes.svg";
import InfoImg from "../../../assets/img/class_info.svg";
import SolidButton from "../../../components/common/SolidButton";
import dateFormat from "dateformat";
import { secondsToDuration } from "../../../utils/helperFunctions";
import { Tooltip } from "antd";
import Api from "../../../common/Api";
import { segmentEvents } from "../../../utils/constants";
import { months } from "../../../utils/constants";

const getList = (arr, idx) => {
	const arrClone = [...arr];
	const lastPart = arrClone.splice(arr.length + 1 - idx, arr.length);
	return [arrClone, lastPart];
};

const ClassCard = ({ data }) => {
	const isMobile = useMediaQuery("(max-width: 991px)");

	const startDate = new Date(data.start_date);
	const endDate = new Date(data.end_date);
	const isLive = data.type === "live";
	const tagsRef = React.useRef(null);
	const [extraCatCount, setExtraCatCount] = React.useState(0);
	const [mainList, overflowList] = getList(Array.isArray(data.tags) ? data.tags : [], extraCatCount);

	React.useEffect(() => {
		const heightBreakpoint = 50;
		if (tagsRef?.current?.offsetHeight > heightBreakpoint) {
			setExtraCatCount((num) => num + 1);
		}
	}, [extraCatCount, mainList]);

	return (
		<div
			style={{
				paddingLeft: isMobile ? 0 : "2rem",
				paddingRight: !isMobile ? 0 : "2rem",
				scrollSnapAlign: "start",
			}}>
			<div className="dashboard_class_card">
				<div className="class_card_faculty_wrapper">
					<div
						className="class_card_status"
						style={{
							"--class_card_status": "#F5EADB",
						}}>
						<p
							className="class_card_status_text"
							style={{
								"--class_card_status_text": " #454545",
							}}>
							{data.class_type}
						</p>
					</div>

					<div className="class_faculty_wrapper">
						<div className="class_faculty_info_container">
							<p className="class_card_faculty_name">{data.faculty_name}</p>

							<div className="class_card_faculty_divider" />

							<div className="class_card_time_wrapper">
								<p className="class_card_time_text1">
									{months[startDate.getMonth()]} {startDate.getDate()}, {startDate.getFullYear()}
								</p>

								<div className="class_card_time_text2">{dateFormat(startDate, "hh:MM TT")}</div>
							</div>
						</div>

						<div className="class_faculty_img_container">
							<img
								src={data.faculty_thumbnail}
								alt="faculty"
								style={{
									width: "calc(100% - 1rem)",
									height: "100%",
									objectFit: "contain",
								}}
							/>
						</div>
					</div>
				</div>

				<div className="class_card_details_wrapper">
					<div>
						<div className="class_card_subject_wrapper">
							<p
								className="class_card_subject"
								style={{
									"--class_card_subject_bg": data.subject_primary_color,
								}}>
								{data.subject_name}
							</p>

							{data.end_date && (
								<p className="class_card_duration">
									{secondsToDuration(Math.abs(endDate.getTime() - startDate.getTime()) / 1000)}
								</p>
							)}
						</div>

						<p className="class_card_topic_text">{data.chapter_name}</p>

						<p className="class_card_lecture_text">Lecture - {data.lecture}</p>

						<div ref={tagsRef} className="mpp_tag_wrapper">
							{mainList.map((tag, i) => {
								return (
									<div key={`tag-${i}`} className="d-flex align-items-center">
										{i !== 0 && <div className="mpp_tag_seperator" />}
										<span className="mpp_card_tags">{tag}</span>
									</div>
								);
							})}
							{overflowList.length > 0 && (
								<div className="d-flex align-items-center">
									<div className="mpp_tag_seperator" />
									<Tooltip title={overflowList.map((tag) => tag).join(", ")} placement="bottom">
										<span className="test_chapters_more">+{overflowList.length}</span>
									</Tooltip>
								</div>
							)}
						</div>
					</div>
					<div>
						<div className="class_button_wrapper">
							{isLive ? (
								<div />
							) : (
								<div
									onClick={() => {
										if (data.notes_file) {
											Api.trackEvent(segmentEvents.DASHBOARD.CLASSES_NOTES);
											window.open(data.notes_file, "_blank");
										}
									}}
									className={`class_notes_wrapper ${data.notes_file ? "" : "class_notes_disabled"}`}>
									<img src={NotesImg} alt="notes" />

									<p className="class_notes_text">Notes</p>
								</div>
							)}

							<SolidButton
								small
								style={{
									height: 32,
									padding: "0 1.2rem",
									minWidth: 90,
									width: "fit-content",
									marginTop: 0,
								}}
								disabled={isLive ? !data.live_class_link : !data.replay_url}
								onClick={() => {
									if (isLive) {
										if (data.live_class_link) {
											Api.trackEvent(segmentEvents.DASHBOARD.CLASSES_JOIN);
											window.open(data.live_class_link, "_blank");
										}
									} else {
										if (data.replay_url) {
											Api.trackEvent(segmentEvents.DASHBOARD.CLASSES_REPLAY);
											window.open(data.replay_url, "_blank");
										}
									}
								}}>
								<img src={isLive ? JoinImg : RepeatImg} alt="join" className="class_button_join_img" />
								{isLive ? "Join" : "Replay class"}
							</SolidButton>
						</div>

						<div className="class_info_wrapper">
							{!isLive && (
								<>
									<p className="class_card_text1">
										{!data.notes_file && (
											<>
												<img src={InfoImg} alt="info" className="class_card_info_img" /> Not Uploaded
											</>
										)}
									</p>

									{!data.replay_url && (
										<p className="class_card_text1">
											<img src={InfoImg} alt="info" className="class_card_info_img" /> Offline class
										</p>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClassCard;
