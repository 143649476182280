import { AUTH_PROFILE_LOCK } from "../actionType";

export const initialState = {
    profileLocked: false,
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case AUTH_PROFILE_LOCK:
            return {
                ...state,
                profileLocked: payload,
            };
        default:
            return state;
    }
}
